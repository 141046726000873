import { fetchManagersFinished, fetchManagersStarted, fetchManagersSuccess } from "."
import ManagersService from "../../../services/managersService"

export const fetchManagers = () => async (dispatch) => {
   try {
      dispatch(fetchManagersStarted())
      const data = await ManagersService.fetchManagers()

      if (!data.error) {
         dispatch(fetchManagersSuccess(data.data))
      }
   } catch (err) {
      console.log(err)      
   } finally {
      dispatch(fetchManagersFinished())
   }
}