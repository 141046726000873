import { createSlice } from "@reduxjs/toolkit"

const initialState = {
   managersLoading: true,
   managers: [],
}

const managersSlice = createSlice({
   name: 'managers',
   initialState,
   reducers: {
      fetchManagersStarted: (state) => {
         state.managersLoading = true
      },
      fetchManagersSuccess: (state, action) => {
         state.managers = action.payload
      },
      fetchManagersFinished: (state) => {
         state.managersLoading = false
      },
   }
})

export const {
   fetchManagersStarted,
   fetchManagersSuccess,
   fetchManagersFinished
} = managersSlice.actions
export default managersSlice.reducer