import React, { useState } from 'react'

import './ChannelSelect.css'

import { useClickOutside } from '../../../hooks'

import ChannelSelectOption from './ChannelSelectOption'

const ChannelSelect = ({ selectedValues, values, onChange }) => {
   const [active, setActive] = useState(false)

   const handleToggle = () => setActive(active => !active)

   const handleClose = () => setActive(false)

   const handleChange = (value) => {
      onChange(value)
      handleClose()
   }

   const ref = useClickOutside(handleClose)

   return (
      <div
         className={`channel-select ${active ? 'active' : ''}`}
         ref={ref}
      >
         <button
            className='channel-select__toggle'
            onClick={handleToggle}
         >
            <span className='channel-select__toggle-label'>
               Каналы контактов
            </span>
            <svg className='channel-select__icon' width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M4.47994 0.478478C4.67521 0.283216 4.99179 0.283216 5.18705 0.478478L9.18705 4.47848C9.38231 4.67374 9.38231 4.99032 9.18705 5.18558C8.99179 5.38085 8.67521 5.38085 8.47994 5.18558L4.8335 1.53914L1.18705 5.18558C0.991787 5.38085 0.675205 5.38085 0.479943 5.18558C0.284681 4.99032 0.284681 4.67374 0.479943 4.47848L4.47994 0.478478Z" fill="#252525"/>
            </svg>
         </button>
         <div className='channel-select__popup'>
            <ul className='channel-select__list'>
               <li key={'Все каналы'}>
                  <ChannelSelectOption
                     isSelected={!selectedValues.length}
                     value={null}
                     onChange={() => handleChange(null)}
                  />
               </li>
               {values.map(value => {                  
                  const isSelected = selectedValues?.some(val => val === value.id) || false
                  return (
                     <li key={value.id}>
                        <ChannelSelectOption
                           isSelected={isSelected}
                           value={value}
                           onChange={() => handleChange(value.id)}
                        />
                     </li>
                  )
               })}
            </ul>
         </div>
      </div>
   )
}

export default ChannelSelect