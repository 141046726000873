import React, { useState } from 'react'

import './CartDetails.css'

import { getFormattedPlanEndDate, getTotalPlanPrice } from '../utils'

import CartBlock from '../CartBlock'

const CartDetails = ({ currentPlan }) => {   
   const { plan: planInfo, items, end_at } = currentPlan
   const { name: planName, price: planPrice } = planInfo

   const planEndDate = getFormattedPlanEndDate(end_at)
   const totalPrice = getTotalPlanPrice(currentPlan)


   const [detailsActive, setDetailsActive] = useState(false)

   const handleToggleDetails = () => setDetailsActive(active => !active)


   return (
      <CartBlock customStyle='cart-details'>
         <div className='cart-details-header'>
            <span className='cart-details-header__text'>
               Текущая стоимость тарифа:
            </span>
            <span className='cart-details-header__price'>
               {totalPrice} рублей
            </span>
         </div>
         <div className={`cart-details-body ${detailsActive ? 'active' : ''}`}>
            <button 
               className='cart-details-body__toggle'
               onClick={handleToggleDetails}
            >
               Детали тарифа
               <img
                  className='cart-details-body__toggle-icon'
                  src='/assets/sidebar/select-dropdown-icon.svg' alt=''
               />
            </button>
            {detailsActive && (
               <div className='cart-details-body__list'>
                  <div className='cart-details-body__item'>
                     <span className='cart-details-body__item-name'>
                        {planName}
                     </span>
                     <span className='cart-details-body__item-price'>
                        {planPrice} руб.
                     </span>
                  </div>
                  {items.length > 0 && items.map(item => (
                     <div key={item.id} className='cart-details-body__item'>
                        <span className='cart-details-body__item-name'>
                           {item.plan_item.name} <span>x{item.quantity}</span>
                        </span>
                        <span className='cart-details-body__item-price'>
                           {item.plan_item.price_per_30_days} руб./шт
                        </span>
                     </div>
                  ))}
                  <div className='cart-details-body__item'>
                     <span className='cart-details-body__item-name'>
                        Дата окончания
                     </span>
                     <span className='cart-details-body__item-price'>
                        {planEndDate}
                     </span>
                  </div>
               </div>
            )}
         </div>
      </CartBlock>
   )
}

export default CartDetails