export const getStatusBarStyle = (managersQty = 0, planManagersQty = 0) => {
   const managersPercentage = managersQty / planManagersQty * 100
   if (managersPercentage < 50) return 'low'
   if (managersPercentage > 50 && managersPercentage < 100) return 'medium'
   return 'high'
}

export const getStatusBarText = (availableManagersQty, planManagersQty) => {
   return planManagersQty > 0 
      ? `Доступно ${availableManagersQty} из ${planManagersQty} менеджеров`
      : `Доступно 0 менеджеров`
}