import React, { useState } from 'react'

import './managerSidebar.css'

import { SidebarHeader } from '../sidebar/Sidebar'
import AddManager from '../modals/AddManager'
import NotificationModal from '../modals/NotificationModal'
import { Button } from '../Button'
import Loader from '../Loader'
import AvailableManagersStatusBar from '../AvailableManagersStatusBar'
import WarningMessage from '../WarningMessage'
import ManagerCard from '../sidebar/ManagerCard'
import Search from '../Search'

const ManagerSidebar = ({
   setAddManagerPage,
   managerCardClick,
   setManagerCardClick,
   setSelectedChannel,
   channelList,
   userName,
   managerSidebarLoader,
   managers,
   managerList,
   setManagerList,
   onManagersBuyClick
}) => {
   const [showAddManagerModal, setShowAddManagerModal] = useState(false)
   const [showNotificationModal, setShowNotificationModal] = useState(false)
   const [searchValue, setSearchValue] = useState('')

   const toggleShowAddManageModal = () => {
      setShowAddManagerModal(!showAddManagerModal)
   }

   const toggleShowNotificationModal = () => {
      setShowNotificationModal(!showNotificationModal)
   }

   const handleInputSearch = (term) => {
      setSearchValue(term)
      term
         ? setManagerList(
              managers.filter((manager) =>
                 manager.username.toLowerCase().startsWith(term.toLowerCase())
              )
           )
         : setManagerList(managers)
   }

   const handleCardClick = (manager) => {
      setManagerCardClick(manager)
      setAddManagerPage(manager)
   }

   return (
      <div className='manager-sidebar-container'>
         <SidebarHeader
            setSelectedChannel={setSelectedChannel}
            channelList={channelList}
            sidebarToggle={3}
            userName={userName}
         />
         {!process.env.REACT_APP_DISABLE_PAYMENT_SYSTEM && (
            <AvailableManagersStatusBar
               onBuyClick={onManagersBuyClick}
            />
         )}
         {!process.env.REACT_APP_DISABLE_PAYMENT_SYSTEM && (
            <WarningMessage
               title='С 15 февраля 2025 года вступают в силу новые условия тарификации'
            >
               Ваш тариф будет автоматически обновлен. Базовая стоимость тарифа составит 0 рублей, а стоимость каждого менеджера — 599 рублей.
            </WarningMessage>
         )}
         <Search
            searchValue={searchValue}
            onChange={handleInputSearch}
         />
         <div className="managerSidebar-wrapper">
            <NotificationModal
               title={'Уведомление'}
               text={'Менеджер успешно добавлен.'}
               isVisible={showNotificationModal}
               ok={() => window.location.reload(false)}
            />
            <NotificationModal
               title={'Уведомление'}
               text={'Менеджер успешно добавлен.'}
               isVisible={showNotificationModal}
               ok={() => window.location.reload(false)}
            />
            <AddManager
               isVisible={showAddManagerModal}
               toggleShowAddManageModal={toggleShowAddManageModal}
               managers={managers}
               toggleShowNotificationModal={toggleShowNotificationModal}
            />
            <ul className='manager-sidebar-list'>
               {managerSidebarLoader 
                  ? <Loader />
                  : managerList && managerList.length > 0 
                     ? managerList.map(manager => (
                        <li key={manager.id}>
                           <ManagerCard
                              isSelected={manager.id === managerCardClick?.id}
                              manager={manager}
                              onClick={handleCardClick}
                           />
                        </li>
                     ))
                     : <p>Список пуст</p>
               }
            </ul>
         </div>
         <div className="sidebar-list-add-contact-button-block">
            <Button
               customStyle='manager-sidebar-add-manager-btn'
               text='Добавить менеджера'
               onClick={toggleShowAddManageModal}
            />
         </div>
      </div>
   )
}

export default ManagerSidebar
