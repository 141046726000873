import React from 'react'

import './ManagersNumberInput.css'

const ManagersNumberInput = ({ value, onChange, disabled }) => {
   const handleChange = (e) => onChange(e.target.value)

   return (
      <div className={`managers-number-input ${disabled ? 'disabled' : ''}`}>
         <div className='managers-number-input__container'>
            <svg className='managers-number-input__icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C10.2051 2.75 8.75 4.20507 8.75 6C8.75 7.79493 10.2051 9.25 12 9.25C13.7949 9.25 15.25 7.79493 15.25 6C15.25 4.20507 13.7949 2.75 12 2.75ZM7.25 6C7.25 3.37665 9.37665 1.25 12 1.25C14.6234 1.25 16.75 3.37665 16.75 6C16.75 8.62335 14.6234 10.75 12 10.75C9.37665 10.75 7.25 8.62335 7.25 6Z" fill="#252525"/>
               <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 22C2.25 16.6152 6.61524 12.25 12 12.25C17.3848 12.25 21.75 16.6152 21.75 22C21.75 22.4142 21.4142 22.75 21 22.75C20.5858 22.75 20.25 22.4142 20.25 22C20.25 17.4437 16.5563 13.75 12 13.75C7.44366 13.75 3.75 17.4437 3.75 22C3.75 22.4142 3.41421 22.75 3 22.75C2.58579 22.75 2.25 22.4142 2.25 22Z" fill="#252525"/>
               <path fill-rule="evenodd" clip-rule="evenodd" d="M12 12.25C12.3293 12.25 12.62 12.4647 12.7168 12.7794L14.7168 19.2794C14.7898 19.5167 14.7407 19.7747 14.5857 19.9685L12.5857 22.4685C12.4433 22.6464 12.2278 22.75 12 22.75C11.7722 22.75 11.5567 22.6464 11.4144 22.4685L9.41436 19.9685C9.25929 19.7747 9.21017 19.5167 9.28317 19.2794L11.2832 12.7794C11.38 12.4647 11.6708 12.25 12 12.25ZM10.8335 19.3413L12 20.7994L13.1665 19.3413L12 15.5503L10.8335 19.3413Z" fill="#252525"/>
            </svg>
            <span className='managers-number-input__default-qty'>
               1 менеджер
            </span>
         </div>
         <div className='managers-number-input__container'>
            <svg className='managers-number-input__icon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12.5303 11.4697C12.8232 11.7626 12.8232 12.2374 12.5303 12.5303L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#252525"/>
               <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 5.46967C11.7626 5.17678 12.2374 5.17678 12.5303 5.46967L18.5303 11.4697C18.8232 11.7626 18.8232 12.2374 18.5303 12.5303L12.5303 18.5303C12.2374 18.8232 11.7626 18.8232 11.4697 18.5303C11.1768 18.2374 11.1768 17.7626 11.4697 17.4697L16.9393 12L11.4697 6.53033C11.1768 6.23744 11.1768 5.76256 11.4697 5.46967Z" fill="#252525"/>
            </svg>
            <input
               className='managers-number-input__input'
               type='text' 
               value={value}
               onChange={handleChange}
               disabled={disabled}
            />
            <span className='managers-number-input__input-text'>
               менеджеров
            </span>
         </div>
      </div>
   )
}

export default ManagersNumberInput