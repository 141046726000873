import $axios from "../http"

class PlanService {
   static fetchPlan = async () => {
      const { data } = await $axios.get('user/plan')
      return data
   }

   static fetchAvailablePlanList = async () => {
      const { data } = await $axios.get('plan/get-available-plan-list')
      return data
   }

   static fetchAvailablePlanComponents = async () => {
      const { data } = await $axios.get('plan/get-available-plan-components')
      return data
   }

   static calculatePlan = async (body) => {
      const params = new URLSearchParams({ ...body, planItems: JSON.stringify(body.planItems) }).toString()

      const { data } = await $axios.post('plan/calculate-plan', params, {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
         }
      })
      return data
   }

   static calculatePlanWithAdditionalComponents = async ({ planItems  }) => {
      const params = new URLSearchParams({ planItems: JSON.stringify(planItems) }).toString()

      const { data } = await $axios.post('plan/calculate-add-components', params, {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
         }
      })
      return data
   }

   static createOrder = async (body) => {
      const params = new URLSearchParams({ 
         ...body, 
         planItems: JSON.stringify(body.planItems),
         paymentMethod: 'Yookassa'
      }).toString()

      const { data } = await $axios.post('payment/order-create', params, {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
         }
      })
      return data
   }

   static createPayment = async (body) => {
      const params = new URLSearchParams(body).toString()

      const { data } = await $axios.post('payment/payment-create', params, {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
         }
      })
      return data
   }

   static changePlan = async (body) => {
      const params = new URLSearchParams({ planItems: JSON.stringify(body.planItems) }).toString()

      const { data } = await $axios.post('plan/change', params, {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
         }
      })
      return data
   }

   static setFreePlan = async (body) => {
      const normalizedBody = { ...body }
      body.planItems && (normalizedBody.planItems = JSON.stringify(body.planItems))
      const params = new URLSearchParams(normalizedBody).toString()

      const { data } = await $axios.post('plan/set', params, {
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
         }
      })
      return data
   }
}

export default PlanService