import React from 'react'

const Checkbox = ({ checked }) => {
   return (
      <div
         className={`dropdown-list_item-checkbox ${checked ? 'active' : ''}`}
      >
         {checked && (
            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M8.71258 1.21248C8.88751 1.03907 9.12367 0.941479 9.36999 0.940799C9.61631 0.94012 9.853 1.03641 10.0289 1.20885C10.2048 1.38129 10.3057 1.61603 10.3099 1.86232C10.3141 2.1086 10.2212 2.34665 10.0513 2.52498L5.06133 8.76248C4.97556 8.85486 4.87206 8.92899 4.75699 8.98044C4.64192 9.0319 4.51766 9.05962 4.39164 9.06195C4.26561 9.06429 4.14041 9.04118 4.02352 8.99402C3.90663 8.94687 3.80045 8.87662 3.71133 8.78748L0.405075 5.47998C0.312966 5.39416 0.239089 5.29066 0.187849 5.17566C0.136609 5.06066 0.109056 4.93652 0.106835 4.81064C0.104614 4.68476 0.12777 4.55972 0.174922 4.44299C0.222073 4.32625 0.292254 4.22021 0.381277 4.13119C0.470301 4.04216 0.576343 3.97198 0.693078 3.92483C0.809813 3.87768 0.93485 3.85452 1.06073 3.85674C1.18661 3.85897 1.31075 3.88652 1.42575 3.93776C1.54075 3.989 1.64425 4.06288 1.73007 4.15498L4.34758 6.77123L8.68883 1.23998C8.69657 1.2303 8.70492 1.22111 8.71383 1.21248H8.71258Z" fill="#00ACEF"/>
            </svg>
         )}
      </div>
   )
}

export default Checkbox