import React, { useEffect } from 'react'
import useState from 'react-usestateref'
import Messenger from './pages/Messenger'
import Auth from './pages/Auth'
import './app.css'
import {useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { 
   auth_selectOAuthLink, 
   auth_selectIsAuth, 
   auth_selectUser, 
   auth_selectIsManager 
} from './store/slices/auth/selectors'
import { setAutoReadStatus, setIsAuth } from './store/slices/auth'
import { checkAuth } from './store/slices/auth/thunk-creators'
import { fetchPlan } from './store/slices/plan/thunk-creators'
import AuthLoader from './components/AuthLoader'

function App() {
   const oAuthLink = useSelector(auth_selectOAuthLink)
   const isAuth = useSelector(auth_selectIsAuth)
   const user = useSelector(auth_selectUser)
   const isManager = useSelector(auth_selectIsManager)

   const userName = user?.username || null
   const allowedTag = user?.allowed_tag || null
   const allowedStatus = user?.allowed_status || null
   const allowedChannel = user?.allowed_channel || null
   const autoReadStatus = user?.dialog_auto_read

   const [loading, setLoading] = useState(true)
   const [userId, setUserId] = useState(user?.user_id || user?.id || null)
   const [disableExit, setDisableExit] = useState(false)
   
   const disableExitParam = new URLSearchParams(useLocation().search).get('disable_exit')
   
   const dispatch = useDispatch()

   const handleIsAuthChange = (value) => {
      dispatch(setIsAuth(value))
   }

   const handleAutoReadStatusChange = (value) => {
      dispatch(setAutoReadStatus(value))
   }

   useEffect(() => {
      setLoading(true)
      dispatch(checkAuth())
         .then(() => {
            if (!process.env.REACT_APP_DISABLE_PAYMENT_SYSTEM) dispatch(fetchPlan())
         })
         .finally(() => setLoading(false))
   }, [isManager])

   useEffect(() => setUserId(user?.user_id || user?.id || null), [user])

   useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps

      if (disableExitParam) {
         setDisableExit(true)
      }
   }, [isAuth])

   if (loading) return <AuthLoader />

   return (
      <div>
         {isAuth ?
            <Messenger
               currentUser={user}
               allowedTag={allowedTag}
               allowedStatus={allowedStatus}
               allowedChannel={allowedChannel}
               isUserAuthorized={isAuth}
               setIsUserAuthorized={handleIsAuthChange}
               userName={userName}
               autoReadStatus={autoReadStatus}
               setAutoReadStatus={handleAutoReadStatusChange}
               isManager={isManager}
               userId={userId}
               setUserId={setUserId}
               disableExit={disableExit}
            />
            :
            <Auth
               oAuthLink={oAuthLink}
               isManager={isManager}
            />
         }
      </div>
   )
}

export default App
