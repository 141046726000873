import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import './AvailableManagersStatusBar.css'

import { getStatusBarStyle, getStatusBarText } from './utils'
import { getAvailableManagersQty } from '../../utils'
import { planComponentsCodes } from '../../utils/consts'
import { managers_selectManagers, managers_selectManagersLoading } from '../../store/slices/managers/selectors'
import { plan_selectPlan } from '../../store/slices/plan/selectors'

import { btnSizes, Button } from '../Button'

const AvailableManagersStatusBar = ({ onBuyClick }) => {
   const plan = useSelector(plan_selectPlan)
   const managers = useSelector(managers_selectManagers)
   const managersLoading = useSelector(managers_selectManagersLoading)

   const managersComponent = plan?.items?.find(item => item.plan_item.code === planComponentsCodes.MANAGER)

   const availableManagersQty = getAvailableManagersQty(managers, managersComponent)
   const managersQty = managers.length
   const planManagersQty = managersComponent?.quantity || 0
   
   const text = managersLoading 
      ? 'Загрузка...' 
      : getStatusBarText(availableManagersQty, planManagersQty)
   const statusbarStyle = getStatusBarStyle(managersQty, planManagersQty)
   const managersPercentage = managersQty / planManagersQty * 100

   return (
      <div className='available-managers-status-bar'>
         <div className='available-managers-status-bar__header'>
            <p className='available-managers-status-bar__text'>{text}</p>
            <Link className='available-managers-status-bar__link' to='/plan'>
               <Button 
                  text='Купить' 
                  size={btnSizes.SMALL} 
                  onClick={onBuyClick}
               />
            </Link>
         </div>
         <div className={`available-managers-status-bar__status-bar available-managers-status-bar__status-bar_${statusbarStyle}`}>
            <div 
               className='available-managers-status-bar__status-bar-state' 
               style={{ width: `${managersPercentage > 100 ? 100 : managersPercentage}%` }}
            ></div>
         </div>
      </div>
  )
}

export default AvailableManagersStatusBar