export const i2crmLinks = [
   {
      name: 'E-mail',
      label: 'support@i2crm.ru',
      link: 'mailto:support@i2crm.ru'
   },
   {
      name: 'Телеграм',
      label: 'i2crm',
      link: 'https://t.me/i2crm_support_bot'
   },
   {
      name: 'WhatsApp',
      label: 'i2crm',
      link: 'https://i2crm.ru/wame?74993501337'
   },
   {
      name: 'Instagram',
      label: 'Ссылка',
      link: 'http://instagram.com/i2crm_official'
   },
   {
      name: 'Facebook',
      label: 'Ссылка',
      link: 'http://facebook.com/i2crm.ru'
   },
   {
      name: 'ВКонтакте',
      label: 'Ссылка',
      link: 'https://vk.com/i2crm'
   },
]