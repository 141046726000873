import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { managers_selectManagers, managers_selectManagersLoading } from "../store/slices/managers/selectors"
import { fetchManagers } from "../store/slices/managers/thunk-creators"


const useFetchManagers = () => {
   const managersLoading = useSelector(managers_selectManagersLoading)
   const managers = useSelector(managers_selectManagers)

   const dispatch = useDispatch()

   const handleFetchManagers = async () => await dispatch(fetchManagers())

   useEffect(() => {
      if (!managers.length) {
         handleFetchManagers()
      }
   }, [])

   return [managersLoading, managers]
}

export default useFetchManagers