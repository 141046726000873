import React, { useEffect, useState } from 'react'

import './TagSelect.css'

import Checkbox from '../../Checkbox'

const TagSelectOption = ({ isSelected, value, onSelect, disabled }) => {
   const [disable, setDisable] = useState(disabled || false)

   const handleSelect = async () => {
      try {
         setDisable(true)
         await onSelect(value)
      } finally {
         setDisable(false)
      }
   }

   useEffect(() => {
      setDisable(disabled)
   }, [disabled])

   return (
      <button
         className='tag-select__option selected'
         onClick={handleSelect}
         disabled={disable}
      >
      <Checkbox checked={isSelected} />
      <span className='tag-select__option-text'>
         {value.label}
      </span>
   </button>
   )
}

export default TagSelectOption