import React, { useState } from 'react'

import './PlanError.css'

import { Button } from '../../../components/Button'

const PlanError = ({ error, onReload }) => {
   const [disableBtn, setDisableBtn] = useState(false)

   const handleReload = async () => {
      setDisableBtn(true)
      await onReload()
      setDisableBtn(false)
   }

   return (
      <div className='plan-error'>
         <p className='plan-error__text'>
           {error} 
         </p>
         <Button
            text='Перезагрузить'
            onClick={handleReload}
            disabled={disableBtn}
         />
      </div>
   )
}

export default PlanError