import React, { useEffect, useState } from 'react'

import './Cart.css'

import { getNormalizedPlanItems, getPeriodValues } from '../utils'
import { plansMap } from '../../../utils/consts'

import CartBlock from '../CartBlock'
import Loader from '../../../components/Loader'
import PeriodSelect from '../PeriodSelect'
import { Button } from '../../../components/Button'
import PlanTooltip from '../PlanTooltip'

const Cart = ({
   cart,
   currentPlan,
   isPlanActive,
   shouldPay,
   changePlanBtnDisabled,
   calculationError,
   onPeriodChange,
   onCreatePayment,
   onPlanChange,
   onSetFreePlan,
   onCalculate,
}) => {
   const { 
      totalPrice,
      selectedPlan, 
      selectedPeriod,
      selectedComponents,
      isCalculating
   } = cart
   const { name: planName, price: planPrice } = selectedPlan
   
   const [disablePayBtn, setDisablePayBtn] = useState(false)
   const [disableChangeBtn, setDisableChangeBtn] = useState(changePlanBtnDisabled)
   const [disableSetFreePlanBtn, setDisableSetFreePlanBtn] = useState(false)
   const [disableCalculateBtn, setDisableCalculateBtn] = useState(false)
   
   const periodValues = getPeriodValues(selectedPlan, currentPlan)                                     

   const handleCreatePayment = async () => {
      setDisablePayBtn(true)
      await onCreatePayment()
      setDisablePayBtn(false)
   }

   const handlePlanChange = async () => {
      setDisableChangeBtn(true)
      await onPlanChange()
      setDisableChangeBtn(false)
   }
   
   const handleSetFreePlan = async () => {
      setDisableSetFreePlanBtn(true)
      await onSetFreePlan()
      setDisableSetFreePlanBtn(false)
   }

   const handleCalculate = async () => {
      try {
         setDisableCalculateBtn(true)
         await onCalculate({
            planId: selectedPlan.id,
            period: selectedPeriod.value,
            planItems: getNormalizedPlanItems(selectedComponents)
         })  
      } catch (err) {
         setDisableCalculateBtn(false)
      }
   }

   useEffect(() => setDisableChangeBtn(changePlanBtnDisabled), [changePlanBtnDisabled])

   return (
      <CartBlock customStyle='cart'>
         {isCalculating
            ? (
               <div className='cart__loader'>
                  <Loader />
               </div>
            ) : (
               <>
                  <div className='cart-header'>
                     <span className='cart-header__text'>
                        Корзина:
                     </span>
                     <span className='cart-header__price'>
                        {totalPrice} рублей
                     </span>
                  </div>
                  {selectedPlan.type && selectedPlan.type !== plansMap.TEST
                     ? (
                        <>
                           <div className='cart-body'>
                              <div className='cart-body__item'>
                                 <span className='cart-body__item-name'>
                                    {planName}
                                 </span>
                                 <span className='cart-body__item-price'>
                                    {planPrice} руб.
                                 </span>
                              </div>
                              <div className='plan__period'>
                                 <PeriodSelect
                                    selectedValue={selectedPeriod}
                                    values={periodValues}
                                    onSelect={onPeriodChange}
                                 />
                              </div>
                              {selectedComponents.length > 0 && selectedComponents.map(component => (
                                 <div key={component.id} className='cart-body__item'>
                                    <span className='cart-body__item-name'>
                                       <span>{component.name}</span>
                                       <span className='cart-body__item-name-quantity'>x{component.quantity}</span>
                                       <PlanTooltip
                                          customStyle='cart-body__item-tooltip'
                                          iconSrc='/assets/notifications/light-info-icon.svg'
                                          text='При покупке компонентов их цена будет расчитываться на срок действия тарифа'
                                       />
                                    </span>
                                    <span className='cart-body__item-price'>
                                       {component.price_per_30_days} руб./шт
                                    </span>
                                 </div>
                              ))}
                           </div>
                           {shouldPay && selectedPlan.type !== plansMap.FREE && (
                              <Button
                                 text='Оплатить'
                                 onClick={handleCreatePayment}
                                 disabled={disablePayBtn}
                              />
                           )}
                           {!shouldPay && selectedPlan.type !== plansMap.FREE && (
                              <Button
                                 text='Изменить'
                                 onClick={handlePlanChange}
                                 disabled={disableChangeBtn}
                              />
                           )}
                           {selectedPlan.type === plansMap.FREE && (
                              <Button
                                 text='Изменить'
                                 onClick={handleSetFreePlan}
                                 disabled={disableSetFreePlanBtn || isPlanActive}
                              />
                           )}
                           {calculationError && (
                              <Button
                                 text='Подсчитать'
                                 onClick={handleCalculate}
                                 disabled={disableCalculateBtn}
                              />
                           )}
                        </>
                     ) : (
                        <p className='cart__fallback-text'>
                           Выберите тарифный план, чтобы рассчитать стоимость.
                        </p>
                     )
                  }
               </>
            )
         }
      </CartBlock>
   )
}

export default Cart