import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker"
import ru from 'date-fns/locale/ru'

import './ContactDatePicker.css'

import { useClickOutside } from '../../hooks'
import { determineDateStatus, getFormattedDate } from '../../utils'
import { Button, btnThemes } from '../Button'
registerLocale('ru', ru)

const ContactDatePicker = ({ selectedDialog, onContactDateSelect, onContactDateDelete }) => {
   const [selectedDate, setSelectedDate] = useState(
      selectedDialog.follow_up_date 
         ? new Date(selectedDialog.follow_up_date) 
         : new Date(Date.now())
   )
   const dialogContactDateStatus = determineDateStatus(selectedDialog.follow_up_date)
   
   const [showDatePicker, setShowDatePicker] = useState(false)
   const [disableDeleteBtn, setDisableDeleteBtn] = useState(false)
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const handleToggleDatePicker = () => setShowDatePicker(show => !show)

   const handleCloseDatePicker = () => setShowDatePicker(false)

   const handleDeleteDate = async () => {
      setDisableDeleteBtn(true)
      await onContactDateDelete(selectedDialog.dialog_id)
      setDisableDeleteBtn(false)
   }
   
   const handleDateChange = (date) => {     
      setSelectedDate(date)
   }
   
   const handleSubmit = async () => {      
      setDisableSubmitBtn(true)
      handleCloseDatePicker()      
      await onContactDateSelect({
         dialog_id: selectedDialog.dialog_id,
         date: selectedDate
      })
      setDisableSubmitBtn(false)
   }

   const datePickerRef = useClickOutside(handleCloseDatePicker)

   useEffect(() => {
      setSelectedDate(
         selectedDialog.follow_up_date 
            ? new Date(selectedDialog.follow_up_date) 
            : new Date(Date.now())
      )
   }, [selectedDialog, showDatePicker])

   return (
      <div ref={datePickerRef} className={`contact-date-picker ${showDatePicker ? 'active' : ''}`}>
         <div className='contact-date-picker__button contact-date-picker-button'>
            <button 
               className='contact-date-picker-button__btn'
               onClick={handleToggleDatePicker}
            >
               <span className='contact-date-picker-button__value'>
                  {selectedDialog.follow_up_date ? getFormattedDate(selectedDialog.follow_up_date) : 'Выбор даты контакта'}
               </span>
               {selectedDialog.follow_up_date && (
                  <span className={`contact-date-picker-button__date-status contact-date-picker-button__date-status_${dialogContactDateStatus.style}`}>
                     {dialogContactDateStatus.name}
                  </span>
               )}
               <svg className='contact-date-picker__btn-icon' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.4001 1.6C4.50618 1.6 4.60793 1.64214 4.68294 1.71716C4.75795 1.79217 4.8001 1.89391 4.8001 2V2.4H11.2001V2C11.2001 1.89391 11.2422 1.79217 11.3173 1.71716C11.3923 1.64214 11.494 1.6 11.6001 1.6C11.7062 1.6 11.8079 1.64214 11.8829 1.71716C11.958 1.79217 12.0001 1.89391 12.0001 2V2.4H12.8001C13.2244 2.4 13.6314 2.56857 13.9315 2.86863C14.2315 3.16869 14.4001 3.57565 14.4001 4V12.8C14.4001 13.2243 14.2315 13.6313 13.9315 13.9314C13.6314 14.2314 13.2244 14.4 12.8001 14.4H3.2001C2.77575 14.4 2.36878 14.2314 2.06873 13.9314C1.76867 13.6313 1.6001 13.2243 1.6001 12.8V4C1.6001 3.57565 1.76867 3.16869 2.06873 2.86863C2.36878 2.56857 2.77575 2.4 3.2001 2.4H4.0001V2C4.0001 1.89391 4.04224 1.79217 4.11725 1.71716C4.19227 1.64214 4.29401 1.6 4.4001 1.6V1.6ZM3.2001 3.2C2.98792 3.2 2.78444 3.28428 2.63441 3.43431C2.48438 3.58434 2.4001 3.78783 2.4001 4V4.8H13.6001V4C13.6001 3.78783 13.5158 3.58434 13.3658 3.43431C13.2158 3.28428 13.0123 3.2 12.8001 3.2H3.2001ZM13.6001 5.6H2.4001V12.8C2.4001 13.0122 2.48438 13.2157 2.63441 13.3657C2.78444 13.5157 2.98792 13.6 3.2001 13.6H12.8001C13.0123 13.6 13.2158 13.5157 13.3658 13.3657C13.5158 13.2157 13.6001 13.0122 13.6001 12.8V5.6Z" fill="#5D6070"/>
               </svg>
            </button>
         </div>
         <div className='contact-date-picker__popup'>
            <DatePicker
               locale='ru'
               wrapperClassName='contact-date-picker__picker-wrapper'
               popperClassName='contact-date-picker__picker'
               className='contact-date-picker__input'
               calendarClassName='contact-date-picker__calendar'
               selected={selectedDate}
               dateFormat={"yyyy-MM-dd"}
               open
               minDate={new Date()}
               onChange={handleDateChange} 
            />
            <div className='contact-date-picker__actions'>
               <Button
                  customStyle='contact-date-picker__action-btn'
                  theme={btnThemes.PRIMARY_SKINNY}
                  text='Сбросить'
                  onClick={handleDeleteDate}
                  disabled={disableDeleteBtn || !selectedDialog.follow_up_date}
               />
               <Button
                  customStyle='contact-date-picker__action-btn'
                  text='Применить'
                  onClick={handleSubmit}
                  disabled={disableSubmitBtn}
               />
            </div>
         </div>
      </div>
   )
}

export default ContactDatePicker