import { useCallback, useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import MainbarConversations from './MainbarConversations'
import MainbarChat from './MainbarChat'
import './mainbar.css'
import ManagerMainbar from '../manager/ManagerMainbar'
import DragAndDrop from '../utils/DragAndDrop'
import DialogSettings from '../settings/DialogSettings'
import ChatBots from '../settings/ChatBots'
import TagsStatus from '../settings/TagsStatus'
import NotificationModal from '../modals/NotificationModal'
import Templates from '../settings/Templates'
import Mailing from '../mailing/Mailing'
import ContactDiscription from './ContactDiscription'
import ChatBotsPlug from '../settings/ChatBotsPlug'
import { notificationsData, tooltipsData } from '../../utils/consts'
import Tooltip from '../Tooltip'
import ReturnBtn from './ReturnBtn'
import Loader from '../Loader'
import EditDialogNameModal from '../modals/EditDialogNameModal'
import ContactDatePicker from '../ContactDatePicker'
import { btnThemes, Button } from '../Button'
import ManagerAccessNotificator from '../ManagerAccessNotificator'
import StatusSelect from './StatusSelect'
import TagSelect from './TagSelect'
import PlanPage from '../../pages/PlanPage'

const MainbarHeader = ({
   dialog,
   onDialogTagSelect,
   onDialogStatusSelect,
   currentUser,
   setSelectTemplate,
   allowedTag,
   allowedStatus,
   allowedChannel,
   isManager,
   selectedConversation,
   setDialogs,
   dialogs,
   channelStatus,
   setChannelStatus,
   tag,
   status,
   handleUpdateDialogName,
   handleDialogContactDateSelect,
   handleDialogContactDateDelete,
   onDeleteTag
}) => {
   const {
      name: clientName,
      identity: clientPhone
   } = dialog.client
   const username = dialog.dialog_username
   const whatsappDialogName = dialog.name || `${clientName} (+${clientPhone}) - ${username}`
   const isWhatsapp = dialog.dialog_channel === "whatsapp"
   const followUpDateEnabled = currentUser.follow_up_date_enabled

   const statusList = status.map((item) => {
      return { value: item.id, label: item.name, bg: item.color }
   })
   
   const tagList = tag.map((item) => {
      return { 
         value: item.id, 
         label: item.name,
         isSelected: dialog.dialog_tag.some(tag => tag.id === item.id) 
      }
   })

   const [notification, setNotification] = useState({
      isVisible: false,
      ...notificationsData.default
   })
   const [showEditDialogNameModal, setShowEditDialogNameModal] = useState(false)

   const showNotification = (notificationData) => {
      setNotification(notification => ({
         isVisible: !notification.isVisible,
         ...notificationData
      }))
   }

   const closeNotification = () => setNotification(notification => ({ ...notification, isVisible: false }))

   const handleToggleShowEditDialogNameModal = () => setShowEditDialogNameModal(show => !show)

   const selectedDialogStatus = (
      dialog.dialog_status !== null
         ? {
            value: dialog.dialog_status.id,
            label: dialog.dialog_status.name,
            bg: dialog.dialog_status.color,
         }
         : {
            value: 'Не задано',
            label: 'Не задано',
            bg: '#FFFFFF',
         }
   )

   const messageChannelList = [
      { value: 1, label: 'Все сообщения' },
      { value: 2, label: 'Только директ' },
      { value: 3, label: 'Только комментарии' },
   ]

   const getDialogStatus = () => {
      // console.log(
      //    'getDialogStatus',
      //    dialogStatus.filter(
      //       (dialog) => dialog.dialog_id === selectedConversation
      //    )
      // )
      return dialog
   }

   const selectedDialogData = getDialogStatus()

   const handleConversationTagClick = (event) => {
      if (dialog.read_only && isManager) {
         return showNotification(notificationsData.readOnly)
      }
      if (dialog.dialog_tag.find((tag) => tag.id === event.value))
         return showNotification(notificationsData.tagAlreadyAttached)
      onDialogTagSelect(event)
   }

   const handleConversationStatusClick = async (status) => {
      if (dialog.read_only && isManager) {
         return showNotification(notificationsData.readOnly)
      }
      await onDialogStatusSelect(dialog.dialog_id, status)
   }

   const handleDeleteTag = async (id) => {
      await onDeleteTag(id, dialog)
   } 

   return (
      <div className="mainbar-header">
         <NotificationModal
            isVisible={notification.isVisible}
            title={notification.title}
            text={notification.text}
            ok={closeNotification}
         />
         <EditDialogNameModal
            isVisible={showEditDialogNameModal}
            dialogId={dialog.dialog_id}
            dialogName={isWhatsapp ? whatsappDialogName : dialog.dialog_name}
            onSubmit={handleUpdateDialogName}
            onClose={handleToggleShowEditDialogNameModal}
         />
         {selectedDialogData ? (
            <div className="mainbar-header-wrapper">
               <div className="mainbar-header-conversation-control">
                  <div className="mainbar-header-conversation-control__header">
                     <span className="mainbar-header-conversation-username">
                        {isWhatsapp ? whatsappDialogName : dialog.dialog_name}
                     </span>
                     {(!isManager || currentUser.edit_dialog_allowed) && (
                        <Button
                           customStyle='mainbar-header-conversation-control__edit-name-btn'
                           theme={btnThemes.LINK}
                           onClick={handleToggleShowEditDialogNameModal}
                        >
                           <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.60449 12.75C1.60449 12.5084 1.80037 12.3125 2.04199 12.3125H12.542C12.7836 12.3125 12.9795 12.5084 12.9795 12.75C12.9795 12.9916 12.7836 13.1875 12.542 13.1875H2.04199C1.80037 13.1875 1.60449 12.9916 1.60449 12.75Z" fill="#158CAE"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.244 1.8125C9.36009 1.81248 9.47143 1.8586 9.55351 1.94071L11.6841 4.07224C11.8549 4.24308 11.8549 4.51998 11.6841 4.69082L5.65162 10.726C5.56957 10.808 5.45826 10.8542 5.34219 10.8542H3.20801C2.96638 10.8542 2.77051 10.6583 2.77051 10.4167V8.2933C2.77051 8.17736 2.81653 8.06616 2.89846 7.98413L8.93453 1.94083C9.01658 1.85869 9.1279 1.81252 9.244 1.8125ZM9.2442 2.86897L3.64551 8.47437V9.97917H5.16092L10.7561 4.38153L9.2442 2.86897Z" fill="#158CAE"/>
                           </svg>
                        </Button>
                     )}
                  </div>
                  {dialog.context_link && (
                     <div className="mainbar-header-conversation-control__links">
                        <a className="mainbar-header-conversation-control__links-item" href={dialog.context_link} target='_blank' rel='noreferrer'>{dialog.context_name}</a>
                     </div>
                  )}
                  <div className="mainbar-header-conversation-control-buttons">
                     <div className='mainbar-header-conversation-control-buttons__column'>
                        <StatusSelect
                           selectedValue={selectedDialogStatus}
                           values={statusList}
                           disabled={
                              dialog
                                 ? dialog.dialog_channelStatus !== 'active'
                                 : null
                           }
                           onChange={handleConversationStatusClick}
                        />
                     </div>
                     <div className='mainbar-header-conversation-control-buttons__column'>
                        <TagSelect
                           selectedValues={dialog.dialog_tag.map(({ id, name }) => ({ value: id, label: name })) || []}
                           values={tagList}
                           disabled={
                              dialog
                                 ? dialog.dialog_channelStatus !== 'active'
                                 : null
                           }
                           onChange={handleConversationTagClick}
                           onDeleteTag={handleDeleteTag}
                        />
                     </div>
                     {selectedDialogData.dialog_channel === 'instagram' && (
                        <div className='mainbar-header-conversation-control-buttons__column'>
                           <Select
                              isSearchable={false}
                              className="mainbar-header-conversation-channel-container"
                              classNamePrefix="mainbar-header-conversation-channel"
                              options={messageChannelList}
                              onChange={(event) => setChannelStatus(event)}
                              value={channelStatus}
                           />
                        </div>
                     )}
                     {followUpDateEnabled && (
                        <div className='mainbar-header-conversation-control-buttons__column'>
                           <ContactDatePicker
                              selectedDialog={dialog}
                              onContactDateSelect={handleDialogContactDateSelect}
                              onContactDateDelete={handleDialogContactDateDelete}
                           />
                        </div>
                     )}
                  </div>
               </div>
            </div>
         ) : (
            <div className="mainbar-header-wrapper"></div>
         )}
      </div>
   )
}

const Mainbar = ({
   currentContact,
   setCurrentContact,
   dialog,
   handleSetSelectedDialog,
   handleDialogTagSelect,
   handleDialogStatusSelect,
   currentUser,
   templates,
   templateSubsections,
   handleCreateTemplate,
   handleUpdateTemplate,
   handleDeleteTemplate,
   handleCreateTemplateSubsection,
   handleUpdateTemplateSubsection,
   handleDeleteTemplateSubsection,
   handleEditMessageClick,
   selectedMessageToEdit,
   handleEditMessageCancel,
   handleEditMessage,
   allowedTag,
   allowedStatus,
   allowedChannel,
   isManager,
   isMainbarTablet,
   selectedConversation,
   setDialogs,
   dialogs,
   messages,
   setMessages,
   nextMessagesCursor,
   setNextMessagesCursor,
   messagesLoader,
   setMessagesLoader,
   channelStatus,
   setChannelStatus,
   sidebarToggle,
   managerCardClick,
   addManagerPage,
   autoReadStatus,
   settingsToggle,
   setSettingsToggle,
   setAutoReadStatus,
   channelList,
   userId,
   fetchDialogData,
   setInstagramConvoFilter,
   toggleSidebarView,
   tag,
   setTag,
   addTag,
   deleteTag,
   editTag,
   status,
   addStatus,
   deleteStatus,
   editStatus,
   messageText,
   onMessageTextChange,
   selectTemplate,
   setSelectTemplate,
   setSidebarToggle,
   setUnreadMessagesIds,
   handleMessageStatusClick,
   handleEditManagerFormSubmit,
   handleToggleManagerActivation,
   handleDeleteManager,
   handleReplyMessageClick,
   selectedRepliedMessage,
   setSelectedRepliedMessage,
   getNextCursorDialogList,
   handleUpdateManagerIsAllowedToEditDialog,
   handleUpdateDialogName,
   handleUpdateIsContactInfoHidden,
   handleUpdateIsContactListUnloadingAllowed,
   handleDialogsScroll,
   handleForwardMessages,
   handleDeleteSelectedMessages,
   handleDialogContactDateSelect,
   handleDialogContactDateDelete,
   onDeleteTag,
   onNavigateToPlanPage
}) => {
   const [showTemplate, setShowTemplate] = useState(false)
   const [templateMessages, setTemplateMessages] = useState([])
   const [commentReplySection, setCommentReplySection] = useState(null)
   const [showEmoji, setShowEmoji] = useState(false)
   const [chosenEmoji, setChosenEmoji] = useState(null)
   const [dragActive, setDragActive] = useState(false)
   const [uploadFileList, setUploadFileList] = useState([])
   const [selectedMessages, setSelectedMessages] = useState([])
   const [messageToForward, setMessageToForward] = useState(null)
   
   const [showChat, setShowChat] = useState(false)

   const handleReplySectionClose = () => {
      setCommentReplySection(null)
   }
   
   const handleMessageSelect = (isMessageSelected, message) => {
      isMessageSelected
         ? setSelectedMessages(messages => messages.filter(selectedMessage => selectedMessage.id !== message.id))
         : setSelectedMessages(messages => [...messages, message].sort((a, b) => a.id - b.id))
   }

   const handleForwardClick = (message) => {
      setMessageToForward(message)
   }

   const handleForwardMessageCancel = () => setMessageToForward(null)

   const handleMessagesSelectionClear = () => setSelectedMessages([])

   const handleDragOver = () => {
      setDragActive(true)
   }

   const handleDragLeave = (e) => {
      setDragActive(false)
   }

   const handleDragCancel = (e) => {
      if (e.key === 'Escape') {
         setDragActive(false)
      }
   }

   const handleDrop = useCallback(() => {
      setDragActive(false)
   }, [])

   const handleReturnFromSettingPage = () => {
      setSidebarToggle(4)
      setSettingsToggle(0)
   }

   useEffect(() => {
      setShowChat(selectedConversation ? true : false)
   }, [selectedConversation])

   useEffect(() => {
      setCommentReplySection(null)
   }, [channelStatus])

   const renderSettingsSwitch = (param) => {
      switch (param) {
         case 1:
            return (
               // <ChatBots
               //    channelList={channelList}
               //    toggleSidebarView={toggleSidebarView}
               // />
               <ChatBotsPlug
                  isMainbarTablet={isMainbarTablet}
                  handleReturnBtnClick={handleReturnFromSettingPage}
               />
            )
         case 2:
            return (
               <Templates 
                  templates={templates}
                  templateSubsections={templateSubsections}
                  handleCreateTemplate={handleCreateTemplate}
                  handleUpdateTemplate={handleUpdateTemplate}
                  handleDeleteTemplate={handleDeleteTemplate}
                  handleCreateTemplateSubsection={handleCreateTemplateSubsection}
                  handleUpdateTemplateSubsection={handleUpdateTemplateSubsection}
                  handleDeleteTemplateSubsection={handleDeleteTemplateSubsection}
                  isMainbarTablet={isMainbarTablet}
                  handleReturnBtnClick={handleReturnFromSettingPage}
               />
            )
         case 3:
            return (
               <TagsStatus
                  isMainbarTablet={isMainbarTablet}
                  handleReturnBtnClick={handleReturnFromSettingPage}
                  tag={tag}
                  setTag={setTag}
                  addTag={addTag}
                  deleteTag={deleteTag}
                  editTag={editTag}
                  status={status}
                  addStatus={addStatus}
                  deleteStatus={deleteStatus}
                  editStatus={editStatus}
               />
            )
         case 4:
            return (
               <DialogSettings
                  autoReadStatus={autoReadStatus}
                  setAutoReadStatus={setAutoReadStatus}
                  isMainbarTablet={isMainbarTablet}
                  handleReturnBtnClick={handleReturnFromSettingPage}
               />
            )
         default:
            return <></>
      }
   }

   return sidebarToggle === 5 ? (
      <Mailing
         currentUser={currentUser}
         tag={tag}
         allowedTag={allowedTag}
         status={status}
         allowedStatus={allowedStatus}
         channelList={channelList}
         allowedChannel={allowedChannel}
      />
   ) : sidebarToggle === 2 ? (
      <>
         {showChat ? (
            <>
               <div 
                  className="mainbar" 
                  onDragOver={handleDragOver}
               >
                  <div className='mainbar-container'>
                     {messagesLoader && <Loader className="messages-loader" />}
                     <ReturnBtn
                        isMainbarTablet={isMainbarTablet || currentContact}
                        onClick={() => handleSetSelectedDialog(null)}
                     />
                     {selectedConversation ? (
                        <>
                           {dragActive ? (
                              <DragAndDrop
                                 selectedConversation={selectedConversation}
                                 setMessages={setMessages}
                                 onDragCancel={handleDragCancel}
                                 onDragLeave={handleDragLeave}
                                 onDrop={handleDrop}
                              />
                           ) : (
                              <></>
                           )}
                           <MainbarHeader
                              dialog={dialog}
                              onDialogTagSelect={handleDialogTagSelect}
                              onDialogStatusSelect={handleDialogStatusSelect}
                              allowedStatus={allowedStatus}
                              allowedTag={allowedTag}
                              allowedChannel={allowedChannel}
                              isManager={isManager}
                              status={status}
                              tag={tag}
                              selectedConversation={selectedConversation}
                              setDialogs={setDialogs}
                              dialogs={dialogs}
                              channelStatus={channelStatus}
                              setChannelStatus={setChannelStatus}
                              currentUser={currentUser}
                              handleUpdateDialogName={handleUpdateDialogName}
                              handleDialogContactDateSelect={handleDialogContactDateSelect}
                              handleDialogContatDateDelete={handleDialogContactDateDelete}
                              onDeleteTag={onDeleteTag}
                           />
                           <MainbarConversations
                              selectedConversation={selectedConversation}
                              messages={messages}
                              selectedMessages={selectedMessages}
                              nextMessagesCursor={nextMessagesCursor}
                              setNextMessagesCursor={setNextMessagesCursor}
                              messagesLoader={messagesLoader}
                              setMessagesLoader={setMessagesLoader}
                              showTemplate={showTemplate}
                              setShowTemplate={setShowTemplate}
                              templateMessages={templateMessages}
                              setSelectTemplate={setSelectTemplate}
                              setCommentReplySection={setCommentReplySection}
                              commentReplySection={commentReplySection}
                              channelStatus={channelStatus}
                              setMessages={setMessages}
                              showEmoji={showEmoji}
                              setShowEmoji={setShowEmoji}
                              setChosenEmoji={setChosenEmoji}
                              autoReadStatus={autoReadStatus}
                              uploadFileList={setUploadFileList}
                              userId={userId}
                              fetchDialogData={fetchDialogData}
                              setInstagramConvoFilter={setInstagramConvoFilter}
                              setUnreadMessagesIds={setUnreadMessagesIds}
                              handleMessageStatusClick={handleMessageStatusClick}
                              handleReplyMessageClick={handleReplyMessageClick}
                              selectedRepliedMessage={selectedRepliedMessage}
                              setSelectedRepliedMessage={setSelectedRepliedMessage}
                              handleEditMessageClick={handleEditMessageClick}
                              handleMessageSelect={handleMessageSelect}
                              handleForwardClick={handleForwardClick}
                              getNextCursorDialogList={getNextCursorDialogList}
                              templates={templates}
                              templateSubsections={templateSubsections}
                           />
                           <MainbarChat
                              dialog={dialog}
                              currentUser={currentUser}
                              isManager={isManager}
                              templates={templates}
                              dialogs={dialogs}
                              selectedConversation={selectedConversation}
                              setMessages={setMessages}
                              showTemplate={showTemplate}
                              messages={messages}
                              selectedMessages={selectedMessages}
                              messageToForward={messageToForward}
                              setShowTemplate={setShowTemplate}
                              setTemplateMessages={setTemplateMessages}
                              selectTemplate={selectTemplate}
                              commentReplySection={commentReplySection}
                              setCommentReplySection={setCommentReplySection}
                              showEmoji={showEmoji}
                              setShowEmoji={setShowEmoji}
                              chosenEmoji={chosenEmoji}
                              uploadFileList={uploadFileList}
                              setUploadFileList={setUploadFileList}
                              toggleSidebarView={toggleSidebarView}
                              setSelectTemplate={setSelectTemplate}
                              messageText={messageText}
                              onMessageTextChange={onMessageTextChange}
                              handleReplySectionClose={handleReplySectionClose}
                              selectedMessageToEdit={selectedMessageToEdit}
                              handleEditMessageCancel={handleEditMessageCancel}
                              onEditMessage={handleEditMessage}
                              handleMessagesSelectionClear={handleMessagesSelectionClear}
                              handleDialogsScroll={handleDialogsScroll}
                              handleForwardMessages={handleForwardMessages}
                              handleForwardMessageCancel={handleForwardMessageCancel}
                              handleDeleteSelectedMessages={handleDeleteSelectedMessages}
                              templateSubsections={templateSubsections}
                           />
                        </>
                     ) : (
                        <></>
                     )}
                     <ManagerAccessNotificator />
                  </div>
               </div>
            </>
         ) : (
            <ContactDiscription
               dialogs={dialogs}
               setDialogs={setDialogs}
               currentContact={currentContact}
               setCurrentContact={setCurrentContact}
               setShowChat={setShowChat}
               isMainbarTablet={isMainbarTablet}
               handleReturnBtnClick={() => setCurrentContact(null)}
               handleSetSelectedDialog={handleSetSelectedDialog}
            />
         )}
      </>
   ) : sidebarToggle === 5 ? (
      <Mailing
         currentUser={currentUser}
         tag={tag}
         allowedTag={allowedTag}
         status={status}
         allowedStatus={allowedStatus}
         channelList={channelList}
         allowedChannel={allowedChannel}
      />
   ) : sidebarToggle === 6 ? (
      <PlanPage
         isMainbarTablet={isMainbarTablet}
         setSidebarToggle={setSidebarToggle}
      />
   ) : sidebarToggle === 4 ? (
      renderSettingsSwitch(settingsToggle)
   ) : sidebarToggle === 3 ? (
      <>
         <div className="mainbar">
            <div className='mainbar-container'>
               <ReturnBtn
                  isMainbarTablet={isMainbarTablet}
                  onClick={() => {
                     handleSetSelectedDialog(null)
                     setSidebarToggle(0)
                  }}
               />
               <ManagerMainbar
                  tag={tag}
                  status={status}
                  channelList={channelList}
                  managerCardClick={managerCardClick}
                  addManagerPage={addManagerPage}
                  handleEditManagerFormSubmit={handleEditManagerFormSubmit}
                  handleToggleManagerActivation={handleToggleManagerActivation}
                  handleDeleteManager={handleDeleteManager}
                  onUpdateManagerIsAllowedToEditDialog={handleUpdateManagerIsAllowedToEditDialog}
                  onUpdateIsContactInfoHidden={handleUpdateIsContactInfoHidden}
                  onUpdateIsContactListUnloadingAllowed={handleUpdateIsContactListUnloadingAllowed}
                  onNavigateToPlanPage={onNavigateToPlanPage}
               />
            </div>
         </div>
      </>
   ) : (
      <div 
         className="mainbar" 
         onDragOver={handleDragOver}
      >
         <div className='mainbar-container'>
            {messagesLoader && <Loader className="messages-loader" />}
            <ReturnBtn
               isMainbarTablet={isMainbarTablet}
               onClick={() => handleSetSelectedDialog(null)}
            />
            {selectedConversation ? (
               <>
                  {dragActive ? (
                     <DragAndDrop
                        selectedConversation={selectedConversation}
                        setMessages={setMessages}
                        onDragCancel={handleDragCancel}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                     />
                  ) : (
                     <></>
                  )}
                  <MainbarHeader
                     dialog={dialog}
                     onDialogTagSelect={handleDialogTagSelect}
                     onDialogStatusSelect={handleDialogStatusSelect}
                     allowedStatus={allowedStatus}
                     allowedTag={allowedTag}
                     allowedChannel={allowedChannel}
                     isManager={isManager}
                     status={status}
                     tag={tag}
                     selectedConversation={selectedConversation}
                     setDialogs={setDialogs}
                     dialogs={dialogs}
                     channelStatus={channelStatus}
                     setChannelStatus={setChannelStatus}
                     currentUser={currentUser}
                     handleUpdateDialogName={handleUpdateDialogName}
                     handleDialogContactDateSelect={handleDialogContactDateSelect}
                     handleDialogContactDateDelete={handleDialogContactDateDelete}
                     onDeleteTag={onDeleteTag}
                  />
                  <MainbarConversations
                     selectedConversation={selectedConversation}
                     messages={messages}
                     selectedMessages={selectedMessages}
                     nextMessagesCursor={nextMessagesCursor}
                     setNextMessagesCursor={setNextMessagesCursor}
                     messagesLoader={messagesLoader}
                     setMessagesLoader={setMessagesLoader}
                     showTemplate={showTemplate}
                     setShowTemplate={setShowTemplate}
                     templateMessages={templateMessages}
                     setSelectTemplate={setSelectTemplate}
                     setCommentReplySection={setCommentReplySection}
                     commentReplySection={commentReplySection}
                     channelStatus={channelStatus}
                     setMessages={setMessages}
                     showEmoji={showEmoji}
                     setShowEmoji={setShowEmoji}
                     setChosenEmoji={setChosenEmoji}
                     autoReadStatus={autoReadStatus}
                     uploadFileList={setUploadFileList}
                     userId={userId}
                     fetchDialogData={fetchDialogData}
                     setInstagramConvoFilter={setInstagramConvoFilter}
                     setUnreadMessagesIds={setUnreadMessagesIds}
                     handleMessageStatusClick={handleMessageStatusClick}
                     handleReplyMessageClick={handleReplyMessageClick}
                     selectedRepliedMessage={selectedRepliedMessage}
                     setSelectedRepliedMessage={setSelectedRepliedMessage}
                     handleEditMessageClick={handleEditMessageClick}
                     handleMessageSelect={handleMessageSelect}
                     handleForwardClick={handleForwardClick}
                     getNextCursorDialogList={getNextCursorDialogList}
                     templates={templates}
                     templateSubsections={templateSubsections}
                  />
                  <MainbarChat
                     dialog={dialog}
                     currentUser={currentUser}
                     isManager={isManager}
                     templates={templates}
                     dialogs={dialogs}
                     selectedConversation={selectedConversation}
                     setMessages={setMessages}
                     showTemplate={showTemplate}
                     messages={messages}
                     selectedMessages={selectedMessages}
                     messageToForward={messageToForward}
                     setShowTemplate={setShowTemplate}
                     setTemplateMessages={setTemplateMessages}
                     selectTemplate={selectTemplate}
                     commentReplySection={commentReplySection}
                     setCommentReplySection={setCommentReplySection}
                     showEmoji={showEmoji}
                     setShowEmoji={setShowEmoji}
                     chosenEmoji={chosenEmoji}
                     uploadFileList={uploadFileList}
                     setUploadFileList={setUploadFileList}
                     toggleSidebarView={toggleSidebarView}
                     setSelectTemplate={setSelectTemplate}
                     messageText={messageText}
                     onMessageTextChange={onMessageTextChange}
                     handleReplySectionClose={handleReplySectionClose}
                     selectedMessageToEdit={selectedMessageToEdit}
                     handleEditMessageCancel={handleEditMessageCancel}
                     onEditMessage={handleEditMessage}
                     handleMessagesSelectionClear={handleMessagesSelectionClear}
                     handleDialogsScroll={handleDialogsScroll}
                     handleForwardMessages={handleForwardMessages}
                     handleForwardMessageCancel={handleForwardMessageCancel}
                     handleDeleteSelectedMessages={handleDeleteSelectedMessages}
                     templateSubsections={templateSubsections}
                  />
               </>
            ) : (
               <></>
            )}
            <ManagerAccessNotificator />
         </div>
      </div>
   )
}

export default Mainbar
