import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import './managerMainbar.css'
import WarningModal from '../modals/WarningModal'
import ManagerPermissionList from './ManagerPermissionList'
import NotificationModal from '../modals/NotificationModal'
import BasicModal from '../modals/BasicModal'
import { useAppNotification } from '../../hooks'
import { managerStatuses, notificationsData } from '../../utils/consts'
import { arraysEqual } from '../../utils'
import EditManagerForm from '../EditManagerForm'
import ManagerPermission from './ManagerPermission'
import { Button } from '../Button'

const ManagerMainbar = ({
   managerCardClick,
   addManagerPage,
   tag,
   status,
   channelList,
   handleEditManagerFormSubmit,
   handleToggleManagerActivation,
   handleDeleteManager,
   onUpdateManagerIsAllowedToEditDialog,
   onUpdateIsContactInfoHidden,
   onUpdateIsContactListUnloadingAllowed,
   onNavigateToPlanPage
}) => {
   const location = useLocation()
   const navigate = useNavigate()

   const isPermissionsPage = location.state?.isPermissionsPage

   const [managerId, setManagerId] = useState('')
   const [managerUsername, setManagerUsername] = useState('')
   const [managerPassword, setManagerPassword] = useState('')
   const [managerEmail, setManagerEmail] = useState('')
   const [managerPermission, setManagerPermission] = useState({})
   const [forceRender, setForceRender] = useState(1)

   const [managerChannelsAllows, setManagerChannelsAllows] = useState([])
   const [isAllChannelsAllow, setIsAllChannelsAllow] = useState(false)

   const [managerTagsAllows, setManagerTagAllows] = useState([])
   const [isAllTagsAllow, setIsAllTagsAllow] = useState(false)

   const [managerStatusAllows, setManagerStatusAllows] = useState([])
   const [isAllStatusesAllow, setIsAllStatusesAllow] = useState(false)

   const [allDialogsWithoutActions, setAllDialogsWithoutActions] =
      useState(false)
   const toggleAllDialogsWithoutActions = () => {
      setAllDialogsWithoutActions(!allDialogsWithoutActions)
   }

   const [showWarningDeleteModal, setShowWarningDeleteModal] = useState(false)
   const toggleShowWarningDeleteModal = () => {
      setShowWarningDeleteModal(!showWarningDeleteModal)
   }
   const [currentManagerPage, setCurrentManagerPage] = useState(isPermissionsPage ? 2 : 1)
   const toggleManagerPage = (page) => {
      setCurrentManagerPage(page)
   }

   const [notification, showNotification, closeNotification] = useAppNotification()

   const [isShowAddPhotoModal, setIsShowAddPhotoModal] = useState(false)
   const toggleShowAddPhotoModal = () => {
      setIsShowAddPhotoModal(!isShowAddPhotoModal)
   }
   const [addedPhoto, setAddedPhoto] = useState(null)
   const [isEditDialogAllowed, setIsEditDialogAllowed] = useState(addManagerPage?.edit_dialog_allowed)
   const [isContactInfoHidden, setIsContactInfoHidden] = useState(addManagerPage?.hide_client_identity)
   const [isContactsListUnloadAllowed, setIsContactsListUnloadAllowed] = useState(addManagerPage?.unload_contact_list)
   const isManagerActive = addManagerPage?.status === managerStatuses.active
   
   const handleToggleIsContactInfoHidden = () => setIsContactInfoHidden(isHidden => !isHidden)

   const handleToggleIsEditDialogAllowed = () => setIsEditDialogAllowed(isAllowed => !isAllowed)

   const handleToggleIsContactsListUnloadAllowed = () => setIsContactsListUnloadAllowed(isAllowed => !isAllowed)

   useEffect(() => {
      if (isPermissionsPage) {
         setCurrentManagerPage(isPermissionsPage ? 2 : 1)
         navigate(location.pathname, { state: null })
      }
   }, [isPermissionsPage])

   useEffect(() => {
      let tempObj = managerCardClick && JSON.parse(managerCardClick.rights)
      managerCardClick &&
         (managerCardClick.rights !== null
            ? setManagerPermission({
                 dialog_create:
                    tempObj.dialog_status &&
                    tempObj.dialog_status.create &&
                    tempObj.dialog_status.create
                       ? true
                       : false,
                 dialog_edit:
                    tempObj.dialog_status &&
                    tempObj.dialog_status.edit &&
                    tempObj.dialog_status.edit
                       ? true
                       : false,
                 dialog_delete:
                    tempObj.dialog_status &&
                    tempObj.dialog_status.delete &&
                    tempObj.dialog_status.delete
                       ? true
                       : false,
                 dialog_manage:
                    tempObj.dialog_status &&
                    tempObj.dialog_status.manage &&
                    tempObj.dialog_status.manage
                       ? true
                       : false,
                 tag_create:
                    tempObj.dialog_tags &&
                    tempObj.dialog_tags.create &&
                    tempObj.dialog_tags.create
                       ? true
                       : false,
                 tag_edit:
                    tempObj.dialog_tags &&
                    tempObj.dialog_tags.edit &&
                    tempObj.dialog_tags.edit
                       ? true
                       : false,
                 tag_delete:
                    tempObj.dialog_tags &&
                    tempObj.dialog_tags.delete &&
                    tempObj.dialog_tags.delete
                       ? true
                       : false,
                 tag_manage:
                    tempObj.dialog_tags &&
                    tempObj.dialog_tags.manage &&
                    tempObj.dialog_tags.manage
                       ? true
                       : false,
              })
            : setManagerPermission({
                 dialog_create: false,
                 dialog_edit: false,
                 dialog_delete: false,
                 dialog_manage: false,
                 tag_create: false,
                 tag_edit: false,
                 tag_delete: false,
                 tag_manage: false,
              }))
   }, [managerCardClick, managerCardClick.rights])

   const checkValidity = (isNew) => {
      if (!managerUsername) return false
      if (!managerPassword) return false
      if (!managerEmail) return false
      if (!isNew && !managerId) return false
      return true
   }

   const handleAddManagerButton = () => {
      save(true)
   }

   const handleUpdateManagerButton = () => {
      save(false)
   }

   const save = (isNew) => {
      if (checkValidity(isNew)) {
         var bodyFormData = new FormData()
         let url = isNew
            ? `api/v1/manager/register`
            : `api/v1/manager/update?id=` + managerId
         bodyFormData.append('email', managerEmail)
         bodyFormData.append('password', managerPassword)
         bodyFormData.append('username', managerUsername)
         axios({
            method: 'post',
            url: url,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
         })
            .then(function (response) {
               if (response.data.error) {
                  let errorArray = Object.keys(response.data.data).map(
                     function (key) {
                        return response.data.data[key]
                     }
                  )
                  let errorDisplay = ''
                  errorArray.forEach((element) => {
                     errorDisplay = errorDisplay + element + '\n'
                  })
                  alert(errorDisplay)
               } else {
                  alert(
                     isNew
                        ? 'Менеджер успешно добавлен.'
                        : 'Менеджер успешно изменён.'
                  )
                  window.location.reload(false)
               }
            })
            .catch(function (err) {
               console.log(err)
               alert('Произошла ошибка...')
            })
      } else {
         alert('Все поля обязательны к заполнению!')
      }
   }

   const handlePermissionToggle = (permission) => {
      let tempObj = managerPermission
      tempObj[permission] = !managerPermission[permission]
      setManagerPermission(tempObj)
      setForceRender((prev) => prev + 1)
   }

   const generateLinkForStatusPermission = () => {
      let generatedLink = ''
      if (managerPermission.dialog_create) generatedLink += '"create":true'
      if (managerPermission.dialog_edit)
         generatedLink += managerPermission.dialog_create
            ? ', "edit":true'
            : '"edit":true'
      if (managerPermission.dialog_manage)
         generatedLink +=
            managerPermission.dialog_edit || managerPermission.dialog_create
               ? ', "manage":true'
               : '"manage":true'
      if (managerPermission.dialog_delete)
         generatedLink +=
            managerPermission.dialog_edit ||
            managerPermission.dialog_create ||
            managerPermission.dialog_edit
               ? ', "delete":true'
               : '"delete":true'
      if (generatedLink !== '') {
         generatedLink = '"dialog_status":{' + generatedLink + '}'
      } else {
         generatedLink = ''
      }
      return generatedLink
   }

   const generateLinkForTagsPermission = () => {
      let generatedLink = ''
      if (managerPermission.tag_create) generatedLink += '"create":true'
      if (managerPermission.tag_edit)
         generatedLink += managerPermission.tag_create
            ? ', "edit":true'
            : '"edit":true'
      if (managerPermission.tag_manage)
         generatedLink +=
            managerPermission.tag_edit || managerPermission.tag_create
               ? ', "manage":true'
               : '"manage":true'
      if (managerPermission.tag_delete)
         generatedLink +=
            managerPermission.tag_edit ||
            managerPermission.tag_create ||
            managerPermission.tag_edit
               ? ', "delete":true'
               : '"delete":true'
      if (generatedLink !== '') {
         generatedLink = '"dialog_tags":{' + generatedLink + '}'
      } else {
         generatedLink = ''
      }
      return generatedLink
   }

   const handleManagerPermissionChange = () => {
      let generatedData
      if (
         generateLinkForTagsPermission() !== '' &&
         generateLinkForStatusPermission() !== ''
      ) {
         generatedData = `{${generateLinkForTagsPermission()}, ${generateLinkForStatusPermission()}}`
      } else if (generateLinkForStatusPermission() !== '') {
         generatedData = `{${generateLinkForStatusPermission()}}`
      } else if (generateLinkForTagsPermission() !== '') {
         generatedData = `{${generateLinkForTagsPermission()}}`
      } else {
         generatedData = `{}`
      }
      axios
         .post(
            `/api/v1/manager/mass-permission?manager=${managerCardClick.id}`,
            {
               // eslint-disable-next-line no-useless-escape
               data: generatedData,
            }
         )
         .then(function (response) {
            if (response.data.error) {
               alert('Произошла ошибка...')
            } else {
               alert('Права менеджера изменены.')
            }
         })
         .catch(function (err) {
            console.log(err)
            alert('Произошла ошибка...')
         })
   }

   const activeToggleButton = {
      backgroundColor: 'white',
      marginLeft: 'auto',
   }

   const inActiveToggleButton = {
      backgroundColor: 'white',
      marginRight: 'auto',
   }

   useEffect(() => {
      if (addManagerPage === 0) {
         setManagerEmail('')
         setManagerUsername('')
      } else {
         setManagerId(addManagerPage.id)
         setManagerEmail(addManagerPage.email)
         setManagerUsername(addManagerPage.username)

         setManagerChannelsAllows(addManagerPage.allowed_channel)
         setIsAllChannelsAllow(addManagerPage.all_channels_available)
         if (addManagerPage.all_channels_available) {
            addAllChannelsPermissions()
         }

         setManagerTagAllows(addManagerPage.allowed_tag)
         setIsAllTagsAllow(tag.length > 0 && addManagerPage?.allowed_tag?.length === tag.length)
         if (addManagerPage.all_tags_available) {
            addAllTagsPermissions()
         }

         setManagerStatusAllows(addManagerPage.allowed_status)
         setIsAllStatusesAllow(addManagerPage.all_status_available)
         if (addManagerPage.all_status_available) {
            addAllStatusPermissions()
         }

         setAllDialogsWithoutActions(addManagerPage.read_only_available)
         setIsEditDialogAllowed(addManagerPage.edit_dialog_allowed)
         setIsContactInfoHidden(addManagerPage.hide_client_identity)
         setIsContactsListUnloadAllowed(addManagerPage.unload_contact_list)
      }
   }, [addManagerPage])

   const addAllChannelsPermissions = () => {
      setManagerChannelsAllows(() => {
         return channelList.map((element) => {
            return element.id
         })
      })
   }

   const removeAllChannelsPermissions = () => {
      setManagerChannelsAllows([])
   }

   const toggleAllChannelsPermissions = () => {
      isAllChannelsAllow
         ? removeAllChannelsPermissions()
         : addAllChannelsPermissions()
      setIsAllChannelsAllow(!isAllChannelsAllow)
   }

   const addChannelPermission = (id) => {
      setManagerChannelsAllows((prevState) => {
         return [...prevState, id]
      })
   }

   const deleteChannelPermission = (id) => {
      setManagerChannelsAllows((prevState) => {
         return prevState.filter((el) => {
            return el !== id
         })
      })
   }

   const addAllTagsPermissions = () => {
      setManagerTagAllows(() => {
         return tag.map((element) => {
            return element.id
         })
      })
   }

   const removeAllTagsPermissions = () => {
      setManagerTagAllows([])
   }

   const toggleAllTagsPermissions = () => {
      if (!tag.length) return
      isAllTagsAllow ? removeAllTagsPermissions() : addAllTagsPermissions()
      setIsAllTagsAllow(!isAllTagsAllow)
   }

   const addTagPermission = (id) => {
      setManagerTagAllows((prevState) => {
         return [...prevState, id]
      })
   }

   const deleteTagPermission = (id) => {
      setManagerTagAllows((prevState) => {
         return prevState.filter((el) => {
            return el !== id
         })
      })
   }

   const addAllStatusPermissions = () => {
      setManagerStatusAllows(() => {
         return status.map((element) => {
            return element.id
         })
      })
   }

   const removeAllStatusPermissions = () => {
      setManagerStatusAllows([])
   }

   const toggleAllStatusesPermissions = () => {
      isAllStatusesAllow
         ? removeAllStatusPermissions()
         : addAllStatusPermissions()
      setIsAllStatusesAllow(!isAllStatusesAllow)
   }

   const addStatusPermission = (id) => {
      setManagerStatusAllows((prevState) => {
         return [...prevState, id]
      })
   }

   const deleteStatusPermission = (id) => {
      setManagerStatusAllows((prevState) => {
         return prevState.filter((el) => {
            return el !== id
         })
      })
   }

   useEffect(() => {
      setIsAllTagsAllow(tag.length > 0 && managerTagsAllows.length === tag.length)
   }, [managerTagsAllows])

   useEffect(() => {
      if (managerStatusAllows.length !== status.length) {
         return setIsAllStatusesAllow(false)
      }
      if (managerStatusAllows.length === status.length) {
         return setIsAllStatusesAllow(true)
      }
   }, [managerStatusAllows])

   useEffect(() => {
      if (managerChannelsAllows.length !== channelList.length) {
         return setIsAllChannelsAllow(false)
      }
      if (managerChannelsAllows.length === channelList.length) {
         return setIsAllChannelsAllow(true)
      }
   }, [managerChannelsAllows])

   useEffect(() => {}, [forceRender])

   const allowTags = () => {
      var bodyFormData = new FormData()
      bodyFormData.append('managerId', managerId)
      bodyFormData.append('accessToAll', isAllTagsAllow)
      bodyFormData.append('tagIds', managerTagsAllows)
      axios({
         method: 'post',
         url: `api/v1/manager/relations-tag`,
         headers: { 'Content-Type': 'multipart/form-data' },
         data: bodyFormData,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const allowStatuses = () => {
      var bodyFormData = new FormData()
      bodyFormData.append('managerId', managerId)
      bodyFormData.append('accessToAll', isAllStatusesAllow)
      bodyFormData.append('dialogStepIds', managerStatusAllows)
      axios({
         method: 'post',
         url: `api/v1/manager/relations-dialog-step`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const allowChannels = () => {
      var bodyFormData = new FormData()
      bodyFormData.append('managerId', managerId)
      bodyFormData.append('accessToAll', isAllChannelsAllow)
      bodyFormData.append('channelIds', managerChannelsAllows)

      axios({
         method: 'post',
         url: `api/v1/manager/relations-channel`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const accessToDialogs = () => {
      axios({
         method: 'post',
         url: `api/v1/manager/set-read-only?managerId=${managerId}&readOnly=${allDialogsWithoutActions}`,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }
    
   const handleUpdateIsContactInfoHidden = async () => {      
      await onUpdateIsContactInfoHidden({ managerId, hideIdentity: isContactInfoHidden })
   }

   const handleUpdateIsContactListUnloadingAllowed = async () => {      
      await onUpdateIsContactListUnloadingAllowed({ managerId, isAllowed: isContactsListUnloadAllowed })
   }

   const saveAllAllows = () => {
      if (tag.length && !managerTagsAllows.length) {
         return showNotification(notificationsData.managerTagsRequired)
      } else if (managerTagsAllows.length) {
         const tagsChanged = !arraysEqual(addManagerPage.allowed_tag, managerTagsAllows)

         tagsChanged && allowTags()
      }
      if (
         managerStatusAllows.length !== 0 &&
         managerChannelsAllows.length !== 0
      ) {
         const channelsChanged = !arraysEqual(addManagerPage.allowed_channel, managerChannelsAllows)
         const statusesChanged = !arraysEqual(addManagerPage.allowed_status, managerStatusAllows)
         const readOnlyChanged = addManagerPage.read_only_available !== allDialogsWithoutActions
         const editDialogChanged = addManagerPage.edit_dialog_allowed !== isEditDialogAllowed
         const isContactInfoHiddenChanged = addManagerPage.hide_client_identity !== isContactInfoHidden
         const isContactsListUnloadChanged = addManagerPage.unload_contact_list !== isContactsListUnloadAllowed

         channelsChanged && allowChannels()
         statusesChanged && allowStatuses()
         readOnlyChanged && accessToDialogs()
         editDialogChanged && onUpdateManagerIsAllowedToEditDialog({ id: managerId, editDialog: isEditDialogAllowed })
         isContactInfoHiddenChanged && handleUpdateIsContactInfoHidden()
         isContactsListUnloadChanged && handleUpdateIsContactListUnloadingAllowed()
         window.location.reload(false)
      } else {
         showNotification(notificationsData.managerPermissionsRequired)
      }
   }

   return (
      <div className="manager-mainbar-wrapper">
         {addManagerPage !== 0 && (
            <>
               <BasicModal
                  managerId={managerId}
                  addedPhoto={addedPhoto}
                  setAddedPhoto={setAddedPhoto}
                  isVisible={isShowAddPhotoModal}
                  title={'Загрузка новой фотографии'}
                  text={
                     'Вы можете загрузить изображение в формате JPG, PNG или GIF. Размер не более 5 Мбайт.'
                  }
                  cancel={toggleShowAddPhotoModal}
                  buttonTitle={'Добавить файл'}
               />
               <WarningModal
                  isVisible={showWarningDeleteModal}
                  title={'Вы действительно хотите удалить менеджера?'}
                  text={''}
                  yes={() => {
                     handleDeleteManager()
                     toggleShowWarningDeleteModal()
                  }}
                  no={toggleShowWarningDeleteModal}
               />
               <div className="manager-mainbar-user">
                  <div className='manager-mainbar-user__avatar'>
                     {addManagerPage.photo === null 
                        ? (
                           <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M21 21C23.7848 21 26.4555 19.8938 28.4246 17.9246C30.3938 15.9555 31.5 13.2848 31.5 10.5C31.5 7.71523 30.3938 5.04451 28.4246 3.07538C26.4555 1.10625 23.7848 0 21 0C18.2152 0 15.5445 1.10625 13.5754 3.07538C11.6062 5.04451 10.5 7.71523 10.5 10.5C10.5 13.2848 11.6062 15.9555 13.5754 17.9246C15.5445 19.8938 18.2152 21 21 21ZM28 10.5C28 12.3565 27.2625 14.137 25.9497 15.4497C24.637 16.7625 22.8565 17.5 21 17.5C19.1435 17.5 17.363 16.7625 16.0503 15.4497C14.7375 14.137 14 12.3565 14 10.5C14 8.64348 14.7375 6.86301 16.0503 5.55025C17.363 4.2375 19.1435 3.5 21 3.5C22.8565 3.5 24.637 4.2375 25.9497 5.55025C27.2625 6.86301 28 8.64348 28 10.5ZM42 38.5C42 42 38.5 42 38.5 42H3.5C3.5 42 0 42 0 38.5C0 35 3.5 24.5 21 24.5C38.5 24.5 42 35 42 38.5ZM38.5 38.486C38.4965 37.625 37.961 35.035 35.588 32.662C33.306 30.38 29.0115 28 21 28C12.985 28 8.694 30.38 6.412 32.662C4.039 35.035 3.507 37.625 3.5 38.486H38.5Z" fill="#5D6070"/>
                           </svg>
                        ) : (
                           <img
                              className="manager-mainbar-user-photo"
                              src={addManagerPage.photo.url}
                              alt='Avatar'
                           />
                        )
                     }
                     <Button
                        customStyle="manager-edit-photo-button"
                        onClick={toggleShowAddPhotoModal}
                     >
                        <svg
                           width="16px"
                           height="16px"
                           fill="white"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.60449 12.75C1.60449 12.5084 1.80037 12.3125 2.04199 12.3125H12.542C12.7836 12.3125 12.9795 12.5084 12.9795 12.75C12.9795 12.9916 12.7836 13.1875 12.542 13.1875H2.04199C1.80037 13.1875 1.60449 12.9916 1.60449 12.75Z"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9.244 1.8125C9.36009 1.81248 9.47143 1.8586 9.55351 1.94071L11.6841 4.07224C11.8549 4.24308 11.8549 4.51998 11.6841 4.69082L5.65162 10.726C5.56957 10.808 5.45826 10.8542 5.34219 10.8542H3.20801C2.96638 10.8542 2.77051 10.6583 2.77051 10.4167V8.2933C2.77051 8.17736 2.81653 8.06616 2.89846 7.98413L8.93453 1.94083C9.01658 1.85869 9.1279 1.81252 9.244 1.8125ZM9.2442 2.86897L3.64551 8.47437V9.97917H5.16092L10.7561 4.38153L9.2442 2.86897Z"
                           />
                        </svg>
                     </Button>
                  </div>
                  <div className='manager-mainbar-user__info'>
                     <span className="manager-mainbar-user_name">
                        {managerUsername}
                     </span>
                     <span className={`manager-mainbar-user__status ${isManagerActive ? 'manager-mainbar-user__status_active' : ''}`}>
                        {isManagerActive ? 'Активный' : 'Неактивный'}
                     </span>
                  </div>
               </div>
               <div className="manager-mainbar-page-block">
                  <div className='manager-mainbar-page-block__container'>
                     <button
                        className={`manager-mainbar-page-button ${currentManagerPage === 1 ? 'active' : ''}`}
                        onClick={() => toggleManagerPage(1)}
                     >
                        Основная информация
                     </button>
                     <button
                        className={`manager-mainbar-page-button ${currentManagerPage === 2 ? 'active' : ''}`}
                        onClick={() => toggleManagerPage(2)}
                     >
                        Разрешения
                     </button>
                  </div>
               </div>
               {currentManagerPage === 1 && (
                  <>
                     <div className="manager-mainbar-info-wrapper">
                        <EditManagerForm
                           managerData={addManagerPage}
                           onSubmit={handleEditManagerFormSubmit}
                           onStatusChange={handleToggleManagerActivation}
                           onDelete={handleDeleteManager}
                           onNavigateToPlanPage={onNavigateToPlanPage}
                        />
                     </div>
                  </>
               )}
               {currentManagerPage === 2 && (
                  <>
                     <NotificationModal
                        isVisible={notification.isVisible}
                        title={notification.title}
                        text={notification.text}
                        ok={closeNotification}
                     />
                     <div className="manager-permission-control_list-block">
                        <div className='manager-permission-control_list-block-container'>
                           <ManagerPermissionList
                              title={'Каналы'}
                              isAllAllow={isAllChannelsAllow}
                              addAllPermissions={addAllChannelsPermissions}
                              removeAllPermissions={
                                 removeAllChannelsPermissions
                              }
                              toggleAllPermissions={
                                 toggleAllChannelsPermissions
                              }
                              elements={channelList.filter(
                                 (channel) => channel.status === 'active'
                              )}
                              permissions={managerChannelsAllows}
                              addPermission={addChannelPermission}
                              deletePermission={deleteChannelPermission}
                           />
                           <ManagerPermissionList
                              title={'Теги'}
                              isAllAllow={isAllTagsAllow}
                              addAllPermissions={addAllTagsPermissions}
                              removeAllPermissions={removeAllTagsPermissions}
                              toggleAllPermissions={toggleAllTagsPermissions}
                              elements={tag}
                              permissions={managerTagsAllows}
                              addPermission={addTagPermission}
                              deletePermission={deleteTagPermission}
                           />
                           <ManagerPermissionList
                              title={'Статусы'}
                              isAllAllow={isAllStatusesAllow}
                              addAllPermissions={addAllStatusPermissions}
                              removeAllPermissions={removeAllStatusPermissions}
                              toggleAllPermissions={
                                 toggleAllStatusesPermissions
                              }
                              elements={status}
                              permissions={managerStatusAllows}
                              addPermission={addStatusPermission}
                              deletePermission={deleteStatusPermission}
                           />
                        </div>
                     </div>
                     <div className='manager-permission__footer-permissions'>
                        <ManagerPermission
                           name='Сохранить доступ к диалогам без возможности
                              отвечать при смене тегов и статусов в меню
                              “Диалоги”'
                           isActive={allDialogsWithoutActions}
                           onToggle={toggleAllDialogsWithoutActions}
                        />
                        <ManagerPermission
                           name='Возможность редактировать информацию о диалогах'
                           isActive={isEditDialogAllowed}
                           onToggle={handleToggleIsEditDialogAllowed}
                        />
                        <ManagerPermission
                           name='Просмотр контактной информации клиента'
                           isActive={!isContactInfoHidden}
                           onToggle={handleToggleIsContactInfoHidden}
                        />
                        <ManagerPermission
                           name='Разрешить менеджеру выгружать контакты'
                           isActive={isContactsListUnloadAllowed}
                           onToggle={handleToggleIsContactsListUnloadAllowed}
                        />
                     </div>
                     <div className="mainbar-manager-button-block">
                        <Button
                           text='Сохранить'
                           onClick={saveAllAllows}
                        />
                     </div>
                  </>
               )}
            </>
         )}
      </div>
   )
}

export default ManagerMainbar
