import React from 'react'

import './CartBlock.css'

const CartBlock = ({ customStyle = '', children }) => {
   return (
      <div className={`cart-block ${customStyle}`}>
         {children}
      </div>
   )
}

export default CartBlock