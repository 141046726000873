import { useEffect, useState } from "react";

const useWithoutAnswerTimer = (unansweredMessageTimestamp, maxTimeWithoutAnswer) => {
   const [timeWithoutAnswer, setTimeWithoutAnswer] = useState(0)

   const updateElapsedTime = () => {
      const currentTime = Math.floor(Date.now() / 1000)
      const timeSinceLastMessage = currentTime - unansweredMessageTimestamp
      
      setTimeWithoutAnswer(timeSinceLastMessage);
   }

   useEffect(() => { 
     updateElapsedTime()
     const intervalId = setInterval(updateElapsedTime, 60000)
 
     return () => clearInterval(intervalId)
   }, [unansweredMessageTimestamp])
 
   const minutesWithoutAnswer = Math.floor(timeWithoutAnswer / 60)
   const isOverdue = timeWithoutAnswer >= maxTimeWithoutAnswer

   return [minutesWithoutAnswer, isOverdue]
}

export default useWithoutAnswerTimer