import React from 'react'

import './CardAvatar.css'

const CardAvatar = ({ photo, channelType }) => {
   const defaultAvatarSrc = '/assets/sidebar/default_avatar.png'
   const avatarSrc = photo || defaultAvatarSrc
   const channelIconSrc = `/assets/sidebar/${channelType}-icon.svg`

   const handleAvatarLoadingError = (e) => {
      e.target.src = defaultAvatarSrc
   }

   return (
      <div className='card-avatar'>
         <img
            className='card-avatar__photo'
            src={avatarSrc}
            alt='Avatar'
            onError={handleAvatarLoadingError}
            draggable={false}
         />
         <img
            className='card-avatar__channel-icon'
            src={channelIconSrc}
            alt='ChannelType'
            draggable={false}
         />
      </div>
   )
}

export default CardAvatar