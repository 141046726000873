import React from 'react'

import './LastMessage.css'
import { AVITO } from '../../../../utils/consts/channelNames'
import { getLastMessageText } from '../utils'

const LastMessage = ({ dialog }) => {
   const { 
      dialog_name, 
      dialog_channel, 
      context_name, 
      client, 
      is_incoming, 
      is_document, 
      last_message
   } = dialog

   const dialogName = dialog_name || client.name || ''
   const messageText = getLastMessageText(is_document, last_message) 

   return (
      <div className='last-message'>
         {dialogName && <p className='last-message__name'>{dialogName}</p>}
         {dialog_channel === AVITO && context_name && (
            <p className='last-message__product-name'>{context_name}</p>
         )}
         <p className='last-message__text'>
            {!is_incoming && <span>Вы: </span>}
            {messageText || ''}
         </p>
      </div>
   )
}

export default LastMessage