import React, { useEffect, useState } from 'react'
import axios from 'axios'

import './EditContact.css'

import WarningBlock from './WarningBlock'
import { btnThemes, Button } from '../Button'
import Modal from './Modal'

const EditContact = ({ isVisible, close, contact, setCurrentContact }) => {
   const [contactName, setContactName] = useState(contact.name)
   const [isContactNameFilled, setIsContactNameFilled] = useState(false)

   const [contactTelephone, setContactTelephone] = useState(
      contact.phone === null ? '' : contact.phone
   )
   const [isContactTelephoneFilled, setIsContactTelephoneFilled] =
      useState(false)

   useEffect(() => {
      setContactName(contact.name)
      setContactTelephone(contact.phone === null ? '' : contact.phone)
   }, [contact])

   const editContact = () => {
      contactName.length === 0
         ? setIsContactNameFilled(true)
         : setIsContactNameFilled(false)
      if (contactName.length !== 0) {
         var bodyFormData = new FormData()
         bodyFormData.append('clientId', contact.id)
         bodyFormData.append('channel', contact.channel_id)
         bodyFormData.append('name', contactName)
         if (contactTelephone.length !== 0) {
            bodyFormData.append('phone', contactTelephone)
         }

         axios({
            method: 'post',
            url: '/api/v1/contact/update',
            data: bodyFormData,
            withCredentials: true,
         }).then((response) => {
            if (response.data.error === true) {
               window.alert(response.data.data.error)
            } else {
               setCurrentContact(response.data.data)
               close()
            }
         })
      }
   }

   useEffect(() => {
      if (!isVisible) {
         setIsContactNameFilled(false)
      }
   }, [isVisible])

   return (
      <Modal
         show={isVisible}
         title='Редактирование контакта'
         onClose={close}
      >
         <div className='edit-contact-form'>
            <div className='edit-contact-form__body'>

               <div className="add-contact-form-control edit-contact-form-control">
                  <label
                     htmlFor=""
                     className={
                        isContactNameFilled
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     Имя
                  </label>
                  <input
                     type="text"
                     className={
                        isContactNameFilled
                           ? 'add-contact-form-control-input-red'
                           : 'add-contact-form-control-input'
                     }
                     placeholder="Введите имя"
                     maxLength="32"
                     onChange={(e) => setContactName(e.target.value)}
                     value={contactName}
                  />
               </div>
               <div className="add-contact-form-control edit-contact-form-control">
                  <label
                     htmlFor=""
                     className={
                        isContactTelephoneFilled
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     Телефон
                  </label>
                  <input
                     type="tel"
                     className={
                        isContactTelephoneFilled
                           ? 'add-contact-form-control-input-red'
                           : 'add-contact-form-control-input'
                     }
                     placeholder="+7 (900) 000-00-00"
                     onChange={(e) => setContactTelephone(e.target.value)}
                     value={contactTelephone}
                  />
               </div>
               {isContactNameFilled && <WarningBlock title={'Введите имя.'} />}
            </div>
            <div className="add-contact-form-submit edit-contact-form__footer">
               <Button
                  customStyle='edit-contact-form__btn'
                  theme={btnThemes.PRIMARY_SKINNY}
                  text='Отменить'
                  onClick={close}
               />
               <Button
                  customStyle='edit-contact-form__btn'
                  text='Изменить'
                  onClick={editContact}
               />
            </div>
         </div>
      </Modal>
   )
}

export default EditContact
