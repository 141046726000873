import React, { useEffect, useState } from 'react'
import './AttachmentsBox.css'

import FilesQty from './FilesQty'
import AttachmentsItem from './AttachmentsItem'

const AttachmentsBox = ({ files = [], disabled = false, onChange, onDelete }) => {
   const [showFilesList, setShowFilesList] = useState(false)

   const filesQty = files.length

   const handleChange = (e) => {
      onChange(e.target.files)
   }

   const handleToggleFilesList = () => setShowFilesList(show => !show)

   const handleCloseFilesList = () => setShowFilesList(false)

   const handleDeleteFile = (id) => {
      onDelete(id)
      if (files.length < 2) setShowFilesList(false)
   }

   useEffect(() => {
      if (files.length < 1) setShowFilesList(false)
   }, [files])

   return (
      <div className='attachments-box'>
         {filesQty < 1 
            ? (
               <label className="attachments-box__add-btn attachments-add-btn" for="add-files">
                  {filesQty > 0 && <FilesQty filesQty={filesQty} />}
                  <svg 
                     width="16" 
                     height="16" 
                     viewBox="0 0 16 16" 
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path d="M4.5 3C4.5 2.33696 4.76339 1.70107 5.23223 1.23223C5.70107 0.763392 6.33696 0.5 7 0.5C7.66304 0.5 8.29893 0.763392 8.76777 1.23223C9.23661 1.70107 9.5 2.33696 9.5 3V12C9.5 12.3978 9.34196 12.7794 9.06066 13.0607C8.77936 13.342 8.39782 13.5 8 13.5C7.60218 13.5 7.22064 13.342 6.93934 13.0607C6.65804 12.7794 6.5 12.3978 6.5 12V5C6.5 4.86739 6.55268 4.74021 6.64645 4.64645C6.74021 4.55268 6.86739 4.5 7 4.5C7.13261 4.5 7.25979 4.55268 7.35355 4.64645C7.44732 4.74021 7.5 4.86739 7.5 5V12C7.5 12.1326 7.55268 12.2598 7.64645 12.3536C7.74021 12.4473 7.86739 12.5 8 12.5C8.13261 12.5 8.25979 12.4473 8.35355 12.3536C8.44732 12.2598 8.5 12.1326 8.5 12V3C8.5 2.80302 8.4612 2.60796 8.38582 2.42597C8.31044 2.24399 8.19995 2.07863 8.06066 1.93934C7.92137 1.80005 7.75601 1.68956 7.57403 1.61418C7.39204 1.5388 7.19698 1.5 7 1.5C6.80302 1.5 6.60796 1.5388 6.42597 1.61418C6.24399 1.68956 6.07863 1.80005 5.93934 1.93934C5.80005 2.07863 5.68956 2.24399 5.61418 2.42597C5.5388 2.60796 5.5 2.80302 5.5 3V12C5.5 12.663 5.76339 13.2989 6.23223 13.7678C6.70107 14.2366 7.33696 14.5 8 14.5C8.66304 14.5 9.29893 14.2366 9.76777 13.7678C10.2366 13.2989 10.5 12.663 10.5 12V5C10.5 4.86739 10.5527 4.74021 10.6464 4.64645C10.7402 4.55268 10.8674 4.5 11 4.5C11.1326 4.5 11.2598 4.55268 11.3536 4.64645C11.4473 4.74021 11.5 4.86739 11.5 5V12C11.5 12.9283 11.1313 13.8185 10.4749 14.4749C9.8185 15.1313 8.92826 15.5 8 15.5C7.07174 15.5 6.1815 15.1313 5.52513 14.4749C4.86875 13.8185 4.5 12.9283 4.5 12V3Z" fill="#5D6070"/>
                  </svg>
                  <input
                     className='attachments-add-btn__input'
                     type="file"
                     name=""
                     id="add-files"
                     onChange={handleChange}
                     disabled={disabled}
                     multiple
                  />
               </label>
            ) : (
               <button 
                  className='attachments-box__add-btn attachments-show-popup-btn' 
                  onClick={handleToggleFilesList}
               >
                  {filesQty > 0 && <FilesQty filesQty={filesQty} />}
                  <svg 
                     width="16" 
                     height="16" 
                     viewBox="0 0 16 16"
                     opacity={disabled ? '.1' : '.3'}
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M4.5 3C4.5 2.33696 4.76339 1.70107 5.23223 1.23223C5.70107 0.763392 6.33696 0.5 7 0.5C7.66304 0.5 8.29893 0.763392 8.76777 1.23223C9.23661 1.70107 9.5 2.33696 9.5 3V12C9.5 12.3978 9.34196 12.7794 9.06066 13.0607C8.77936 13.342 8.39782 13.5 8 13.5C7.60218 13.5 7.22064 13.342 6.93934 13.0607C6.65804 12.7794 6.5 12.3978 6.5 12V5C6.5 4.86739 6.55268 4.74021 6.64645 4.64645C6.74021 4.55268 6.86739 4.5 7 4.5C7.13261 4.5 7.25979 4.55268 7.35355 4.64645C7.44732 4.74021 7.5 4.86739 7.5 5V12C7.5 12.1326 7.55268 12.2598 7.64645 12.3536C7.74021 12.4473 7.86739 12.5 8 12.5C8.13261 12.5 8.25979 12.4473 8.35355 12.3536C8.44732 12.2598 8.5 12.1326 8.5 12V3C8.5 2.80302 8.4612 2.60796 8.38582 2.42597C8.31044 2.24399 8.19995 2.07863 8.06066 1.93934C7.92137 1.80005 7.75601 1.68956 7.57403 1.61418C7.39204 1.5388 7.19698 1.5 7 1.5C6.80302 1.5 6.60796 1.5388 6.42597 1.61418C6.24399 1.68956 6.07863 1.80005 5.93934 1.93934C5.80005 2.07863 5.68956 2.24399 5.61418 2.42597C5.5388 2.60796 5.5 2.80302 5.5 3V12C5.5 12.663 5.76339 13.2989 6.23223 13.7678C6.70107 14.2366 7.33696 14.5 8 14.5C8.66304 14.5 9.29893 14.2366 9.76777 13.7678C10.2366 13.2989 10.5 12.663 10.5 12V5C10.5 4.86739 10.5527 4.74021 10.6464 4.64645C10.7402 4.55268 10.8674 4.5 11 4.5C11.1326 4.5 11.2598 4.55268 11.3536 4.64645C11.4473 4.74021 11.5 4.86739 11.5 5V12C11.5 12.9283 11.1313 13.8185 10.4749 14.4749C9.8185 15.1313 8.92826 15.5 8 15.5C7.07174 15.5 6.1815 15.1313 5.52513 14.4749C4.86875 13.8185 4.5 12.9283 4.5 12V3Z" 
                     />
                  </svg>
               </button>
         )}
         <div className={`attachments-box__popup attachments-popup ${showFilesList ? 'attachments-popup_active' : ''}`}>
            <ul className='attachments-popup__list'>
               {files.map(file => (
                  <AttachmentsItem 
                     key={file.id}
                     file={file} 
                     onDelete={handleDeleteFile} />
               ))}
            </ul>
            <div className='attachments-popup__actions'>
               <label className='attachments-popup__add-btn attachments-add-more-btn' for="add-more-files" >
                  <span className='attachments-add-more-btn__text'>Добавить</span>
                  <input
                     className='attachments-add-more-btn__input'
                     type="file"
                     name=""
                     id="add-more-files"
                     onChange={handleChange}
                     disabled={disabled}
                     multiple
                  />
               </label>
               <button
                  className='attachments-popup__close-btn' 
                  onClick={handleCloseFilesList}
               >
                  Закрыть
               </button>
            </div>
         </div>
      </div>
   )
}

export default AttachmentsBox