import React, { useState } from 'react'

import './PlanItem.css'

import useClickOutside from '../../../hooks/useClickOutside'
import { planItemFeaturesMap } from '../utils'
import { plansMap } from '../../../utils/consts'

import { btnSizes, Button } from '../../../components/Button'

const PlanItem = ({ 
   planItem,
   selectedPlan,
   onSelect
}) => {
   const [active, setActive] = useState(false)
   
   const { name, price, type } = planItem

   const activeStyle = active ? 'active' : ''
   const selectedStyle = planItem.type === selectedPlan.type ? 'selected' : ''
   
   const handleToggle = () => setActive(active => !active)

   const handleClose = () => setActive(false)
   
   const handleSelect = () => {
      onSelect(planItem)
      handleClose()
   }

   const ref = useClickOutside(handleClose)   

   return (
      <div className={`plan-item ${activeStyle} ${selectedStyle}`} ref={ref}>
         <button 
            className='plan-item-toggle'
            onClick={handleToggle}
         >
            <div className='plan-item-toggle__name'>
               Название:
               <span>
                  {name}
               </span>
            </div>
            <div className='plan-item-toggle__price'>
               Стоимость:
               <span>
                  {price} рублей
               </span>
            </div>
            <img 
               className='plan-item-toggle__icon'
               src='/assets/sidebar/select-dropdown-icon.svg'
               alt='Select-icon'
            />
         </button>
         <div className='plan-item-drop-down'>
            <ul className='plan-item-drop-down__list'>
               {planItemFeaturesMap[`${type}`].map(feature => (
                  <li key={feature} className='plan-item-drop-down__list-item'>
                     {feature}
                  </li>
               ))

               }
            </ul>
            <Button
               size={btnSizes.SMALL}
               text='Подключить'
               onClick={handleSelect}
               disabled={selectedPlan.id === planItem.id}
            />
         </div>
      </div>
   )
}

export default PlanItem