import React, { useState } from 'react'
import './notificationContactModal.css'
import { Button } from '../Button'
import Modal from './Modal'

const NotificationContactModal = ({ isVisible, title, text, ok, cancel }) => {
   const [disableBtn, setDisableBtn] = useState(false)

   const handleSubmit = async () => {
      setDisableBtn(true)
      await ok()
      setDisableBtn(false)
   }

   return (
      <Modal
         show={isVisible}
         title={title}
         onClose={cancel}
      >
         <p className='notification_text'>
            {text}
         </p>
         <div className="notification_button-block">
            <Button
               customStyle='notification_button'
               text='Ок'
               onClick={handleSubmit}
               disabled={disableBtn}
            />
         </div>
      </Modal>
   )
}

export default NotificationContactModal
