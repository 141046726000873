import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import * as yup from 'yup'

import './AddContact.css'

import {getUsernameFieldPlaceholder} from '../../../utils'

import { btnThemes, Button } from '../../Button'
import Modal from '../Modal'
import ChannelSelect from './ChannelSelect'

const getChannelsForManagers = (channelList = [], allowedChannelList = []) => {
   return channelList.filter(channel => (allowedChannelList || []).includes(channel.id))
}

const getParcedChannel = (channelList = [], newContactInitiationParams = {}) => {
   return channelList.find(channel => channel.identity === parseInt(newContactInitiationParams?.channel)) 
}

const AddContact = ({
   close,
   isVisible,
   channelList,
   newContactInitiationParams,
   allowedChannel,
}) => {
   const [channelsForManagers, setChannelsForManagers] = useState(getChannelsForManagers(channelList, allowedChannel))
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const formik = useFormik({
      initialValues: {
         selectedChannelType: getParcedChannel(channelList, newContactInitiationParams) || null,
         contactName: '',
         contactTelephone: '',
         defaultUsername: newContactInitiationParams?.username || '',
         contactMessage: ''
      },
      validationSchema: yup.object().shape({
         selectedChannelType: yup.object().required('Выберите канал'),
         contactName: yup.string().required('Заполните данное поле'),
         defaultUsername: yup.string().required('Заполните данное поле')
      }),
      onSubmit: async ({ 
         selectedChannelType, 
         contactName, 
         defaultUsername, 
         contactTelephone,
         contactMessage
      }, { setStatus }) => {
         var bodyFormData = new FormData()
         bodyFormData.append('channel', selectedChannelType.id)
         bodyFormData.append('name', contactName)
         bodyFormData.append('username', defaultUsername)
         if (contactTelephone.length !== 0) {
            bodyFormData.append('phone', contactTelephone)
         }
         bodyFormData.append('text', contactMessage)

         setDisableSubmitBtn(true)
         await axios({
            method: 'post',
            url: `/api/v1/contact/add?`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
         })
            .then((response) => {
               if (response.data.error === false) {
                  close()
                  window.location.reload(false)
               } else {
                  setStatus(response.data.data.error)
               }
            })
            .catch((err) => {
               setStatus("Произошла ошибка, попробуйте позже!")
            })
            .finally(() => setDisableSubmitBtn(false))
      }
   })

   const usernameFieldPlaceholder = getUsernameFieldPlaceholder(formik.values.selectedChannelType?.type)

   const handleChannelSelect = (item) => {
      formik.setFieldValue('selectedChannelType', item)
   }

   const handleChannelSelectTouch = () => {
      formik.setFieldTouched('selectedChannelType', true)
   }

   useEffect(() => {
      setChannelsForManagers(getChannelsForManagers(channelList, allowedChannel))
   }, [channelList, allowedChannel])

   useEffect(() => {
      formik.setValues({
         ...formik.values,
         selectedChannelType: getParcedChannel(channelList, newContactInitiationParams) || formik.values.selectedChannelType
      })
   }, [newContactInitiationParams, channelList])

   useEffect(() => {
      if (!isVisible) formik.resetForm()
   }, [isVisible])

   return (
      <Modal
         show={isVisible}
         title='Добавление контакта'
         onClose={close}
      >
         <form 
            className='add-contact-form'
            onSubmit={formik.handleSubmit}   
         >
            {formik.status && (<p className='add-contact-form__status'>{formik.status}</p>)}
            <div className='add-contact-form__body'>
               <ChannelSelect
                  selectedValue={formik.values.selectedChannelType}
                  values={allowedChannel === null ? channelList : channelsForManagers}
                  error={formik.touched.selectedChannelType && formik.errors.selectedChannelType}
                  onTouch={handleChannelSelectTouch}
                  onSelect={handleChannelSelect}
               />
               <div className={`add-contact-form__control ${formik.errors.contactName && formik.touched.contactName ? 'error' : ''}`}>
                  <p className='add-contact-form__control-error'>{formik.errors.contactName}</p>
                  <input
                     type='text'
                     className='add-contact-form__input'
                     placeholder='Имя'
                     maxLength='32'
                     name='contactName'
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.contactName}
                  />
               </div>
               <div className={`add-contact-form__control ${formik.errors.defaultUsername && formik.touched.defaultUsername ? 'error' : ''}`}>
                  <p className='add-contact-form__control-error'>{formik.errors.defaultUsername}</p>
                  <input
                     type='text'
                     className='add-contact-form__input'
                     placeholder={usernameFieldPlaceholder}
                     name='defaultUsername'
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.defaultUsername}
                  />
               </div>
               <div className={`add-contact-form__control ${formik.errors.contactTelephone && formik.touched.contactTelephone ? 'error' : ''}`}>
                  <p className='add-contact-form__control-error'>{formik.errors.contactTelephone}</p>
                  <input
                     type='tel'
                     className='add-contact-form__input'
                     placeholder='Телефон'
                     name='contactTelephone'
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.contactTelephone}
                  />
               </div>
               <div className='add-contact-form__control'>
                  <p className='add-contact-form__control-error'>{formik.errors.contactMessage}</p>
                  <textarea
                     className='add-contact-form__textarea'
                     placeholder='Сообщение'
                     name='contactMessage'
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.contactMessage}
                  />
               </div>
            </div>
            <div className='add-contact-form__footer'>
               <Button
                  customStyle='add-contact-form__btn'
                  theme={btnThemes.PRIMARY_SKINNY}
                  text='Отменить'
                  onClick={close}
               />
               <Button
                  customStyle='add-contact-form__btn'
                  type='submit'
                  text='Добавить'
                  disabled={disableSubmitBtn}
               />
            </div>
         </form>
      </Modal>
   )
}

export default AddContact
