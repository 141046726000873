import React, { useState } from 'react'

import './StatusSelect.css'

import { useClickOutside } from '../../../hooks'

import Radio from '../../Radio'

const StatusSelect = ({ selectedValue, values, disabled = false, onChange }) => {
   const [show, setShow] = useState(false)
   const [disableOption, setDisableOption] = useState(false)
   
   const handleToggle = () => setShow(show => !show)

   const handleClose = () => setShow(false)

   const handleSelect = async (value) => {
      if (value.value === selectedValue.value) return handleClose()

      setDisableOption(true)
      handleClose()
      await onChange(value)      
      setDisableOption(false)
   }

   const ref = useClickOutside(handleClose)

   return (
      <div className={`status-select ${show ? 'active' : ''} ${disabled ? 'status-select_disabled' : ''}`} ref={ref}>
         <button 
            className='status-select__selected-value'
            style={{
               backgroundColor: selectedValue.bg
            }}
            onClick={handleToggle}
            disabled={disabled}
         >
            {selectedValue.label}
            <svg className='status-select__icon' width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M4.47994 0.478478C4.67521 0.283216 4.99179 0.283216 5.18705 0.478478L9.18705 4.47848C9.38231 4.67374 9.38231 4.99032 9.18705 5.18558C8.99179 5.38085 8.67521 5.38085 8.47994 5.18558L4.8335 1.53914L1.18705 5.18558C0.991787 5.38085 0.675205 5.38085 0.479943 5.18558C0.284681 4.99032 0.284681 4.67374 0.479943 4.47848L4.47994 0.478478Z" fill="#252525"/>
            </svg>
         </button>
         <div className='status-select__popup'>
            <ul className='status-select__list'>
               {values.map(value => {
                  const isSelected = value.value === selectedValue.value
                  return (
                     <li
                        key={value.value}
                        className='status-select__list-item'                        
                     >
                        <button
                           className={`status-select__option ${isSelected ? 'selected' : ''}`}
                           onClick={() => handleSelect(value)}
                           disabled={disableOption}
                        >
                           <Radio checked={isSelected} />
                           <span className='status-select__option-text'>
                              {value.label}
                           </span>
                        </button>
                     </li>
                  )
               })}
            </ul>
         </div>
      </div>
   )
}

export default StatusSelect