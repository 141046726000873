import React from 'react'

import './ContactInfoItem.css'

const ContactInfoItem = ({ label, value }) => {
   return (
      <div className='contact-info-item'>
         <p className='contact-info-item__label'>
            {label}
         </p>
         <p className='contact-info-item__value'>
            {value}
         </p>
      </div>
   )
}

export default ContactInfoItem