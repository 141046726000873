import { useCallback, useEffect, useMemo } from 'react'
import useState from 'react-usestateref'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import debounce from 'lodash.debounce'
import qs from "qs"
import MessengerWS from '../services/MessengerWS'
import { Sidebar } from '../components/sidebar/Sidebar'
import Mainbar from '../components/mainbar/Mainbar'
import useWindowDimensions from '../components/utils/WindowDimensions'
import './messenger.css'
import Navbar from '../components/navbar/Navbar'
import { 
   getNormalizedDialog, 
   mapDialogsSearchToQuery, 
   mapDialogsSearchToFilter, 
   mapDialogsFilterToQuery, 
   mapDialogsFilterToSearch,
   createManagerAccessNotification
} from '../utils'
import Notifications from '../services/Notifications'
import { useDisplayNotificationsQtyInTitle } from '../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/slices/auth/thunk-creators'
import Onboarding from '../components/Onboarding'
import ManagersService from '../services/managersService'
import { managerStatuses, userEvents, socketEventTypes, managerAccessNotificationsData } from '../utils/consts'
import AuthService from '../services/authService'
import { dialogs_selectDialogs, dialogs_selectSelectedDialog } from '../store/slices/dialogs/selectors'
import { dialogsFilter_selectFilter } from '../store/slices/dialogsFilter/selectors'
import { 
   setDialogs, 
   setSelectedDialog, 
   updateDialogsOnMessageStatusEvent, 
   updateDialogsOnUpdateMessageEvent 
} from '../store/slices/dialogs'
import { 
   setDialogsFilter, 
   setDialogsFilterDateStatus, 
   setDialogsFilterSearchValue, 
   setDialogsFilterUnreadOnly 
} from '../store/slices/dialogsFilter'
import { 
   attachTagToDialog, 
   deleteAttachedTagFromDialog, 
   fetchDialogs, 
   readDialog, 
   setDialogContactDate, 
   setDialogStatus, 
   updateDialogName, 
   updateDialogsOnNewMessage, 
   updateSelectedDialogOnManagerAccessChange,
   updateSelectedDialogOnNewMessage
} from '../store/slices/dialogs/thunk-creators'
import { messages_selectMessages, messages_selectMessagesLoader } from '../store/slices/messages/selectors'
import { 
   setMessages, 
   setMessagesLoader, 
   updateMessagesOnMessageStatusEvent, 
   updateMessagesOnNewMessage, 
   updateMessagesOnUpdateMessageEvent 
} from '../store/slices/messages'
import { messagesFilter_selectEntireFilter } from '../store/slices/messagesFilter/selectors'
import { setMessagesFilterCursor } from '../store/slices/messagesFilter'
import { fetchMessages, toggleMessageStatus, readMessages, editMessage, forwardMessages, revokeMessage } from '../store/slices/messages/thunk-creators'
import store from '../store'
import { fetchChannelsWithoutRelations } from '../store/slices/channels/thunk-creators'
import { createDialogStep, deleteDialogStep, fetchDialogSteps, fetchDialogStepsWithoutRelations, updateDialogStep } from '../store/slices/dialogSteps/thunk-creators'
import { fetchTagsWithoutRelations } from '../store/slices/tags/thunk-creators'
import Notificator from '../components/Notificator'
import { logoutSuccess, updateUserOnAccountSettingChange, updateUserOnManagerAccessChange } from '../store/slices/auth'
import { dialogSteps_selectDialogSteps } from '../store/slices/dialogSteps/selectors'
import { templates_selectTemplateSubsections, templates_selectTemplates } from '../store/slices/templates/selectors'
import { 
   createTemplate, 
   createTemplateSubsection, 
   deleteTemplate, 
   deleteTemplateSubsection, 
   fetchTemplateSubsections, 
   fetchTemplates, 
   updateTemplate, 
   updateTemplateSubsection 
} from '../store/slices/templates/thunk-creators'
import { setSelectedContactId } from '../store/slices/contacts'
import { contacts_selectSelectedContact, contacts_selectSelectedContactId } from '../store/slices/contacts/selectors'
import { addManagerAccessNotification } from '../store/slices/notifications'
import { fetchContacts } from '../store/slices/contacts/thunk-creators'
import { mapContactsFilterToQuery } from '../utils/normalizeContactsParams'
import { contactsFilter_selectFilter } from '../store/slices/contactsFilter/selectors'
import { fetchManagersFinished, fetchManagersStarted, fetchManagersSuccess } from '../store/slices/managers'

const Messenger = ({
   currentUser,
   isUserAuthorized,
   setIsUserAuthorized,
   userName,
   autoReadStatus,
   setAutoReadStatus,
   isManager,
   userId,
   setUserId,
   allowedStatus,
   allowedTag,
   allowedChannel,
   disableExit,
}) => {
   const [sidebarToggle, setSidebarToggle] = useState(1)
   const [toggleSidebarView, setToggleSidebarView, toggleSidebarViewRef] =
      useState(true)
   const [unreadMessagesIds, setUnreadMessagesIds] = useState([])
   const [channelStatus, setChannelStatus] = useState({
      value: 1,
      label: 'Все сообщения',
   })
   const [addManagerPage, setAddManagerPage] = useState(0)
   const [managerCardClick, setManagerCardClick] = useState(0)

   const { width } = useWindowDimensions()
   const [newContactInitiationParams, setNewContactInitiationParams] =
      useState(null)
   const [showAddContactModal, setShowAddContactModal] = useState(false)
   const [settingsToggle, setSettingsToggle] = useState(0)
   const [messengerLoader, setMessengerLoader] = useState(false)
   const [channelList, setChannelList] = useState([])
   const [, setInstagramConvoFilter, instagramConvoFilterRef] = useState(null)

   const [tag, setTag] = useState([])
   const [messageText, setMessageText] = useState('')
   const [selectTemplate, setSelectTemplate] = useState({})
   const [managerList, setManagerList] = useState(null)
   const [managers, setManagers] = useState(null)
   const [managerSidebarLoader, setManagerSidebarLoader] = useState(true)

   const userOnboarding = currentUser?.user_events.find(event => (
      event.code === userEvents.onBoarding.code
   ))
   const isOnboardingCompleted = [
      userEvents.onBoarding.values.skipped,
      userEvents.onBoarding.values.finished
   ].includes(userOnboarding?.value)

   const location = useLocation()
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const handleMessageTextChange = useCallback((value) => setMessageText(value), [])

   const [handleGettingNewNotification] = useDisplayNotificationsQtyInTitle()

   const handleLogout = async () => {
      await dispatch(logout())
   }

   const followUpDateEnabled = currentUser.follow_up_date_enabled   
   const selectedDialog = useSelector(dialogs_selectSelectedDialog)
   const dialogs = useSelector(dialogs_selectDialogs)
   const dialogsFilter = useSelector(dialogsFilter_selectFilter)
   const messagesLoader = useSelector(messages_selectMessagesLoader)
   const messages = useSelector(messages_selectMessages)
   const messagesFilter = useSelector(messagesFilter_selectEntireFilter)
   const dialogSteps = useSelector(dialogSteps_selectDialogSteps)
   const templates = useSelector(templates_selectTemplates)
   const templateSubsections = useSelector(templates_selectTemplateSubsections)
   const selectedContactId = useSelector(contacts_selectSelectedContactId)
   const selectedContact = useSelector(contacts_selectSelectedContact)

   const selectedDialogId = selectedDialog?.dialog_id
   const dialogsSearchValue = dialogsFilter.searchValue
   const dialogsUnreadOnly = dialogsFilter.unreadOnly
   const dialogsDateStatus = dialogsFilter.dateStatus
   const messagesCursor = messagesFilter.cursor

   const [dialogsLoading, setDialogsLoading] = useState(false)
   const [shouldUpdateQuery, setShouldUpdateQuery] = useState(false)
   const [shouldFetchDialogs, setShouldFetchDialogs] = useState(false)
   const [shouldFetchMessagesOnFilterChange, setShouldFetchMessagesOnFilterChange] = useState(false)
   const [showSystemNotification, setShowSystemNotification] = useState(false)
   const [selectedRepliedMessage, setSelectedRepliedMessage] = useState(null)
   const [selectedMessageToEdit, setSelectedMessageToEdit] = useState(null)

   const handleSetDialogs = (dialogs) => {
      dispatch(setDialogs(dialogs))
   }

   const handleSetSelectedDialog = (dialog) => {
      dispatch(setSelectedDialog(dialog))
   }

   const handleSetSelectedContact = (contactId) => {
      dispatch(setSelectedContactId(contactId))      
   }

   const handleCloseSystemNotification = () => setShowSystemNotification(false)

   const handleSearchChange = useMemo(() => debounce((searchValue) => {
      dispatch(setDialogsFilterSearchValue(searchValue))
   }, 500), [])

   const handleUnreadOnlyChange = useMemo(() => debounce((value) => {
      dispatch(setDialogsFilterUnreadOnly(value))
   }, 300), [])

   const handleDateStatusChange = useMemo(() => debounce((value) => {
      dispatch(setDialogsFilterDateStatus(value))
   }, 300), [])

   const handleFetchDialogs = async (query) => {
      setDialogsLoading(true)
      await dispatch(fetchDialogs(query))
      setDialogsLoading(false)
   }

   const debouncedFetchDialogs = debounce((query) => handleFetchDialogs(query), 500)

   const handleDialogsScroll = async () => {
      const query = mapDialogsFilterToQuery(dialogsFilter) 
      await dispatch(fetchDialogs(query, true))
   }

   const mapMessagesFilterToQuery = (filter) => {
      const params = {}
      params.dialog = filter.dialogId
      switch (filter?.filter) {
         case 3:
            params.filter = 'comment'
            setInstagramConvoFilter('comment')
            break;
         case 2:
            params.filter = 'direct'
            setInstagramConvoFilter('direct')
            break;
         default:
            setInstagramConvoFilter('all')
            break;
      }
      filter.cursor && (params.cursor = filter.cursor)
      return qs.stringify(params, { addQueryPrefix: true })
   }

   const handleSetMessages = (messages) => {
      dispatch(setMessages(messages))
   }

   const handleSetMessagesCursor = (cursor) => {
      dispatch(setMessagesFilterCursor(cursor))
   }

   const handleFetchMessages = async (query) => {
      await dispatch(fetchMessages(query))       
   }

   const handleFetchNextMessages = async (cursor) => {
      const filter = {
         dialogId: selectedDialogId,
         filter: channelStatus.value,
         cursor: cursor || messagesCursor
      }
      const query = mapMessagesFilterToQuery(filter)
      return await dispatch(fetchMessages(query, true))
   }

   const handleMessagesScroll = async () => {
      await handleFetchNextMessages()
   }

   const handleReadDialogAndFetchMessages = async (dialogId, query) => {
      if (autoReadStatus) {
         await dispatch(readDialog(dialogId))
      }
      await handleFetchMessages(query)
   }

   const handleForwardMessages = async (body) => {
      await dispatch(forwardMessages(body))
   }

   const handleDeleteSelectedMessages = async (messages) => {
      for (let i = 0; i < messages.length; i++) {
         dispatch(revokeMessage({
            dialogId: messages[i].dialog_id,
            messageId:  messages[i].id
         }))
         await new Promise((resolve) => setTimeout(resolve, 200))
      }
   }

   const handleDialogStatusSelect = async (dialogId, statusData) => {
      await dispatch(setDialogStatus(dialogId, statusData))
   }

   const handleDialogTagSelect = async (tag) => {
      await dispatch(attachTagToDialog(tag))
   }

   const handleDeleteAttachedTagFromDialog = async (tagId, dialog) => {
      return await dispatch(deleteAttachedTagFromDialog(tagId, dialog))
   }

   const handleDialogContactDateSelect = async ({ dialog_id, date }) => {
      const formattedDate = new Date(date).toISOString().slice(0, 10)      
      await dispatch(setDialogContactDate({ dialog_id, follow_up_date: formattedDate }))
   }

   const handleDialogContactDateDelete = async (dialog_id) => {     
      await dispatch(setDialogContactDate({ dialog_id, follow_up_date: '' }))
   }

   const handleMessageStatusClick = async (messageData) => {
      if (!autoReadStatus) {
         dispatch(toggleMessageStatus(messageData))
      } else {
         alert('Включите настройку "Оставлять сообщения непрочитанными" для использования функционала!')
      }
   }

   const handleReadMessages = async (unreadMessagesIds) => {
      setUnreadMessagesIds([])
      dispatch(readMessages(unreadMessagesIds))
   }

   const handleFetchUnattachedDialogEntities = async () => {
      return await Promise.all([
         dispatch(fetchChannelsWithoutRelations()),
         dispatch(fetchDialogStepsWithoutRelations()),
         dispatch(fetchTagsWithoutRelations())
      ])
   }

   const handleCheckIsThereUnattachedDialogEntities = async () => {
      const data = await handleFetchUnattachedDialogEntities()
      const isThereUnattachedEntities = data.some(dataEntity => dataEntity !== null && dataEntity?.length)
      setShowSystemNotification(isThereUnattachedEntities ? true : false)
   }

   const handleNotificationActionBtnClick = () => {
      navigate('/managers', { state: { isPermissionsPage: true } })
      setSidebarToggle(3)
      if (managers?.length > 0) {
         setManagerCardClick(managers[0])
         setAddManagerPage(managers[0])
      }
      handleCloseSystemNotification()
   }

   const handleFetchContacts = async (query) => {
      await dispatch(fetchContacts(query))
   }

   const debouncedFetchContacts = debounce((query) => handleFetchContacts(query), 500)

   const handleConnectionChange = (e) => {
      const isConnected = e.type === 'online'
      const state = store.getState()
      const messagesLoader = messages_selectMessagesLoader(state)
      const selectedDialogId = dialogs_selectSelectedDialog(state).dialog_id

      if (isConnected && messagesLoader) {
         const filter = {
            dialogId: selectedDialogId,
            filter: channelStatus.value
         }
         const query = mapMessagesFilterToQuery(filter)
         handleFetchMessages(query)
      }
   }

   useEffect(() => {
      window.addEventListener('online', handleConnectionChange)
      window.addEventListener('offline', handleConnectionChange)

      return () => {
        window.removeEventListener('online', handleConnectionChange)
        window.removeEventListener('offline', handleConnectionChange)
      }
   }, [])

   useEffect(() => {
      if (sidebarToggle === 1) {
         const query = mapDialogsSearchToQuery(location.search)
         const currentFilter = mapDialogsSearchToFilter(location.search)
         dispatch(setDialogsFilter(currentFilter))
         handleFetchDialogs(query)
      }

      return () => {
         if (sidebarToggle === 1) {
            setShouldFetchDialogs(false)
            dispatch(setDialogsFilter({
               searchValue: '',
               unreadOnly: false
            }))
         }
      }
   }, [sidebarToggle])

   useEffect(() => {
      if (sidebarToggle === 1) {
         if (shouldUpdateQuery) {
            const search = mapDialogsFilterToSearch(dialogsFilter)
            navigate(search)
            setShouldFetchDialogs(true)
         } else {
            setShouldUpdateQuery(true)
         }
      }
   }, [dialogsFilter])

   useEffect(() => {
      if (sidebarToggle === 1) {
         if (shouldFetchDialogs) {
            const query = mapDialogsFilterToQuery(dialogsFilter)
            handleFetchDialogs(query)
         }
      }
   }, [dialogsSearchValue, dialogsUnreadOnly, dialogsDateStatus])

   useEffect(() => {
      if (selectedDialogId) {
         handleSetMessagesCursor(null)
         const filter = {
            dialogId: selectedDialogId,
            filter: channelStatus.value
         }
         const query = mapMessagesFilterToQuery(filter)
         handleReadDialogAndFetchMessages(selectedDialogId, query)
      }
      setInstagramConvoFilter(null)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedDialogId])

   useEffect(() => {
      if (isManager) return
      handleFetchUnattachedDialogEntities()
   }, [])

   useEffect(() => {
      handleMessageTextChange('')
      setSelectTemplate({})
   }, [selectedDialogId])

   useEffect(() => {
      if (selectTemplate.text) {
         handleMessageTextChange(selectTemplate.text)
      } else {
         handleMessageTextChange('')
      }
   }, [selectTemplate])

   useEffect(() => {
      dispatch(fetchTemplates())
   }, [])

   useEffect(() => {
      dispatch(fetchTemplateSubsections())
   }, [])

   const handleCreateTemplate = async (template) => {
      return await dispatch(createTemplate(template))
   }

   const handleUpdateTemplate = async (template) => {
      return await dispatch(updateTemplate(template))
   }

   const handleDeleteTemplate = async (id) => {
      return await dispatch(deleteTemplate(id))
   }

   const handleCreateTemplateSubsection = async (templateSubsection) => {
      return await dispatch(createTemplateSubsection(templateSubsection))
   }

   const handleUpdateTemplateSubsection = async (templateSubsection) => {
      return await dispatch(updateTemplateSubsection(templateSubsection))
   }

   const handleDeleteTemplateSubsection = async (id) => {
      return await dispatch(deleteTemplateSubsection(id))
   }

   const addTag = (body) => {
      var bodyFormData = new FormData()
      bodyFormData.append('name', body.name)

      axios({
         method: 'post',
         url: `/api/v1/tag/add`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setTag([...tag, response.data.data])
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const deleteTag = (id) => {
      axios({
         method: 'delete',
         url: `/api/v1/tag/delete?id=` + id,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setTag((prevState) => {
                  return prevState.filter((tag) => {
                     return tag.id !== id
                  })
               })
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const editTag = (body) => {
      var bodyFormData = new FormData()
      bodyFormData.append('id', body.id)
      bodyFormData.append('name', body.name)

      axios({
         method: 'post',
         url: `/api/v1/tag/update`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setTag((prevState) => {
                  return prevState.map((tag) => {
                     if (tag.id !== body.id) return tag
                     return { ...tag, name: body.name }
                  })
               })
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const addStatus = async (body) => {
      await dispatch(createDialogStep(body))
   }

   const deleteStatus = async (id) => {
      await dispatch(deleteDialogStep(id))
   }

   const editStatus = async (body) => {
      await dispatch(updateDialogStep(body))
   }

   useEffect(() => {
      axios({
         method: 'get',
         url: `/api/v1/tag/get`,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setTag([...tag, ...response.data.data])
            }
         })
         .catch((err) => {
            console.log(err)
         })
   }, [])

   useEffect(() => {
      dispatch(fetchDialogSteps())
   }, [])

   useEffect(() => {
      setChannelStatus({
         value: 1,
         label: 'Все сообщения',
      })
   }, [selectedDialogId])

   const handleGettingNewMessage = (newMessage) => {
      handleGettingNewNotification(newMessage.dialog_id)
      dispatch(updateDialogsOnNewMessage(newMessage))

      const state = store.getState()
      const selectedDialogId = dialogs_selectSelectedDialog(state)?.dialog_id

      if (selectedDialogId === newMessage.dialog_id) {
         dispatch(updateSelectedDialogOnNewMessage(newMessage))

         if (
            instagramConvoFilterRef.current &&
            instagramConvoFilterRef.current === 'comment' &&
            newMessage.comment_id === null
         ) return

         dispatch(updateMessagesOnNewMessage(newMessage))
      }
   }

   const handleMessageStatusEvent = (messageStatusData) => {
      dispatch(updateDialogsOnMessageStatusEvent(messageStatusData))

      const state = store.getState()
      const selectedDialogId = dialogs_selectSelectedDialog(state)?.dialog_id
      
      if (selectedDialogId) {
         dispatch(updateMessagesOnMessageStatusEvent(messageStatusData))
      }
   }

   const handleLogoutEvent = () => {
      dispatch(logoutSuccess())
   }

   const handleUpdateMessageEvent = (newMessage) => {
      dispatch(updateDialogsOnUpdateMessageEvent(newMessage))

      const state = store.getState()
      const selectedDialogId = dialogs_selectSelectedDialog(state)?.dialog_id
      
      if (selectedDialogId) {
         dispatch(updateMessagesOnUpdateMessageEvent(newMessage))
      }
   }

   const handleUpdateSelectedDialogOnManagerAccessChange = async (id) => {
      await dispatch(updateSelectedDialogOnManagerAccessChange(id))
   }

   const debouncedUpdateSelectedDialogOnManagerAccessChange = debounce(
      (id) => handleUpdateSelectedDialogOnManagerAccessChange(id)
   , 500)

   const handleCreateManagerAccessNotification = (data) => {
      dispatch(addManagerAccessNotification(createManagerAccessNotification(
         data
      )))
   }

   const handleUpdateManagerAccessChangeEvent = (data) => {
      dispatch(updateUserOnManagerAccessChange(data))

      const state = store.getState()
      const selectedDialog = dialogs_selectSelectedDialog(state)
      const selectedContact = contacts_selectSelectedContact(state)
      const { 
         allowed_channel, 
         allowed_tag, 
         allowed_status, 
         hide_client_identity, 
         edit_dialog_allowed 
      } = data

      if (
         allowed_channel ||
         allowed_tag ||
         allowed_status ||
         hide_client_identity !== undefined
      ) {
         const state = store.getState()
         
         if (location.pathname === '/dialogs') {
            const dialogsFilter = dialogsFilter_selectFilter(state)
            const query = mapDialogsFilterToQuery({ ...dialogsFilter, cursor: null })
            debouncedFetchDialogs(query)
         } else if (location.pathname === '/contacts') {
            const contactsFilter = contactsFilter_selectFilter(state)            
            const query = mapContactsFilterToQuery({ ...contactsFilter, cursor: null })
            debouncedFetchContacts(query)
         }
         
         if (selectedDialog) {
            debouncedUpdateSelectedDialogOnManagerAccessChange(selectedDialog.dialog_id)
         }
         
         if (selectedDialog && hide_client_identity !== undefined) {
            const filter = {
               dialogId: selectedDialog.dialog_id,
               filter: channelStatus.value
            }
            const query = mapMessagesFilterToQuery(filter)
            handleFetchMessages(query)
         }
      }
      
      if (selectedDialog) {
         if (allowed_channel && !allowed_channel.includes(selectedDialog.client.channel_id)) {
            handleCreateManagerAccessNotification(managerAccessNotificationsData.dialogAccessChanged)
         } else if (allowed_tag && !selectedDialog.dialog_tag.some(tag => allowed_tag.includes(tag.id))) {
            handleCreateManagerAccessNotification(managerAccessNotificationsData.dialogAccessChanged)
         } else if (allowed_status && !allowed_status.includes(selectedDialog.dialog_status.id)) {
            handleCreateManagerAccessNotification(managerAccessNotificationsData.dialogAccessChanged)
         } else if (edit_dialog_allowed === false) {            
            handleCreateManagerAccessNotification(managerAccessNotificationsData.editDialogAccessChanged)
         }
      } else if (selectedContact) {
         if (allowed_channel && !allowed_channel.includes(selectedContact.dialog.client.channel_id)) {
            handleCreateManagerAccessNotification(managerAccessNotificationsData.dialogAccessChanged)
         } else if (allowed_tag && !selectedContact.dialog.tags.some(tag => allowed_tag.includes(tag.id))) {
            handleCreateManagerAccessNotification(managerAccessNotificationsData.dialogAccessChanged)
         } else if (allowed_status && !allowed_status.includes(selectedContact.dialog.status.id)) {
            handleCreateManagerAccessNotification(managerAccessNotificationsData.dialogAccessChanged)
         } else if (edit_dialog_allowed === false) {            
            handleCreateManagerAccessNotification(managerAccessNotificationsData.editDialogAccessChanged)
         }
      }
   }

   const handleAccountSettingChangeEvent = (data) => {
      dispatch(updateUserOnAccountSettingChange(data))
      if (data.follow_up_date_enabled === false) {
         dispatch(setDialogsFilter({
            cursor: '',
            dateStatus: {
               name: 'Все',
               value: null
            }
         }))
      }
   }

   const newMessageSubscriber = async (newMessage) => {
      if (!newMessage.eventType) {
         handleGettingNewMessage(newMessage)
         return
      }
      switch (newMessage.eventType) {
         case socketEventTypes.MESSAGE:
            handleGettingNewMessage(newMessage.payload)
            break
         case socketEventTypes.STATUS:
            handleMessageStatusEvent(newMessage.payload)
            break
         case socketEventTypes.LOGOUT:
            handleLogoutEvent()
            break
         case socketEventTypes.UPDATE_MESSAGE:
            handleUpdateMessageEvent(newMessage.payload)
            break
         case socketEventTypes.MANAGER_ACCESS_CHANGE:
            handleUpdateManagerAccessChangeEvent(newMessage.payload)
            break
         case socketEventTypes.ACCOUNT_SETTING_CHANGE:
            handleAccountSettingChangeEvent(newMessage.payload)
            break
         default:
            return
      }
   }

   const startMessengerWS = () => {
      const postfix = isManager ? '-' : ''

      MessengerWS.subscribe(`PERSONAL_USER_CHANNEL_${userId}#${postfix}${currentUser.id}`, newMessageSubscriber)
   }

   const stopMessengerWS = () => {
      MessengerWS.unsubscribe()
   }

   const handleContactCardClick = (contact) => {
      handleSetSelectedDialog(null)
      handleSetSelectedContact(contact)
   }

   const handleEditManagerFormSubmit = async (id, { username, email, password }) => {
      try {
         const formData = new FormData()
         formData.append('username', username)
         formData.append('email', email)
         password && formData.append('password', password)

         const data = await ManagersService.updateManager(id, formData)
         if (!data.error) {
            setManagerList(managers => managers.map(manager => (
               manager.id === id
                  ? {
                     ...manager,
                     username,
                     email
                  }
                  : manager
            )))
            setManagers(managers => managers.map(manager => (
               manager.id === id
                  ? {
                     ...manager,
                     username,
                     email
                  }
                  : manager
            )))
            setAddManagerPage(data => ({
               ...data,
               username,
               email
            }))
         } else {
            return Promise.reject(data.data.error)
         }
      } catch (err) {
         return Promise.reject('Не удалось обновить данные. Пожалуйста обновите страницу')
      }
   }

   const handleToggleManagerActivation = async (id, value) => {
      const isManagerActive = value !== managerStatuses.active
      const action = isManagerActive ? ManagersService.deactivate : ManagersService.activate
      try {
         const data = await action(id)
         if (!data.error) {
            setManagerList(managers => managers.map(manager => (
               manager.id === id
                  ? {
                     ...manager,
                     status: isManagerActive ? managerStatuses.inactive : managerStatuses.active
                  }
                  : manager
            )))
            setManagers(managers => managers.map(manager => (
               manager.id === id
                  ? {
                     ...manager,
                     status: isManagerActive ? managerStatuses.inactive : managerStatuses.active
                  }
                  : manager
            )))
            setAddManagerPage(data => ({
               ...data,
               status: isManagerActive ? managerStatuses.inactive : managerStatuses.active
            }))
            handleCheckIsThereUnattachedDialogEntities()
         } else {
            if (data.data.name) return Promise.reject(data.data.name)
         }
      } catch (err) {
         alert('Не удалось обновить статус менеджера')
      }
   }

   const handleDeleteManager = async (id) => {
      try {
         const data = await ManagersService.delete(id)
         if (!data.error) {
            setManagerList(managers => managers.filter(manager => manager.id !== id))
            setManagers(managers => managers.filter(manager => manager.id !== id))
            setAddManagerPage(0)
            handleCheckIsThereUnattachedDialogEntities()
         }
      } catch (err) {
         alert('Не удалось удалить менеджера')
      }
   }

   const handleUpdateManagerIsAllowedToEditDialog = async (formData) => {
      try {
         const data = await ManagersService.updateIsEditDialogAllowed(formData)

         if (!data.error) {
            setAddManagerPage(data => ({ ...data, edit_dialog_allowed: formData.editDialog }))
         }
      } catch (err) {
         alert('Не удалось обновить разрешение "Возможность редактировать информацию о диалогах"')
      }
   }

   const handleUpdateDialogName = async (data) => {
      try {
         await dispatch(updateDialogName(data))
      } catch (err) {
         return Promise.reject(err)
      }
   }

   const handleUpdateIsContactInfoHidden = async (body) => {
      try {
         const data = await ManagersService.updateIsContactInfoHidden(body)
         
         if (data.error) {
            alert(data.data.error)
         }
      } catch (err) {
         alert('Не удалось обновить право на просмотр контактных данных!')
      }
   }

   const handleUpdateIsContactListUnloadingAllowed = async (body) => {
      try {
         const data = await ManagersService.updateIsContactListUnloadingAllowed(body)
         
         if (data.error) {
            alert(data.data.error)
         }
      } catch (err) {
         alert('Не удалось обновить право на выгрузку контактных данных!')
      }
   }

   const handleCompleteOnboarding = async (eventData) => {
      try {
         const data = await AuthService.sendUserEvent(eventData)
         if (data.error) alert('Не удалось пропустить обучение')
      } catch (err) {
         alert('Не удалось пропустить обучение')
      }
   }

   const handleReplyMessage = (message) => {
      setSelectedRepliedMessage(message)
      setSelectedMessageToEdit(null)
      handleMessageTextChange('')
   }
   
   const handleReplyMessageClick = async (id, nextMessagesData) => {
      const messagesList = nextMessagesData?.messages || messages
      const cursor = nextMessagesData?.next_cursor

      const originalMessage = messagesList.find(message => message.id === id)
      if (originalMessage) {
         setSelectedRepliedMessage(id)
      } else {
         const data = await handleFetchNextMessages(cursor)
         handleReplyMessageClick(id, data)
      }
   }

   const handleEditMessageClick = (message) => {
      setSelectedRepliedMessage(null)
      setSelectedMessageToEdit(message)
      handleMessageTextChange(message.text)
   }

   const handleEditMessageCancel = () => {
      setSelectedMessageToEdit(null)
      handleMessageTextChange('')
   }

   const handleEditMessage = async (body) => {
      try {
         await dispatch(editMessage(body))
         handleEditMessageCancel()
      } catch (err) {
         alert(err)
      }
   }

   const handleNavigationToPlanPage = () => {
      setSidebarToggle(6)
      setAddManagerPage(false)
      setToggleSidebarView(false)
   }

   useEffect(() => {
      return () => {
         setSelectedMessageToEdit(null)
      }
   }, [selectedDialog])

   useEffect(() => {
      startMessengerWS()

      return () => {
         stopMessengerWS()
      }
   }, [])

   useEffect(() => {
      if ('Notification' in window) {
         Notification.requestPermission().then((permission) => {
            if (permission === 'granted' && 'serviceWorker' in navigator) {
               navigator.serviceWorker
                  .register('/notificationsSW.js')
                  .then(() => {
                     console.log('Service Worker registered')
                  })
                  .catch((registrationError) => {
                     console.error(
                        'Service Worker registration failed: ',
                        registrationError
                     )
                  })
            }
         })
      }
   }, [])

   useEffect(() => {
      if (unreadMessagesIds.length) {
         const timerId = setTimeout(() => {
            handleReadMessages(unreadMessagesIds)
         }, 1000)
         return () => clearTimeout(timerId)
      }
   }, [unreadMessagesIds])

   useEffect(() => {
      setAddManagerPage(0)
      setManagerCardClick(0)
      dispatch(setSelectedDialog(null))
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [sidebarToggle])

   useEffect(() => {
      if(shouldFetchMessagesOnFilterChange) {
         const filter = {
            dialogId: selectedDialogId,
            filter: channelStatus.value
         }
         const query = mapMessagesFilterToQuery(filter)
         handleFetchMessages(query)
      } else {
         setShouldFetchMessagesOnFilterChange(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [channelStatus.value])

   useEffect(() => {
      axios
         .get(`/api/v1/channel/get?`, { withCredentials: true })
         .then(function (response) {
            // handle success
            setChannelList(response.data.data)
         })
         .catch(function (error) {
            // handle error
            console.log(error)
         })

      let dialogFinder = new URLSearchParams(window.location.search).get(
         'dialog'
      )
      if (dialogFinder !== null) {
         try {
            let decodedData = atob(dialogFinder)
            let regex = /username=(\w+)&channel_name=(\w+)/g
            let match = regex.exec(decodedData)
            let params = {
               username: match[1],
               channel: match[2],
            }
            axios
               .get(`/api/v1/dialog/get?${decodedData}`, {
                  withCredentials: true,
               })
               .then(function (response) {
                  if (response.data.error === true) {
                     setNewContactInitiationParams(params)
                     setSidebarToggle(2)
                     setShowAddContactModal(true)
                  } else {
                     const normalizedDialog = getNormalizedDialog(response.data.data)
                     dispatch(setSelectedDialog(normalizedDialog))
                  }
               })
               .catch(function (error) {
                  console.log(error)
               })
         } catch (error) {
            alert('Ссылку невозможно открыть!')
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if (sidebarToggle === 3) {
         setManagerSidebarLoader(true)
         dispatch(fetchManagersStarted())
         axios
            .get(`/api/v1/user/managers`, { withCredentials: true })
            .then(function (response) {
               // handle success
               if (!response.data.error) {
                  dispatch(fetchManagersSuccess(response.data.data))
               }
               setManagerList(response.data.data)
               setManagers(response.data.data)
               setManagerSidebarLoader(false)
            })
            .catch(function (error) {
               // handle error
               console.log(error)
               setManagerSidebarLoader(false)
            })
            .finally(() => dispatch(fetchManagersFinished()))
      }
   }, [sidebarToggle])
   
   useEffect(() => {
      if (location !== null && location.pathname !== null) {
         if (location.pathname === '/dialogs') {
            setSidebarToggle(1)
            setAddManagerPage(0)
            setSettingsToggle(0)
            setToggleSidebarView(true)
         } else if (location.pathname === '/contacts') {
            setSidebarToggle(2)
            setAddManagerPage(0)
            setSettingsToggle(0)
         } else if (location.pathname === '/managers' && !isManager) {
            setSidebarToggle(3)
            setSettingsToggle(0)
         } else if (location.pathname === '/settings') {
            setSidebarToggle(4)
         } else if (location.pathname === '/chat-bots' && !isManager) {
            setSidebarToggle(4)
            setSettingsToggle(1)
         } else if (location.pathname === '/template') {
            setSidebarToggle(4)
            setSettingsToggle(2)
         } else if (location.pathname === '/tags-statuses' && !isManager) {
            setSidebarToggle(4)
            setSettingsToggle(3)
         } else if (location.pathname === '/dialog-settings') {
            setSidebarToggle(4)
            setSettingsToggle(4)
         } else if (location.pathname === '/mailing') {
            setSidebarToggle(5)
            setToggleSidebarView(false)
            setSettingsToggle(0)
         } else if (location.pathname === '/plan') {
            setSidebarToggle(6)
            setToggleSidebarView(false)
         }
      }
      if (sidebarToggle !== 1) {
         window.history.pushState('dialogs', 'dialogs', location.pathname);
      } else {
         window.history.pushState('dialogs', 'dialogs', '/dialogs');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [sidebarToggle])
   
   if (messengerLoader) {
      return (
         <div className="auth-loader">
            <div className="lds-ellipsis">
               <div></div>
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      )
   } else {
      if (width >= 900) {
         return (
            <div className="messenger">
               {showSystemNotification && (
                  <Notificator
                     showActionBtn={managers.length > 0}
                     onClose={handleCloseSystemNotification}
                     onClick={handleNotificationActionBtnClick}
                  />
               )}
               <Onboarding
                  isCompleted={isOnboardingCompleted}
                  onComplete={handleCompleteOnboarding}
                  setSidebarToggle={setSidebarToggle}
                  setToggleSidebarView={setToggleSidebarView}
                  handleMessageTextChange={handleMessageTextChange}
               />
               <div
                  className={
                     toggleSidebarViewRef.current
                        ? 'messenger-wrapper-active'
                        : 'messenger-wrapper-inactive'
                  }
               >
                  <Navbar
                     setToggleSidebarView={setToggleSidebarView}
                     sidebarToggle={sidebarToggle}
                     setSidebarToggle={setSidebarToggle}
                     setAddManagerPage={setAddManagerPage}
                     isManager={isManager}
                     disableExit={disableExit}
                     setSelectedConversation={handleSetSelectedDialog}
                     onLogout={handleLogout}
                  />
                  <Sidebar
                     onDeleteAttachedTagFromDialog={handleDeleteAttachedTagFromDialog}
                     dialogsLoading={dialogsLoading}
                     searchValue={dialogsSearchValue}
                     isUnreadOnlyChecked={dialogsUnreadOnly}
                     handleSearchChange={handleSearchChange}
                     handleUnreadOnlyChange={handleUnreadOnlyChange}
                     handleDateStatusChange={handleDateStatusChange}
                     selectedDateStatus={dialogsDateStatus}
                     currentUser={currentUser}
                     handleSetSelectedDialog={handleSetSelectedDialog}
                     isManager={isManager}
                     allowedStatus={allowedStatus}
                     allowedTag={allowedTag}
                     allowedChannel={allowedChannel}
                     dialogs={dialogs}
                     setDialogs={handleSetDialogs}
                     userName={userName}
                     sidebarToggle={sidebarToggle}
                     setAddManagerPage={setAddManagerPage}
                     managerCardClick={managerCardClick}
                     setManagerCardClick={setManagerCardClick}
                     handleDialogsScroll={handleDialogsScroll}
                     newContactInitiationParams={newContactInitiationParams}
                     showAddContactModal={showAddContactModal}
                     setShowAddContactModal={setShowAddContactModal}
                     autoReadStatus={autoReadStatus}
                     setAutoReadStatus={setAutoReadStatus}
                     setSettingsToggle={setSettingsToggle}
                     channelList={channelList}
                     toggleSidebarView={toggleSidebarView}
                     setToggleSidebarView={setToggleSidebarView}
                     width={width}
                     selectedConversation={selectedDialogId}
                     selectedContact={selectedContact}
                     handleContactCardClick={handleContactCardClick}
                     managerSidebarLoader={managerSidebarLoader}
                     managers={managers}
                     managerList={managerList}
                     setManagerList={setManagerList}
                     followUpDateEnabled={followUpDateEnabled}
                     onManagersBuyClick={handleNavigationToPlanPage}
                  />
                  {selectedDialogId ||
                  sidebarToggle === 5 ||
                  sidebarToggle === 6 ||
                  addManagerPage ||
                  managerCardClick ||
                  settingsToggle !== 0 ||
                  selectedContactId !== null ? (
                     <Mainbar
                        currentContact={selectedContact}
                        setCurrentContact={handleSetSelectedContact}
                        dialog={selectedDialog}
                        handleSetSelectedDialog={handleSetSelectedDialog}
                        handleDialogTagSelect={handleDialogTagSelect}
                        handleDialogStatusSelect={handleDialogStatusSelect}
                        currentUser={currentUser}
                        allowedStatus={allowedStatus}
                        allowedTag={allowedTag}
                        allowedChannel={allowedChannel}
                        isManager={isManager}
                        templates={templates}
                        templateSubsections={templateSubsections}
                        handleCreateTemplate={handleCreateTemplate}
                        handleUpdateTemplate={handleUpdateTemplate}
                        handleDeleteTemplate={handleDeleteTemplate}
                        handleCreateTemplateSubsection={handleCreateTemplateSubsection}
                        handleUpdateTemplateSubsection={handleUpdateTemplateSubsection}
                        handleDeleteTemplateSubsection={handleDeleteTemplateSubsection}
                        handleEditMessageClick={handleEditMessageClick}
                        selectedMessageToEdit={selectedMessageToEdit}
                        handleEditMessageCancel={handleEditMessageCancel}
                        handleEditMessage={handleEditMessage}
                        tag={tag}
                        setTag={setTag}
                        addTag={addTag}
                        deleteTag={deleteTag}
                        editTag={editTag}
                        status={dialogSteps}
                        addStatus={addStatus}
                        deleteStatus={deleteStatus}
                        editStatus={editStatus}
                        isMainbarTablet={false}
                        selectedConversation={selectedDialogId}
                        setDialogs={handleSetDialogs}
                        dialogs={dialogs}
                        messages={messages}
                        setMessages={handleSetMessages}
                        nextMessagesCursor={messagesCursor}
                        setNextMessagesCursor={handleSetMessagesCursor}
                        messagesLoader={messagesLoader}
                        setMessagesLoader={setMessagesLoader}
                        channelStatus={channelStatus}
                        setChannelStatus={setChannelStatus}
                        sidebarToggle={sidebarToggle}
                        managerCardClick={managerCardClick}
                        addManagerPage={addManagerPage}
                        autoReadStatus={autoReadStatus}
                        settingsToggle={settingsToggle}
                        setSettingsToggle={setSettingsToggle}
                        setAutoReadStatus={setAutoReadStatus}
                        channelList={channelList}
                        userId={userId}
                        setInstagramConvoFilter={setInstagramConvoFilter}
                        toggleSidebarView={toggleSidebarView}
                        messageText={messageText}
                        onMessageTextChange={handleMessageTextChange}
                        selectTemplate={selectTemplate}
                        setSelectTemplate={setSelectTemplate}
                        setSidebarToggle={setSidebarToggle}
                        setUnreadMessagesIds={setUnreadMessagesIds}
                        handleMessageStatusClick={handleMessageStatusClick}
                        handleEditManagerFormSubmit={handleEditManagerFormSubmit}
                        handleToggleManagerActivation={handleToggleManagerActivation}
                        handleDeleteManager={handleDeleteManager}
                        handleReplyMessageClick={handleReplyMessageClick}
                        selectedRepliedMessage={selectedRepliedMessage}
                        setSelectedRepliedMessage={handleReplyMessage}
                        getNextCursorDialogList={handleMessagesScroll}
                        handleUpdateManagerIsAllowedToEditDialog={handleUpdateManagerIsAllowedToEditDialog}
                        handleUpdateDialogName={handleUpdateDialogName}
                        handleUpdateIsContactInfoHidden={handleUpdateIsContactInfoHidden}
                        handleUpdateIsContactListUnloadingAllowed={handleUpdateIsContactListUnloadingAllowed}
                        handleDialogsScroll={handleDialogsScroll}
                        handleForwardMessages={handleForwardMessages}
                        handleDeleteSelectedMessages={handleDeleteSelectedMessages}
                        handleDialogContactDateSelect={handleDialogContactDateSelect}
                        handleDialogContactDateDelete={handleDialogContactDateDelete}
                        onDeleteTag={handleDeleteAttachedTagFromDialog}
                        onNavigateToPlanPage={handleNavigationToPlanPage}
                     />
                  ) : (
                     <div className="messenger-welcome">
                        <div className="messenger-welcome-wrapper">
                           <p className="messenger-welcome-text">
                              Здравствуйте, {userName}!
                           </p>
                           <p className="messenger-welcome-instructrions-text">
                              Выберите диалог, чтобы начать общение.
                           </p>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         )
      } else {
         return (
            <div className="messenger">
               {showSystemNotification && (
                  <Notificator
                     showActionBtn={managers.length > 0}
                     onClose={handleCloseSystemNotification}
                     onClick={handleNotificationActionBtnClick}
                  />
               )}
               <Onboarding
                  isCompleted={isOnboardingCompleted}
                  onComplete={handleCompleteOnboarding}
                  setSidebarToggle={setSidebarToggle}
                  setToggleSidebarView={setToggleSidebarView}
                  handleMessageTextChange={handleMessageTextChange}
               />
               <div className="messenger-wrapper-tablet">
                  {selectedDialogId ||
                  addManagerPage === true ||
                  settingsToggle !== 0 ||
                  managerCardClick ||
                  selectedContactId !== null ||
                  sidebarToggle === 6 ? (
                     // selectedConversation === 0
                     <>
                        <Mainbar
                           currentContact={selectedContact}
                           setCurrentContact={handleSetSelectedContact}
                           dialog={selectedDialog}
                           handleSetSelectedDialog={handleSetSelectedDialog}
                           handleDialogTagSelect={handleDialogTagSelect}
                           handleDialogStatusSelect={handleDialogStatusSelect}
                           currentUser={currentUser}
                           allowedStatus={allowedStatus}
                           allowedTag={allowedTag}
                           allowedChannel={allowedChannel}
                           isManager={isManager}
                           templates={templates}
                           templateSubsections={templateSubsections}
                           handleCreateTemplate={handleCreateTemplate}
                           handleUpdateTemplate={handleUpdateTemplate}
                           handleDeleteTemplate={handleDeleteTemplate}
                           handleCreateTemplateSubsection={handleCreateTemplateSubsection}
                           handleUpdateTemplateSubsection={handleUpdateTemplateSubsection}
                           handleDeleteTemplateSubsection={handleDeleteTemplateSubsection}
                           handleEditMessageClick={handleEditMessageClick}
                           selectedMessageToEdit={selectedMessageToEdit}
                           handleEditMessageCancel={handleEditMessageCancel}
                           handleEditMessage={handleEditMessage}
                           tag={tag}
                           setTag={setTag}
                           addTag={addTag}
                           deleteTag={deleteTag}
                           editTag={editTag}
                           status={dialogSteps}
                           addStatus={addStatus}
                           deleteStatus={deleteStatus}
                           editStatus={editStatus}
                           isMainbarTablet={true}
                           selectedConversation={selectedDialogId}
                           setDialogs={handleSetDialogs}
                           dialogs={dialogs}
                           messages={messages}
                           setMessages={handleSetMessages}
                           nextMessagesCursor={messagesCursor}
                           setNextMessagesCursor={handleSetMessagesCursor}
                           messagesLoader={messagesLoader}
                           setMessagesLoader={setMessagesLoader}
                           channelStatus={channelStatus}
                           sidebarToggle={sidebarToggle}
                           managerCardClick={managerCardClick}
                           addManagerPage={addManagerPage}
                           autoReadStatus={autoReadStatus}
                           settingsToggle={settingsToggle}
                           setSettingsToggle={setSettingsToggle}
                           setAutoReadStatus={setAutoReadStatus}
                           channelList={channelList}
                           userId={userId}
                           setInstagramConvoFilter={setInstagramConvoFilter}
                           messageText={messageText}
                           onMessageTextChange={handleMessageTextChange}
                           selectTemplate={selectTemplate}
                           setSelectTemplate={setSelectTemplate}
                           setSidebarToggle={setSidebarToggle}
                           setUnreadMessagesIds={setUnreadMessagesIds}
                           handleMessageStatusClick={handleMessageStatusClick}
                           handleEditManagerFormSubmit={handleEditManagerFormSubmit}
                           handleToggleManagerActivation={handleToggleManagerActivation}
                           handleDeleteManager={handleDeleteManager}
                           handleReplyMessageClick={handleReplyMessageClick}
                           selectedRepliedMessage={selectedRepliedMessage}
                           setSelectedRepliedMessage={handleReplyMessage}
                           getNextCursorDialogList={handleMessagesScroll}
                           handleUpdateManagerIsAllowedToEditDialog={handleUpdateManagerIsAllowedToEditDialog}
                           handleUpdateDialogName={handleUpdateDialogName}
                           handleUpdateIsContactInfoHidden={handleUpdateIsContactInfoHidden}
                           handleUpdateIsContactListUnloadingAllowed={handleUpdateIsContactListUnloadingAllowed}
                           handleDialogsScroll={handleDialogsScroll}
                           handleForwardMessages={handleForwardMessages}
                           handleDeleteSelectedMessages={handleDeleteSelectedMessages}
                           handleDialogContactDateSelect={handleDialogContactDateSelect}
                           handleDialogContactDateDelete={handleDialogContactDateDelete}
                           onDeleteTag={handleDeleteAttachedTagFromDialog}
                           onNavigateToPlanPage={handleNavigationToPlanPage}
                        />
                        {/*<Navbar*/}
                        {/*   sidebarToggle={sidebarToggle}*/}
                        {/*   setSidebarToggle={setSidebarToggle}*/}
                        {/*   setIsUserAuthorized={setIsUserAuthorized}*/}
                        {/*   setAddManagerPage={setAddManagerPage}*/}
                        {/*   isManager={isManager}*/}
                        {/*/>*/}
                        {/*<Sidebar*/}
                        {/*   handleConvoClick={setSelectedConversation}*/}
                        {/*   dialogStatus={dialogStatus}*/}
                        {/*   userName={userName}*/}
                        {/*   sidebarToggle={sidebarToggle}*/}
                        {/*   setAddManagerPage={setAddManagerPage}*/}
                        {/*   setManagerCardClick={setManagerCardClick}*/}
                        {/*   handleScroll={handleDialogScroll}*/}
                        {/*   newContactInitiationParams={*/}
                        {/*      newContactInitiationParams*/}
                        {/*   }*/}
                        {/*   showAddContactModal={showAddContactModal}*/}
                        {/*   setShowAddContactModal={setShowAddContactModal}*/}
                        {/*   autoReadStatus={autoReadStatus}*/}
                        {/*   setAutoReadStatus={setAutoReadStatus}*/}
                        {/*   autoReadStatusRef={autoReadStatusRef}*/}
                        {/*   settingsToggle={settingsToggle}*/}
                        {/*   setSettingsToggle={setSettingsToggle}*/}
                        {/*   userNameRef={userNameRef}*/}
                        {/*   channelList={channelList}*/}
                        {/*   toggleSidebarView={toggleSidebarView}*/}
                        {/*   setToggleSidebarView={setToggleSidebarView}*/}
                        {/*   width={width}*/}
                        {/*/>*/}
                     </>
                  ) : !selectedDialogId &&
                     (
                        sidebarToggle !== 5 &&
                        sidebarToggle !== 6
                     ) ? (
                     //  selectedConversation ||
                     // addManagerPage === true ||
                     // settingsToggle !== 0 ||
                     // managerCardClick
                     <>
                        <Navbar
                           setToggleSidebarView={setToggleSidebarView}
                           sidebarToggle={sidebarToggle}
                           setSidebarToggle={setSidebarToggle}
                           setAddManagerPage={setAddManagerPage}
                           isManager={isManager}
                           disableExit={disableExit}
                           setSelectedConversation={handleSetSelectedDialog}
                           onLogout={handleLogout}
                        />
                        <Sidebar
                           onDeleteAttachedTagFromDialog={handleDeleteAttachedTagFromDialog}
                           dialogsLoading={dialogsLoading}
                           searchValue={dialogsSearchValue}
                           isUnreadOnlyChecked={dialogsUnreadOnly}
                           handleSearchChange={handleSearchChange}
                           handleUnreadOnlyChange={handleUnreadOnlyChange}
                           handleDateStatusChange={handleDateStatusChange}
                           selectedDateStatus={dialogsDateStatus}
                           handleSetSelectedDialog={handleSetSelectedDialog}
                           currentUser={currentUser}
                           selectedConversation={selectedDialogId}
                           selectedContact={selectedContact}
                           isManager={isManager}
                           allowedStatus={allowedStatus}
                           allowedTag={allowedTag}
                           allowedChannel={allowedChannel}
                           dialogs={dialogs}
                           setDialogs={handleSetDialogs}
                           userName={userName}
                           sidebarToggle={sidebarToggle}
                           setAddManagerPage={setAddManagerPage}
                           managerCardClick={managerCardClick}
                           setManagerCardClick={setManagerCardClick}
                           handleDialogsScroll={handleDialogsScroll}
                           newContactInitiationParams={
                              newContactInitiationParams
                           }
                           showAddContactModal={showAddContactModal}
                           setShowAddContactModal={setShowAddContactModal}
                           autoReadStatus={autoReadStatus}
                           setAutoReadStatus={setAutoReadStatus}
                           settingsToggle={settingsToggle}
                           setSettingsToggle={setSettingsToggle}
                           channelList={channelList}
                           toggleSidebarView={toggleSidebarView}
                           setToggleSidebarView={setToggleSidebarView}
                           width={width}
                           handleContactCardClick={handleContactCardClick}
                           managerSidebarLoader={managerSidebarLoader}
                           managers={managers}
                           managerList={managerList}
                           setManagerList={setManagerList}
                           followUpDateEnabled={followUpDateEnabled}
                           onManagersBuyClick={handleNavigationToPlanPage}
                        />
                     </>
                  ) : sidebarToggle === 5 ||
                      sidebarToggle === 6 ? (
                     <>
                        <Navbar
                           setToggleSidebarView={setToggleSidebarView}
                           sidebarToggle={sidebarToggle}
                           setSidebarToggle={setSidebarToggle}
                           setAddManagerPage={setAddManagerPage}
                           isManager={isManager}
                           disableExit={disableExit}
                           setSelectedConversation={handleSetSelectedDialog}
                           onLogout={handleLogout}
                        />
                        <Mainbar
                           currentContact={selectedContact}
                           setCurrentContact={handleSetSelectedContact}
                           dialog={selectedDialog}
                           handleSetSelectedDialog={handleSetSelectedDialog}
                           handleDialogTagSelect={handleDialogTagSelect}
                           handleDialogStatusSelect={handleDialogStatusSelect}
                           currentUser={currentUser}
                           allowedStatus={allowedStatus}
                           allowedTag={allowedTag}
                           allowedChannel={allowedChannel}
                           isManager={isManager}
                           templates={templates}
                           templateSubsections={templateSubsections}
                           handleCreateTemplate={handleCreateTemplate}
                           handleUpdateTemplate={handleUpdateTemplate}
                           handleDeleteTemplate={handleDeleteTemplate}
                           handleCreateTemplateSubsection={handleCreateTemplateSubsection}
                           handleUpdateTemplateSubsection={handleUpdateTemplateSubsection}
                           handleDeleteTemplateSubsection={handleDeleteTemplateSubsection}
                           handleEditMessageClick={handleEditMessageClick}
                           selectedMessageToEdit={selectedMessageToEdit}
                           handleEditMessageCancel={handleEditMessageCancel}
                           handleEditMessage={handleEditMessage}
                           tag={tag}
                           setTag={setTag}
                           addTag={addTag}
                           deleteTag={deleteTag}
                           editTag={editTag}
                           status={dialogSteps}
                           addStatus={addStatus}
                           deleteStatus={deleteStatus}
                           editStatus={editStatus}
                           isMainbarTablet={true}
                           selectedConversation={selectedDialogId}
                           setDialogs={handleSetDialogs}
                           dialogs={dialogs}
                           messages={messages}
                           setMessages={handleSetMessages}
                           nextMessagesCursor={messagesCursor}
                           setNextMessagesCursor={handleSetMessagesCursor}
                           messagesLoader={messagesLoader}
                           setMessagesLoader={setMessagesLoader}
                           channelStatus={channelStatus}
                           sidebarToggle={sidebarToggle}
                           managerCardClick={managerCardClick}
                           addManagerPage={addManagerPage}
                           autoReadStatus={autoReadStatus}
                           settingsToggle={settingsToggle}
                           setSettingsToggle={setSettingsToggle}
                           setAutoReadStatus={setAutoReadStatus}
                           channelList={channelList}
                           userId={userId}
                           setInstagramConvoFilter={setInstagramConvoFilter}
                           messageText={messageText}
                           onMessageTextChange={handleMessageTextChange}
                           selectTemplate={selectTemplate}
                           setSelectTemplate={setSelectTemplate}
                           setSidebarToggle={setSidebarToggle}
                           setUnreadMessagesIds={setUnreadMessagesIds}
                           handleMessageStatusClick={handleMessageStatusClick}
                           handleEditManagerFormSubmit={handleEditManagerFormSubmit}
                           handleToggleManagerActivation={handleToggleManagerActivation}
                           handleDeleteManager={handleDeleteManager}
                           handleReplyMessageClick={handleReplyMessageClick}
                           selectedRepliedMessage={selectedRepliedMessage}
                           setSelectedRepliedMessage={handleReplyMessage}
                           getNextCursorDialogList={handleMessagesScroll}
                           handleUpdateManagerIsAllowedToEditDialog={handleUpdateManagerIsAllowedToEditDialog}
                           handleUpdateDialogName={handleUpdateDialogName}
                           handleUpdateIsContactInfoHidden={handleUpdateIsContactInfoHidden}
                           handleUpdateIsContactListUnloadingAllowed={handleUpdateIsContactListUnloadingAllowed}
                           handleDialogsScroll={handleDialogsScroll}
                           handleForwardMessages={handleForwardMessages}
                           handleDeleteSelectedMessages={handleDeleteSelectedMessages}
                           handleDialogContactDateSelect={handleDialogContactDateSelect}
                           handleDialogContactDateDelete={handleDialogContactDateDelete}
                           onDeleteTag={handleDeleteAttachedTagFromDialog}
                           onNavigateToPlanPage={handleNavigationToPlanPage}
                        />
                     </>
                     ) : (
                     <div
                        className="messenger-welcome"
                        style={{ width: '100%' }}
                     >
                        <div className="messenger-welcome-wrapper">
                           <p className="messenger-welcome-text">
                              Здравствуйте, {userName}!
                           </p>
                           <p className="messenger-welcome-instructrions-text">
                              Выберите диалог, чтобы начать общение.
                           </p>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         )
      }
   }
}

export default Messenger
