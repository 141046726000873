import React from 'react'

import './WarningMessage.css'
import { useState } from 'react'

const WarningMessage = ({ title = '', children }) => {
   const [isVisible, setIsVisible] = useState(true)

   const handleClick = () => setIsVisible(false)

   if (!isVisible) return null

   return (
      <div className='warning-message'>
         <img 
            className='warning-message__icon' 
            src="/assets/notifications/info-icon.svg" 
            alt="Info" 
         />
         <div className='warning-message__body'>
            {title && <h4>{title}</h4>}
            {children && <p>{children}</p>}
         </div>
         <button 
            className='warning-message__close-btn'
            onClick={handleClick}   
         >
            <img 
               src="/assets/notifications/close-icon.svg" 
               alt="Close" 
               draggable={false} 
            />
         </button>
      </div>
   )
}

export default WarningMessage