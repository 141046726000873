import { createSlice } from "@reduxjs/toolkit"
import { getIsPlanActive, periodVariants } from "../../../pages/PlanPage/utils"
import { plansMap } from "../../../utils/consts"

const initialState = {
   planIsLoading: true,
   plan: null,
   availablePlanListLoading: true,
   availablePlanList: [],
   availablePlanComponents: [],
   cart: {
      totalPrice: 0,
      selectedPlan: {},
      selectedPeriod: periodVariants[0],
      selectedComponents: [],
      shouldPay: false,
      isCalculating: false,
      calculationError: false
   },
   fetchingDataError: null,
}

const planSlice = createSlice({
   name: 'plan',
   initialState,
   reducers: {
      resetCartState: (state) => {
         const plan = state.plan
         const isPlanActive = getIsPlanActive(plan?.end_at)

         state.cart = {
            totalPrice: 0,
            selectedPlan: plan.plan || {},
            selectedPeriod: periodVariants[plan.plan?.type !== plansMap.FREE && isPlanActive ? 0 : 1],
            selectedComponents: plan.items?.map(item => ({
               ...item.plan_item,
               quantity: item.quantity
            })) || [],
            shouldPay: false,
            isCalculating: false,
            calculationError: false
         }
      },
      fetchPlanStarted: (state) => {
         state.planIsLoading = true
      },
      fetchPlanSuccess: (state, action) => {
         const plan = action.payload         
         const isPlanActive = getIsPlanActive(plan?.end_at)

         state.plan = plan
         state.cart = {
            ...state.cart,
            selectedPlan: plan.plan || {},
            selectedPeriod: periodVariants[plan.plan?.type !== plansMap.FREE && isPlanActive ? 0 : 1],
            selectedComponents: plan.items?.map(item => ({
               ...item.plan_item,
               quantity: item.quantity
            })) || []
         }
         state.fetchingDataError = null
      },
      fetchPlanFailed: (state, action) => {
         state.fetchingDataError = action.payload
      },
      fetchPlanFinished: (state) => {
         state.planIsLoading = false
      },
      fetchAvailablePlanListStarted: (state) => {
         state.availablePlanListLoading = true
      },
      fetchAvailablePlanListSuccess: (state, action) => {
         state.availablePlanList = action.payload

         const componentsMap = action.payload.reduce((map, plan) => {
            plan.items.forEach(component => map[component.code] = component)
            return map
         }, {})
         state.availablePlanComponents = Object.values(componentsMap)
         state.fetchingDataError = null
      },
      fetchAvailablePlanListFailed: (state, action) => {
         state.fetchingDataError = action.payload
      },
      fetchAvailablePlanListFinished: (state) => {
         state.availablePlanListLoading = false
      },
      calculatingPlanStarted: (state) => {
         state.cart = {
            ...state.cart,
            totalPrice: 0,
            shouldPay: false,
            isCalculating: true,
            calculationError: false
         }
      },
      calculatingPlanSuccess: (state, action) => {
         state.cart = {
            ...state.cart,
            totalPrice: action.payload.total,
            shouldPay: action.payload.should_pay,
         }
      },
      calculatingPlanFailed: (state) => {         
         state.cart.calculationError = true
      },
      calculatingPlanFinished: (state) => {
         state.isCalculating = false

         state.cart = {
            ...state.cart,
            isCalculating: false
         }
      },
      selectedPlanChanged: (state, action) => {
         const currentPlan = state.plan
         const selectedPlan = action.payload
         const isCurrentPlanActive = getIsPlanActive(currentPlan?.end_at)
         
         state.cart = {
            ...state.cart,
            totalPrice: 0,
            selectedPlan: selectedPlan,
            selectedPeriod: selectedPlan.type === plansMap.FREE
               ?  periodVariants[1]
               : selectedPlan.type === currentPlan?.plan?.type && isCurrentPlanActive
                  ? periodVariants[0]
                  : periodVariants[1],
            selectedComponents: selectedPlan.type === currentPlan.plan?.type
               ? state.plan.items.map(item => ({
                  ...item.plan_item,
                  quantity: item.quantity
               }))
               : selectedPlan.items.map(item => ({
                  ...item,
                  quantity: 1
               })),
            shouldPay: false,
            isCalculating: false,
            calculationError: false
         }
      },
      selectedPeriodChanged: (state, action) => {
         state.cart = {
            ...state.cart,
            selectedPeriod: action.payload
         }
      },
      selectedComponentsChanged: (state, action) => {
         const changedComponent = action.payload
         
         state.cart = {
            ...state.cart,
            selectedComponents: state.cart.selectedComponents.map(component => (
               component.code === changedComponent.code
                  ? changedComponent
                  : component
            ))
         }
      },
      changePlanSuccess: (state, action) => {
         state.plan = action.payload
      },
      changePlanFailed: (state) => {
         const plan = state.plan
         state.cart = {
            ...state.cart,
            selectedComponents: plan.items?.map(item => ({
               ...item.plan_item,
               quantity: item.quantity
            })) || []
         }
      }
   }
})

export const {
   resetCartState,
   fetchPlanStarted, 
   fetchPlanSuccess,
   fetchPlanFailed, 
   fetchPlanFinished,
   fetchAvailablePlanListStarted,
   fetchAvailablePlanListSuccess,
   fetchAvailablePlanListFailed,
   fetchAvailablePlanListFinished,
   calculatingPlanStarted,
   calculatingPlanSuccess,
   calculatingPlanFailed,
   calculatingPlanFinished,
   selectedPlanChanged,
   selectedPeriodChanged,
   selectedComponentsChanged,
   changePlanSuccess,
   changePlanFailed
} = planSlice.actions
export default planSlice.reducer

