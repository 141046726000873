import React, { useState } from 'react'

import './AddContact.css'

import { useClickOutside } from '../../../hooks'

const ChannelSelect = ({ selectedValue, values, error = '', onTouch, onSelect }) => {
   const [active, setActive] = useState(false)

   const selectedValueText = selectedValue 
      ? `${selectedValue.type} - ${selectedValue.username}`
      : 'Выберите канал'

   const handleToggle = () => {
      setActive(active => !active)
      if (active) onTouch()
   }
   
   const handleClose = () => {
      setActive(active => {
         if (active) onTouch()
         return false
      })
   }

   const handleSelect = (value) => {
      onSelect(value)
      setActive(false)
   }

   const ref = useClickOutside(handleClose)
   
   return (
      <div
         ref={ref}
         className={`add-contact-form-select ${active ? 'active' : ''} ${error ? 'error' : ''}`}
      >
         {error && <p className='add-contact-form-select__error'>{error}</p>}
         <button
            type='button'
            className='add-contact-form-select__toggle'
            onClick={handleToggle}
         >
            <span className='add-contact-form-select__selected-value'>
               {selectedValueText}
            </span>
            <svg className='add-contact-form-select__icon' width="13" height="7" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd" d="M4.47994 0.478478C4.67521 0.283216 4.99179 0.283216 5.18705 0.478478L9.18705 4.47848C9.38231 4.67374 9.38231 4.99032 9.18705 5.18558C8.99179 5.38085 8.67521 5.38085 8.47994 5.18558L4.8335 1.53914L1.18705 5.18558C0.991787 5.38085 0.675205 5.38085 0.479943 5.18558C0.284681 4.99032 0.284681 4.67374 0.479943 4.47848L4.47994 0.478478Z" fill="#252525"/>
            </svg>
         </button>
         <div className='add-contact-form-select__popup'>
            <p className='add-contact-form-select__list-title'>Активные каналы</p>
            <ul className='add-contact-form-select__list'>
               {values.length > 0
                  ? values.map(value => (
                     <li
                        key={value.id}
                        className='add-contact-form-select__list-item'
                        onClick={() => handleSelect(value)}
                     >
                        {`${value.type} - ${value.username}`}
                     </li>
                  ))
                  : <p className='add-contact-form-select__fallback-message'>Список каналов пуст</p>
               }
            </ul>
         </div>
      </div>
   )
}

export default ChannelSelect