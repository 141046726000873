import React from 'react'

import './Radio.css'

const Radio = ({ checked }) => {
   return (
      <div className={`radio ${checked ? 'radio_active' : ''}`}></div>
   )
}

export default Radio