import React from 'react'

import './RangeInput.css'

const RangeInput = ({ 
   value,
   min = 0,
   max = 10,
   onChange,
   disabled
}) => {
   const progressWidth = value * 100 / max
   
   const handleChange = (e) => onChange(e.target.value)

   return (
      <div className={`range-input ${disabled ? 'disabled' : ''}`}>
         <div className='range-input__wrapper'>
            <input
               className='range-input__input'
               type='range'
               min={min}
               max={max}
               value={value}
               onChange={handleChange}
               disabled={disabled}
            />
            <div 
               className='range-input__progress'
               style={{ width: `${progressWidth}%` }}  
            >
            </div>
         </div>
         <div className='range-input__footer'>
            <span>{min}</span>
            <span>{max}</span>
         </div>
      </div>
   )
}

export default RangeInput