import React, { useState } from 'react'

import './TagSelect.css'

import { useClickOutside } from '../../../hooks'

import Search from '../../Search'
import SelectedValueItem from './SelectedValueItem'
import TagSelectOption from './TagSelectOption'

const TagSelect = ({ selectedValues, values, disabled = false, onChange, onDeleteTag }) => {   
   const [show, setShow] = useState(false)
   const [search, setSearch] = useState('')

   const filteredValues = values.filter(val => val.label.includes(search))
   
   const handleToggle = () => setShow(show => !show)

   const handleClose = () => setShow(false)

   const handleSearchChange = (value) => setSearch(value)

   const ref = useClickOutside(handleClose)

   return (
      <div className={`tag-select ${show ? 'active' : ''} ${disabled ? 'tag-select_disabled' : ''}`} ref={ref}>
         <button 
            className='tag-select__selected-value'
            onClick={handleToggle}
            disabled={disabled}
         >
            {selectedValues.length
               ? selectedValues.map(value => {                  
                  return (
                     <SelectedValueItem
                        key={value.value}
                        value={value} 
                        onDelete={onDeleteTag}
                     />
                  )
               })
               : 'Не задано'
            }
            <span className='tag-select__icon'>
               <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.47994 0.478478C4.67521 0.283216 4.99179 0.283216 5.18705 0.478478L9.18705 4.47848C9.38231 4.67374 9.38231 4.99032 9.18705 5.18558C8.99179 5.38085 8.67521 5.38085 8.47994 5.18558L4.8335 1.53914L1.18705 5.18558C0.991787 5.38085 0.675205 5.38085 0.479943 5.18558C0.284681 4.99032 0.284681 4.67374 0.479943 4.47848L4.47994 0.478478Z" fill="#252525"/>
               </svg>
            </span>
         </button>
         <div className='tag-select__popup'>
            <Search
               customStyle='tag-select__search'
               searchValue={search}
               onChange={handleSearchChange}
            />
            {filteredValues.length
               ? (
                  <>
                     <div className='tag-select__list-wrapper'>
                        <span className='tag-select__list-title'>Выбранные теги</span>
                        <ul className='tag-select__list tag-select__list_selected'>
                           {selectedValues.map(value => {
                              return (
                                 <li
                                    key={value.value}
                                    className='tag-select__list-item'                        
                                 >
                                    <TagSelectOption
                                       isSelected={true}
                                       value={value}
                                       onSelect={onChange}
                                       disabled={true}
                                    />
                                 </li>
                              )
                           })}
                        </ul>
                     </div>
                     <div className='tag-select__list-wrapper'>
                        <span className='tag-select__list-title'>Все теги</span>
                        <ul className='tag-select__list'>
                           {filteredValues.map(value => {
                              const isSelected = selectedValues.some(val => val.value === value.value)
                              return (
                                 <li
                                    key={value.value}
                                    className='tag-select__list-item'                        
                                 >
                                    <TagSelectOption
                                       isSelected={isSelected}
                                       value={value}
                                       onSelect={onChange}   
                                    />
                                 </li>
                              )
                           })}
                        </ul>
                     </div>
                  </>
               ) : (
                  <p className='tag-select__empty-list-message'>
                     Список тегов пуст
                  </p>
               )}
         </div>
      </div>
   )
}

export default TagSelect