import React, { useState } from 'react'

import './Input.css'

const PasswordInput = ({
   id = '', 
   name = '',
   type = 'password',
   value = '',
   label = '',
   error,
   placeholder = '',
   onChange,
   onBlur,
}) => {
   const [showPassword, setShowPassword] = useState(false)

   const handleToggleShowPassword = () => setShowPassword(show => !show)

   return (
      <div className={`input input_password ${error ? 'input_error' : ''}`}>
         <div className='input__container'>
            <label 
               className='input__label'
               htmlFor={id}
            >
               {error || label}
            </label>
            <div className='input__body'>
               <input 
                  className='input__input'
                  id={id}
                  name={name}
                  type={showPassword ? 'text' : type}
                  value={value}
                  placeholder={placeholder}
                  onChange={onChange}
                  onBlur={onBlur}
               />
            </div>
         </div>
         <button
            type='button'
            className='input__toggle-password-btn'
            onClick={handleToggleShowPassword}
         >
            <img
               className='input__toggle-password-icon'
               src={`/assets/controls/password-${showPassword ? 'visible' : 'hidden'}-icon.svg`} 
               alt='Toggle password' 
            />
         </button>
      </div>
   )
}

export default PasswordInput