import React, { useState } from 'react'

import './CartSupportBlock.css'

import { i2crmLinks } from '../../../utils/consts'

import CartBlock from '../CartBlock'

const CartSupportBlock = () => {
   const [showSupport, setShowSupport] = useState(false)

   const handleToggleSupport = () => setShowSupport(show => !show)

   return (
      <CartBlock customStyle='cart-support-block'>
         <div className='cart-support-block-header'>
            <span className='cart-support-block-header__title'>
               Вы можете обратиться в службу поддержки и мы ответим на все вопросы
            </span>
         </div>
         <div className={`cart-support-block-body ${showSupport ? 'active' : ''}`}>
            {showSupport && (
               <div className='cart-support-block-body__list'>
                  {i2crmLinks.map(link => (
                     <div key={link.link} className='cart-support-block-body__item'>
                        <span className='cart-support-block-body__item-name'>
                           {link.name}
                        </span>
                        <a 
                           className='cart-support-block-body__item-link'
                           href={link.link}
                           target='_blank'
                           rel='noreferrer'
                        >
                           {link.label}
                        </a>
                     </div>
                  ))}
               </div>
            )}
            <button 
               className='cart-support-block-body__toggle'
               onClick={handleToggleSupport}
            >
               Служба поддержки
               <svg viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.47994 0.478478C4.67521 0.283216 4.99179 0.283216 5.18705 0.478478L9.18705 4.47848C9.38231 4.67374 9.38231 4.99032 9.18705 5.18558C8.99179 5.38085 8.67521 5.38085 8.47994 5.18558L4.8335 1.53914L1.18705 5.18558C0.991787 5.38085 0.675205 5.38085 0.479943 5.18558C0.284681 4.99032 0.284681 4.67374 0.479943 4.47848L4.47994 0.478478Z" fill="#252525"/>
               </svg>
            </button>
         </div>
      </CartBlock>
   )
}

export default CartSupportBlock