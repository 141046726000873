import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import './PlanPage.css'

import { useFetchAvailablePlanList, useFetchManagers } from '../../hooks'
import { 
   plan_selectAvailablePlanComponents, 
   plan_selectFetchingDataError, 
   plan_selectPlan 
} from '../../store/slices/plan/selectors'
import { getFormattedPlanEndDate } from './utils'
import { fetchAvailablePlanList, fetchPlan } from '../../store/slices/plan/thunk-creators'
import { resetCartState } from '../../store/slices/plan'

import Plan from './Plan'
import Loader from '../../components/Loader'
import PlanError from './PlanError'
import ReturnBtn from '../../components/mainbar/ReturnBtn'

const PlanPage = ({ isMainbarTablet, setSidebarToggle }) => {
   const plan = useSelector(plan_selectPlan)
   const [availablePlanListLoading, availablePlanList] = useFetchAvailablePlanList()
   const availablePlanComponents = useSelector(plan_selectAvailablePlanComponents)
   const [managersLoading, managers] = useFetchManagers()
   const error = useSelector(plan_selectFetchingDataError)

   const formattedDate = getFormattedPlanEndDate(plan?.end_at)
   const planName = plan?.plan?.name
   
   
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const handleReloadPage = async () => {
      if (!plan) await dispatch(fetchPlan())
      if (!availablePlanList.length) await dispatch(fetchAvailablePlanList())
   }

   const handleReturn = () => {
      setSidebarToggle(0)
      navigate('/dialogs')
   }
   
   useEffect(() => {
      return () => dispatch(resetCartState())
   }, [])
   
   return (
      <div className='plan-page'>
         <div className='plan-page__container'>
            <ReturnBtn
               customStyle='plan-page__return-btn'
               isMainbarTablet={isMainbarTablet} 
               onClick={handleReturn}
            />
            {availablePlanListLoading || managersLoading
               ? <Loader />
               : error 
                  ? (
                     <PlanError
                        error={error}
                        onReload={handleReloadPage}
                     />
                  ) : (
                     <>
                        <div className='plan-page__header'>
                           <h1 className='plan-page__title'>
                              Тариф 
                           </h1>
                           {formattedDate && (
                              <span className='plan-page__end-date'>
                                 {` Активен до: ${formattedDate} ${planName ? `(${planName})` : ''}`}
                              </span>
                           )}
                        </div>
                        <Plan 
                           currentPlan={plan}
                           availablePlanList={availablePlanList}
                           availablePlanComponents={availablePlanComponents}
                           managers={managers}   
                        />
                     </>
                  )
            }
         </div>
      </div>
   )
}

export default PlanPage