import uniqid from 'uniqid'
import { planComponentsCodes, plansMap } from "../../utils/consts"

export const planStatusesMap = {
   ACTIVE: 1,
   INACTIVE: 0
}

export const periodVariants = [
   {
      id: 0,
      name: 'Текущий период',
      value: 0
   },
   {
      id: 1,
      name: '1 период (30 дней)',
      value: 30
   },
   {
      id: 2,
      name: '2 периода (60 дней)',
      value: 60
   },
   {
      id: 3,
      name: '3 периода (90 дней)',
      value: 90
   },
   {
      id: 4,
      name: '4 периода (120 дней)',
      value: 120
   },
   {
      id: 5,
      name: '5 периодов (150 дней)',
      value: 150
   },
   {
      id: 6,
      name: '6 периодов (180 дней)',
      value: 180
   },
   {
      id: 7,
      name: '7 периодов (210 дней)',
      value: 210
   },
   {
      id: 8,
      name: '8 периодов (240 дней)',
      value: 240
   },
   {
      id: 9,
      name: '9 периодов (270 дней)',
      value: 270
   },
   {
      id: 10,
      name: '10 периодов (300 дней)',
      value: 300
   },
   {
      id: 11,
      name: '11 периодов (330 дней)',
      value: 330
   },
   {
      id: 12,
      name: '12 периодов (360 дней)',
      value: 360
   },
   {
      id: 13,
      name: '13 периодов (390 дней)',
      value: 390
   },
   {
      id: 14,
      name: '14 периодов (420 дней)',
      value: 420
   },
   {
      id: 15,
      name: '15 периодов (450 дней)',
      value: 450
   },
   {
      id: 16,
      name: '16 периодов (480 дней)',
      value: 480
   },   
   {
      id: 17,
      name: '17 периодов (510 дней)',
      value: 510
   },
   {
      id: 18,
      name: '18 периодов (540 дней)',
      value: 540
   },
   {
      id: 19,
      name: '19 периодов (570 дней)',
      value: 570
   },
   {
      id: 20,
      name: '20 периодов (600 дней)',
      value: 600
   },
   {
      id: 21,
      name: '21 период (630 дней)',
      value: 630
   },
   {
      id: 22,
      name: '22 периода (660 дней)',
      value: 660
   },
   {
      id: 23,
      name: '23 периода (690 дней)',
      value: 690
   },
   {
      id: 24,
      name: '24 периода (720 дней)',
      value: 720
   }
]

export const planItemFeaturesMap = {
   [plansMap.BASIC]: [
      'До 100 менеджеров',
      'Возможность добавить контакт и первым написать сообщение в чате с клиентом',
      'Список диалогов с клиентами',
      'Настройка и использование шаблонов для быстрых ответов',
      'Присвоение тегов и статусов диалогам',
      'Диалоговое окно для ведения коммуникации с клиентами из подключенных мессенджеров',
      'Список контактов',
      'Возможность создания 4 типов рассылок',
      'Список менеджеров с возможностью настройки доступов'
   ],
   [plansMap.FREE]: [
      'Без менеджеров',
      'Возможность добавить контакт и первым написать сообщение в чате с клиентом',
      'Список диалогов с клиентами',
      'Настройка и использование шаблонов для быстрых ответов',
      'Присвоение тегов и статусов диалогам',
      'Диалоговое окно для ведения коммуникации с клиентами из подключенных мессенджеров',
      'Список контактов',
      'Возможность создания 4 типов рассылок',
      'Список менеджеров с возможностью настройки доступов'
   ]
}

export const getFormattedPlanEndDate = (timestamp) => {
   if (!timestamp) return ''

   const date = new Date(timestamp * 1000)
   const day = String(date.getDate()).padStart(2, '0')
   const month = String(date.getMonth() + 1).padStart(2, '0')
   const year = date.getFullYear()

   return `${day}.${month}.${year}`
}

export const getIsPlanActive = (timestamp) => {
   if (!timestamp) return false

   const currentTime = Date.now()   
   return currentTime < timestamp * 1000
}

export const getNormalizedConstructorComponents = (components) => {
   return components.map(component => (
      component.code === planComponentsCodes.MANAGER
         ? {
            id: uniqid(),
            plan_item: {
               ...component
            },
            quantity: 1
         }
         : component
   ))
}

export const getNormalizedPlanItems = (components) => (
   components.reduce((planItems, item) => {      
      planItems[item.id] = item.quantity
      return planItems
   }, {})
)

export const getTotalPlanPrice = (plan) => {
   const planPrice = plan.plan.price
   const componentsPrice = plan.items.reduce((totalPrice, item) => {      
      return totalPrice += item.plan_item.price_per_30_days * item.quantity
   }, 0)
   
   return planPrice + componentsPrice
}

export const getPeriodValues = (selectedPlan, currentPlan) => {
   const isCurrentPlanActive = getIsPlanActive(currentPlan.end_at)

   switch (selectedPlan.type) {
      case plansMap.FREE:
         return [periodVariants[1]]
      case plansMap.BASIC:         
         return currentPlan.plan?.type === plansMap.BASIC && isCurrentPlanActive
            ? periodVariants
            : periodVariants.slice(1)
      default:
         return periodVariants
   }
}