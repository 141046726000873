import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import './DialogCard.css'

import { convertDate } from '../../utils/helper'
import { auth_selectUser } from '../../../store/slices/auth/selectors'

import CardAvatar from '../../CardAvatar'
import LastMessage from './LastMessage'
import DialogAnswerTimer from '../../DialogAnswerTimer'
import DialogCardTag from './DialogCardTag'

const DialogCard = ({
   isSelected = false,
   dialog,
   onClick,
   onDeleteTag
}) => {
   const {
      client, 
      dialog_channel, 
      timestamp, 
      new_message, 
      dialog_status,
      dialog_tag,
      date_first_incoming_in_series 
   } = dialog
   const maxTimeWithoutAnswer = useSelector(auth_selectUser).time_without_answer
   
   const lastMessageDate = convertDate(timestamp)
   const showWithoutAnswerTimer = date_first_incoming_in_series && maxTimeWithoutAnswer
   
   const [showAllTags, setShowAllTags] = useState(false)

   const tags = showAllTags ? dialog_tag : dialog_tag.slice(0, 2)

   const handleClick = () => onClick(dialog)

   const handleToggleTags = (e) => {
      e.stopPropagation()
      setShowAllTags(show => !show)
   }

   const handleDeleteTag = async (id) => await onDeleteTag(id, dialog)
   
   return (
      <div 
         className={`dialog-card ${isSelected ? 'dialog-card_selected' : ''}`}
         onClick={handleClick}
      >
         <div className='dialog-card__header'>
            <CardAvatar photo={client.photo} channelType={dialog_channel} />
            <LastMessage dialog={dialog} />
            <div className='dialog-card__time-block'>
               <div className='dialog-card__last-message-date'>
                  {lastMessageDate}
               </div>
               {new_message > 0 && (
                  <div className='dialog-card__new-messages-counter'>
                     {new_message}
                  </div>
               )}
            </div>
         </div>
         <div className='dialog-card__footer'>
            <div className='dialog-card__footer-top'>
               {dialog_status &&  (
                  <div 
                     className='dialog-card__status' 
                     style={{ backgroundColor: dialog_status.color }}
                  >
                     {dialog_status.name}
                  </div>
               )}
               {showWithoutAnswerTimer && (
                  <DialogAnswerTimer
                     unansweredMessageTimestamp={date_first_incoming_in_series}
                     maxTimeWithoutAnswer={maxTimeWithoutAnswer}
                  />
               )}
            </div>
            {dialog_tag.length > 0 && (
               <div className='dialog-card__footer-bottom'>
                  <div className={`dialog-card__tags ${showAllTags ? 'active' : ''}`}>
                     <ul className='dialog-card__tags-list'>
                        {tags.map(tag => (
                           <DialogCardTag
                              key={tag.id}
                              tag={tag}
                              onDelete={handleDeleteTag}
                           />
                        ))}
                     </ul>
                     {dialog_tag.length > 2 && (
                        <button 
                           className='dialog-card__tags-more-btn'
                           onClick={handleToggleTags}   
                        >
                           <span className='dialog-card__tags-more-btn-text'>Еще</span>
                           <svg className='dialog-card__tags-more-btn-icon' width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.54464 0.294156C4.7155 0.123302 4.99251 0.123302 5.16336 0.294156L8.66336 3.79416C8.83422 3.96501 8.83422 4.24202 8.66336 4.41288C8.49251 4.58373 8.2155 4.58373 8.04464 4.41288L4.854 1.22223L1.66336 4.41288C1.49251 4.58373 1.2155 4.58373 1.04464 4.41288C0.87379 4.24202 0.87379 3.96501 1.04464 3.79416L4.54464 0.294156Z" fill="#40B7D9"/>
                           </svg>
                        </button>
                     )}
                  </div>
               </div>
            )}
         </div>
      </div>
   )
}

export default DialogCard