import React from 'react'

import './PlanTooltip.css'

const PlanTooltip = ({
   iconSrc = '/assets/notifications/info-icon.svg', 
   text, 
   customStyle = ''
}) => {
   return (
      <div className={`plan-tooptip ${customStyle}`}>
         <img className='plan-tooptip__icon' src={iconSrc} alt='Info' />
         {text && (
            <div className='plan-tooptip__body'>
               {text}
            </div>
         )}
      </div>
   )
}

export default PlanTooltip