import { createSlice } from "@reduxjs/toolkit"

export const setDialogStatusSuccessCases = {
   statusNotAllowed: 'managerWithoutAllowedStatus',
   default: 'default'
}

const initialState = {
   dialogs: [],
   selectedDialog: null,
}

const dialogsSlice = createSlice({
   name: 'dialogs',
   initialState,
   reducers: {
      setDialogs: (state, action) => {
         state.dialogs = action.payload
      },
      fetchDialogsSuccess: (state, action) => {
         state.dialogs = [...state.dialogs, ...action.payload]
      },
      setSelectedDialog: (state, action) => {
         state.selectedDialog = action.payload
      },
      setDialogStatusSuccess: (state, action) => {
         switch (action.payload.case) {
            case setDialogStatusSuccessCases.statusNotAllowed:
               state.dialogs = state.dialogs.filter(dialog => dialog.dialog_id !== state.selectedDialog.dialog_id)
               state.selectedDialog = null
               break
            default:
               state.dialogs = state.dialogs.map(dialog => (
                  dialog.dialog_id === state.selectedDialog.dialog_id
                     ? { 
                        ...dialog, 
                        dialog_status: { ...action.payload.status }
                     }
                     : dialog
               ))
               state.selectedDialog = {
                  ...state.selectedDialog,
                  dialog_status: { ...action.payload.status }
               }
         }
      },
      setDialogTagSuccess: (state, action) => {
         state.dialogs = state.dialogs.map(dialog => (
            dialog.dialog_id === state.selectedDialog.dialog_id
               ? {
                  ...dialog,
                  dialog_tag: [ ...dialog.dialog_tag, action.payload ]
               }
               : dialog
         ))
         state.selectedDialog = {
            ...state.selectedDialog,
            dialog_tag: [ ...state.selectedDialog.dialog_tag, action.payload]
         }
      },
      deleteDialogTagSuccess: (state, action) => {
         state.dialogs = state.dialogs.map(dialog => (
            dialog.dialog_id === action.payload.dialog_id
               ? {
                  ...dialog,
                  dialog_tag: dialog.dialog_tag.filter(tag => tag.id !== action.payload.tag_id)
               }
               : dialog
         ))
         if (state.selectedDialog) {
            state.selectedDialog = {
               ...state.selectedDialog,
               dialog_tag: state.selectedDialog.dialog_tag.filter(tag => tag.id !== action.payload.tag_id)
            }
         }
      },
      hoistDialogOnNewMessageSuccess: (state, action) => {
         state.dialogs = [action.payload, ...state.dialogs]
      },
      updateDialogsOnMessageStatusEvent: (state, action) => {
         switch (Number(action.payload.status)) {
            case 1:
               state.dialogs = state.dialogs.map(dialog => (
                  dialog.dialog_id === action.payload.dialog_id
                     ? {
                        ...dialog,
                        new_message: dialog.new_message + 1,
                        status: action.payload.status
                     }
                     : dialog
               ))
               break
            case 3:
               state.dialogs = state.dialogs.map(dialog => (
                  dialog.dialog_id === action.payload.dialog_id
                     ? {
                        ...dialog,
                        new_message: dialog.new_message + (dialog.new_message > 0 ? -1 : 0),
                        status: dialog.new_message <= 1 ? action.payload.status : dialog.status
                     }
                     : dialog
               ))
               break
            case 4:
               state.dialogs = state.dialogs.map(dialog => (
                  dialog.dialog_id === action.payload.dialog_id
                     ? {
                        ...dialog,
                        last_message: dialog.last_message_id === action.payload.id ? "🚫 Сообщение удалено." : dialog.last_message,
                        new_message: action.payload.dialog_new_messages,
                        is_document: 'text'
                     }
                     : dialog
               ))
               break
            default:
               return state
         }
      },
      updateDialogsOnUpdateMessageEvent: (state, action) => {
         state.dialogs = state.dialogs.map(dialog => (
            dialog.dialog_id === action.payload.dialog_id
               ? {
                  ...dialog,
                  last_message: dialog.last_message_id === action.payload.id ? action.payload.text : dialog.last_message,
                  is_document: action.payload.type
               }
               : dialog
         ))
      },
      updateDialogNameSuccess: (state, action) => {
         state.selectedDialog = { ...action.payload }
         state.dialogs = state.dialogs.map(dialog => (
            dialog.dialog_id === action.payload.dialog_id
               ? { ...action.payload }
               : dialog
         ))
      },
      setDialogContactDateSuccess: (state, action) => {
         state.selectedDialog = { ...state.selectedDialog, follow_up_date: action.payload.follow_up_date }
         state.dialogs = state.dialogs.map(dialog => (
            dialog.dialog_id === action.payload.dialog_id
               ? { ...dialog, follow_up_date: action.payload.follow_up_date }
               : dialog
         ))
      }
   }
})

export const {
   setDialogs,
   fetchDialogsSuccess,
   setSelectedDialog,
   readDialogSuccess,
   setDialogStatusSuccess,
   setDialogStatusSuccessForManager,
   setDialogTagSuccess,
   deleteDialogTagSuccess,
   hoistDialogOnNewMessageSuccess,
   updateDialogsOnMessageStatusEvent,
   updateDialogsOnUpdateMessageEvent,
   updateDialogNameSuccess,
   setDialogContactDateSuccess
} = dialogsSlice.actions
export default dialogsSlice.reducer