import $axios from '../http'

class ManagersService {
   static fetchManagers = async () => {
      const { data } = await $axios.get('user/managers')
      return data
   }

   static updateManager = async (id, body) => {
      const { data } = await $axios.post(`manager/update?managerId=${id}`, body)
      return data
   }

   static activate = async (id) => {
      const { data } = await $axios.put(`manager/activate?managerId=${id}`)
      return data
   }

   static deactivate = async (id) => {
      const { data } = await $axios.put(`manager/deactivate?managerId=${id}`)
      return data
   }

   static delete = async (id) => {
      const { data } = await $axios.delete(`manager/delete?managerId=${id}`)
      return data
   }

   static updateIsEditDialogAllowed = async ({ id, editDialog }) => {
      const { data } = await $axios.post(`manager/set-edit-dialog-allowed?managerId=${id}`, {
         editDialog
      })
      return data
   }

   static updateIsContactInfoHidden = async ({ managerId, hideIdentity }) => {      
      const { data } = await $axios.post(`manager/set-hide-identity?managerId=${managerId}&hideIdentity=${hideIdentity}`)
      return data
   }

   static updateIsContactListUnloadingAllowed = async ({ managerId, isAllowed }) => {      
      const { data } = await $axios.post(`manager/set-unload-contact-list?managerId=${managerId}`, {
         unload_contact_list: isAllowed
      })
      return data
   }
}

export default ManagersService