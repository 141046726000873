import React, { useState } from 'react'

const ManagerPermission = ({ name, isActive, onToggle }) => {
   const [disableToggle, setDisagleToggle] = useState(false)

   const handleToggle = async () => {
      setDisagleToggle(true)
      onToggle()
      setDisagleToggle(false)
   }

   return (
      <div className="manager-permission-control-dialogs-permission">
         <p className="manager-permission-control-text">
            {name}
         </p>
         <button
            className={`manager-permission-controller ${isActive ? 'active' : ''}`}
            onClick={handleToggle}
            disabled={disableToggle}
         >
            <div
               className={`manager-permission-toggle-button ${isActive ? 'manager-permission-toggle-button_active' : ''}`}
            ></div>
         </button>
      </div>
   )
}

export default ManagerPermission