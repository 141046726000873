import { 
   calculatingPlanFailed,
   calculatingPlanFinished, 
   calculatingPlanStarted, 
   calculatingPlanSuccess, 
   changePlanSuccess, 
   fetchPlanFailed, 
   fetchPlanStarted, 
   fetchPlanSuccess, 
   fetchPlanFinished, 
   fetchAvailablePlanListStarted,
   fetchAvailablePlanListFinished,
   fetchAvailablePlanListSuccess,
   fetchAvailablePlanListFailed,
   changePlanFailed
} from "."
import PlanService from "../../../services/planService"

export const fetchPlan = () => async (dispatch) => {
   try {
      dispatch(fetchPlanStarted())
      const data = await PlanService.fetchPlan()
     
      if (!data.error) {
         dispatch(fetchPlanSuccess(!Array.isArray(data.data) ? data.data : {}))
      } else {
         dispatch(fetchPlanFailed('Не удалось получить данные о тарифе. Попробуйте позже.'))
      }
   } catch (err) {
      dispatch(fetchPlanFailed('Не удалось получить данные о тарифе. Попробуйте позже.'))
   } finally {
      dispatch(fetchPlanFinished())
   }
}

export const fetchAvailablePlanList = () => async (dispatch) => {
   try {
      dispatch(fetchAvailablePlanListStarted())
      const data = await PlanService.fetchAvailablePlanList()

      if (!data.error) {
         dispatch(fetchAvailablePlanListSuccess(data.data))
      } else {
         dispatch(fetchAvailablePlanListFailed('Не удалось получить данные о тарифе. Попробуйте позже.'))
      }
   } catch (err) {
      dispatch(fetchAvailablePlanListFailed('Не удалось получить данные о тарифе. Попробуйте позже.'))
   } finally {
      dispatch(fetchAvailablePlanListFinished())
   }
}

export const calculatePlan = (body) => async (dispatch) => {
   try {
      dispatch(calculatingPlanStarted())
      const { data, error } = await PlanService.calculatePlan(body)
      
      if (!error) {
         dispatch(calculatingPlanSuccess({ 
            should_pay: data.should_pay, 
            total: Number.isInteger(data.total) ? data.total : data.total.toFixed(2)
         }))
         return data.should_pay
      } else {
         alert('Не удалось подсчитать стоимость тарифа. Попробуйте еще раз.')
         dispatch(calculatingPlanFailed())
      }
   } catch (err) {
      alert('Не удалось подсчитать стоимость тарифа. Попробуйте еще раз.')
      dispatch(calculatingPlanFailed())
      return Promise.reject(err) 
   } finally {
      dispatch(calculatingPlanFinished())
   }
}

export const createPayment = (body) => async () => {
   try {
      const createOrderData = await PlanService.createOrder(body)
      
      if (!createOrderData.error) {
         const createPaymentData = await PlanService.createPayment({ orderId: createOrderData.data.order_id })

         if (!createPaymentData.error) {
            window.location.href = createPaymentData.data.redirect_link
         } else {
            alert('Не удалось создать заказ. Попробуйте позже.')
         }
      } else {
         alert('Не удалось создать заказ. Попробуйте позже.')
      }
   } catch (err) {
      alert('Не удалось создать заказ. Попробуйте позже.')
   }
}

export const changePlan = (body) => async (dispatch) => {
   try {
      const data = await PlanService.changePlan(body)

      if (!data.error) {
         dispatch(changePlanSuccess(data.data))
      } else {
         dispatch(changePlanFailed())
      }
   } catch (err) {
      dispatch(changePlanFailed())
   }
}

export const setFreePlan = (body) => async (dispatch) => {
   try {
      const data = await PlanService.setFreePlan(body)

      if (!data.error) {
         dispatch(changePlanSuccess(data.data))
      } else {
         dispatch(changePlanFailed())
      }
   } catch (err) {
      dispatch(changePlanFailed())
   }
}