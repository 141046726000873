import React from 'react'

import { planComponentsCodes, plansMap } from '../../../utils/consts'
import { getAvailableManagersQty } from '../../../utils'

import ManagersConstructor from '../ManagersConstructor'

const PlanComponent = ({ 
   availablePlanList,
   planComponent,
   cart,
   managers,
   onComponentsChange
}) => {
   const { code } = planComponent
   const { selectedPlan, selectedComponents } = cart
   
   const isComponentDisabled = (
      selectedPlan.type === undefined ||
      selectedPlan.type === plansMap.TEST || 
      !selectedComponents.some(component => component.code === code)
   )
   
   const planNamesWithComponent = availablePlanList.reduce((planNames, plan) => {
      return plan.items.some(item => item.code === code)
         ? [...planNames, `"${plan.name}"`]
         : planNames
   }, []).join(', ')

   switch (code) {
      case planComponentsCodes.MANAGER:
         const managerComponent = selectedComponents.find(component => (
            component.code === planComponentsCodes.MANAGER
         ))
         const currentManagersQty = managerComponent?.quantity
         const availableManagersQty = getAvailableManagersQty(managers, managerComponent)

         return (
            <ManagersConstructor
               planComponent={planComponent}
               currentManagersQty={currentManagersQty}
               availableManagersQty={availableManagersQty}
               planNamesWithComponent={planNamesWithComponent}
               onChange={onComponentsChange}
               disabled={isComponentDisabled}
            />
         )
      default:
         return null
   }
}

export default PlanComponent