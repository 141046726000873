import React, { Component } from 'react'

import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import Timer from './Timer'
import { btnThemes, Button } from '../Button'

export default class VoiceRecorder extends Component {
   constructor(props) {
      super(props)

      this.state = {
         recordState: 'stop',
      }
   }

   start = () => {
      this.setState({
         recordState: RecordState.START,
      })
      this.props.setTimer(0)
   }

   stop = () => {
      this.setState({
         recordState: RecordState.STOP,
      })
   }

   //audioData contains blob and blobUrl
   onStop = (audioData) => {
      this.props.setAudioTransmit(audioData)
   }

   render() {
      const { recordState } = this.state
      return (
         <div style={{ display: 'inline' }} disabled={!this.props.micActivated}>
            <div style={{ display: 'none' }}>
               <AudioReactRecorder state={recordState} onStop={this.onStop} />
            </div>
            <Button 
               customStyle='recorder-button'
               theme={btnThemes.PRIMARY_SKINNY}
               disabled={this.props.currentDialog?.dialog_channelStatus !== 'active'}
               // onMouseDown={this.start}
               // onMouseUp={this.stop}
               onClick={
                  this.state.recordState === 'stop' ? this.start : this.stop
               }
            >
               {this.state.recordState === 'start' ? (
                  <Timer
                     timer={this.props.timer}
                     setTimer={this.props.setTimer}
                  />
               ) : (
                  <></>
               )}
               <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.375 8.125C4.54076 8.125 4.69973 8.19085 4.81694 8.30806C4.93415 8.42527 5 8.58424 5 8.75V10C5 11.3261 5.52678 12.5979 6.46447 13.5355C7.40215 14.4732 8.67392 15 10 15C11.3261 15 12.5979 14.4732 13.5355 13.5355C14.4732 12.5979 15 11.3261 15 10V8.75C15 8.58424 15.0658 8.42527 15.1831 8.30806C15.3003 8.19085 15.4592 8.125 15.625 8.125C15.7908 8.125 15.9497 8.19085 16.0669 8.30806C16.1842 8.42527 16.25 8.58424 16.25 8.75V10C16.25 11.5494 15.6745 13.0436 14.6352 14.1927C13.5958 15.3418 12.1666 16.0638 10.625 16.2188V18.75H14.375C14.5408 18.75 14.6997 18.8158 14.8169 18.9331C14.9342 19.0503 15 19.2092 15 19.375C15 19.5408 14.9342 19.6997 14.8169 19.8169C14.6997 19.9342 14.5408 20 14.375 20H5.625C5.45924 20 5.30027 19.9342 5.18306 19.8169C5.06585 19.6997 5 19.5408 5 19.375C5 19.2092 5.06585 19.0503 5.18306 18.9331C5.30027 18.8158 5.45924 18.75 5.625 18.75H9.375V16.2188C7.83336 16.0638 6.40422 15.3418 5.36485 14.1927C4.32548 13.0436 3.74998 11.5494 3.75 10V8.75C3.75 8.58424 3.81585 8.42527 3.93306 8.30806C4.05027 8.19085 4.20924 8.125 4.375 8.125Z" fill="#00ACEF"/>
                  <path d="M12.5 10C12.5 10.663 12.2366 11.2989 11.7678 11.7678C11.2989 12.2366 10.663 12.5 10 12.5C9.33696 12.5 8.70107 12.2366 8.23223 11.7678C7.76339 11.2989 7.5 10.663 7.5 10V3.75C7.5 3.08696 7.76339 2.45107 8.23223 1.98223C8.70107 1.51339 9.33696 1.25 10 1.25C10.663 1.25 11.2989 1.51339 11.7678 1.98223C12.2366 2.45107 12.5 3.08696 12.5 3.75V10ZM10 0C9.00544 0 8.05161 0.395088 7.34835 1.09835C6.64509 1.80161 6.25 2.75544 6.25 3.75V10C6.25 10.9946 6.64509 11.9484 7.34835 12.6517C8.05161 13.3549 9.00544 13.75 10 13.75C10.9946 13.75 11.9484 13.3549 12.6517 12.6517C13.3549 11.9484 13.75 10.9946 13.75 10V3.75C13.75 2.75544 13.3549 1.80161 12.6517 1.09835C11.9484 0.395088 10.9946 0 10 0V0Z" fill="#00ACEF"/>
               </svg>
            </Button>
         </div>
      )
   }
}
