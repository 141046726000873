import { useEffect, useState } from 'react'
import ManagerPermissionTooltip from './ManagerPermissionTooltip'

const ManagerPermissionItem = ({
   entityIsNotAttached,
   element,
   addPermission,
   deletePermission,
   inActiveToggleButton,
   activeToggleButton,
   permissions,
}) => {
   const [isActivePermission, setIsActivePermission] = useState(false)

   useEffect(() => {
      setIsActivePermission(permissions.includes(element.id))
   }, [permissions, element.id])

   const togglePermission = () => {
      isActivePermission
         ? deletePermission(element.id)
         : addPermission(element.id)
      setIsActivePermission(!isActivePermission)
   }
   return (
      <div className="manager-permission-control-field">
         {entityIsNotAttached && <ManagerPermissionTooltip />}
         <span className={`manager-permission-field-name ${entityIsNotAttached ? 'manager-permission-field-name_active' : ''}`}>
            {element.name || `${element.type}-${element.username}`}
         </span>
         <div
            className={`manager-permission-controller ${isActivePermission ? 'active' : ''}`}
            onClick={togglePermission}
         >
            <div
               className="manager-permission-toggle-button"
               style={
                  isActivePermission ? activeToggleButton : inActiveToggleButton
               }
            ></div>
         </div>
      </div>
   )
}

export default ManagerPermissionItem
