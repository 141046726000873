import React from 'react'

import './ChannelSelect.css'

import Radio from '../../Radio'

const ChannelSelectOption = ({ isSelected, value, onChange }) => {
   const valueLabel = value 
      ? `${value.type} ${value.username}` 
      : 'Все каналы'

   return (
      <button 
         className={`channel-select-option ${isSelected ? 'selected' : ''}`}
         onClick={onChange}
      >
         <Radio checked={isSelected} />
         <span className='channel-select-option__label'>
            {valueLabel}
         </span>
      </button>
   )
}

export default ChannelSelectOption