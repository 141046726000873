import React from 'react'
import PasswordInput from './PasswordInput'
import TextInput from './TextInput'

const Input = (props) => {
   switch (props.type) {
      case 'password':
         return <PasswordInput {...props} />
      default:
         return <TextInput {...props} />
   }
}

export default Input