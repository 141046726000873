export const planComponentsCodes = {
   MANAGER: 1 
}

export const plansMap = {
   TEST: 0,
   BASIC: 1,
   FREE: 2
}

export const MIN_MANAGERS_QTY = 1
export const MAX_MANAGERS_QTY = 100
