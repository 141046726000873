import React, { useState } from 'react'

import './PeriodSelect.css'

import useClickOutside from '../../../hooks/useClickOutside'

const PeriodSelect = ({
   className= '',
   selectedValue,
   values,
   onSelect
}) => {   
   const [active, setActive] = useState(false)

   const handleToggleSelect = () => setActive(active => !active)

   const handleSelect = (value) => {
      if (values.length > 1) onSelect(value)
      handleToggleSelect()
   }

   const ref = useClickOutside(() => setActive(false))

   return (
      <div className={`period-select ${active ? 'active' : ''} ${className}`} ref={ref}>
         <div className='period-select__select'>
            <button 
               className='period-select__selected-value'
               onClick={handleToggleSelect}
            >
               <span className='period-select__selected-value-container'>
                  <span className='period-select__label'>Срок тарифа</span>
                  <span className='period-select__selected-value-text'>{selectedValue.name}</span>
               </span>
               <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 1L9 9L1 0.999999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
               </svg>
            </button>
            <div className='period-select__popup'>
               <ul className='period-select__list'>
                  {values.map(value => {
                     const isSelected = value.name === selectedValue.name

                     return (
                        <li
                           key={value.id} 
                           className={`period-select__list-item ${isSelected ? 'selected' : ''}`}
                           onClick={() => handleSelect(value)}
                        >
                           {value.name}
                        </li>
                     )
                  })}
               </ul>
            </div>
         </div>
      </div>
   )
}

export default PeriodSelect