import React from 'react'

import './ManagerCard.css'

import { managerStatuses } from '../../../utils/consts'

const ManagerCard = ({ isSelected, manager, onClick }) => {
   const { photo, username, email, status } = manager   

   const defaultAvatarSrc = '/assets/sidebar/default_avatar.png'
   const avatarSrc = photo || defaultAvatarSrc
   const isManagerActive = status === managerStatuses.active

   const handleAvatarLoadingError = (e) => {
      e.target.src = defaultAvatarSrc
   }

   const handleClick = () => onClick(manager)

   return (
      <div 
         className={`manager-card ${isSelected ? 'selected' : ''}`}
         onClick={handleClick}   
      >
         <div className='manager-card__avatar'>
            <img
               className='manager-card__photo'
               src={avatarSrc}
               alt='Avatar'
               onError={handleAvatarLoadingError}
               draggable={false}
            />
         </div>
         <div className='manager-card__info'>
            <p className='manager-card__name'>
               {username}
            </p>
            <p className='manager-card__email'>
               {email}
            </p>
         </div>
         <div className={`manager-card__status ${isManagerActive ? 'manager-card__status_active' : ''}`}>
         </div>
      </div>
   )
}

export default ManagerCard