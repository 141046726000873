import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash.debounce'
import '../settings/dropdown.css'

import { contacts_selectContacts, contacts_selectContactsLoading } from '../../store/slices/contacts/selectors'
import { contactsFilter_selectFilter } from '../../store/slices/contactsFilter/selectors'
import { 
   setContactsFilter, 
   setContactsFilterChannelId, 
   setContactsFilterSearchValue 
} from '../../store/slices/contactsFilter'
import { fetchContacts } from '../../store/slices/contacts/thunk-creators'
import { 
   mapContactsFilterToQuery, 
   mapContactsFilterToSearch, 
   mapContactsSearchToFilter, 
   mapContactsSearchToQuery
} from '../../utils/normalizeContactsParams'

import AddContact from '../modals/AddContact'
import { Button } from '../Button'
import Loader from '../Loader'
import Search from '../Search'
import ChannelSelect from './ChannelSelect'
import ContactCard from './ContactCard'

const SidebarContactsList = ({
   toggleSidebarView,
   showAddContactModal,
   setShowAddContactModal,
   channelList,
   newContactInitiationParams,
   allowedChannel,
   selectedContact,
   isManager,
   handleContactCardClick
}) => {
   const contacts = useSelector(contacts_selectContacts)
   const contactsLoading = useSelector(contacts_selectContactsLoading)
   const contactsFilter = useSelector(contactsFilter_selectFilter)
   const contactsSearchValue = contactsFilter.searchValue
   const contactsChannel = contactsFilter.channel
   const contactsChannelIds = contactsFilter.channelIds

   const [searchValue, setSearchValue] = useState(contactsSearchValue)
   const [shouldUpdateQuery, setShouldUpdateQuery] = useState(false)
   const [shouldFetchContacts, setShouldFetchContacts] = useState(false)
   const [disableContactsScroll, setDisableContactsScroll] = useState(false)

   const dispatch = useDispatch()
   const location = useLocation()
   const navigate = useNavigate()

   const onSearchChange = useMemo(() => debounce((searchValue) => {
      dispatch(setContactsFilterSearchValue(searchValue))
   }, 500), [])

   const handleSearchValueChange = (value) => {
      setSearchValue(value)
      onSearchChange(value)
   }

   const onChannelChange = useMemo(() => debounce((value) => {
      dispatch(setContactsFilterChannelId(value))
   }, 300), [])

   const handleFetchContacts = async (query) => {
      await dispatch(fetchContacts(query))
   }

   const handleContactsScroll = async (e) => {
      if (
         e.target.scrollHeight - e.target.scrollTop <=
         e.target.clientHeight + 35 && !disableContactsScroll 
      ) {
         setDisableContactsScroll(true)
         const query = mapContactsFilterToQuery(contactsFilter) 
         await dispatch(fetchContacts(query, true))
         setDisableContactsScroll(false)
      }
   }

   useEffect(() => {
      const query = mapContactsSearchToQuery(location.search)
      const currentFilter = mapContactsSearchToFilter(location.search)
      dispatch(setContactsFilter(currentFilter))
      if (!contacts.length) {
         handleFetchContacts(query)
      }
   }, [])

   useEffect(() => {
      if (shouldUpdateQuery) {
         const search = mapContactsFilterToSearch(contactsFilter)
         navigate(search)
         setShouldFetchContacts(true)
      } else {
         setShouldUpdateQuery(true)
      }
   }, [contactsFilter])

   useEffect(() => {
      if (shouldFetchContacts) {
         const query = mapContactsFilterToQuery(contactsFilter)
         handleFetchContacts(query)
      }
   }, [contactsSearchValue, contactsChannel, contactsChannelIds])

   useEffect(() => setSearchValue(contactsSearchValue), [contactsSearchValue])

   return (
      <>
         <div className='contacts-sidebar-filter'>
            <Search
               searchValue={searchValue}
               onChange={handleSearchValueChange}
            />
            <ChannelSelect
               selectedValues={contactsChannelIds}
               values={channelList}
               onChange={onChannelChange}
            />
         </div>
         <div
            className="sidebar-list"
            onScroll={handleContactsScroll}
            style={{
               display: toggleSidebarView ? 'flex' : 'none',
            }}
         >
            <AddContact
               close={() => {
                  setShowAddContactModal(!showAddContactModal)
               }}
               isVisible={showAddContactModal}
               channelList={channelList.filter(
                  (channel) => channel.status === 'active'
               )}
               newContactInitiationParams={newContactInitiationParams}
               allowedChannel={allowedChannel}
            />
            {contactsLoading
               ? <Loader />
               : contacts.map((contact) => (
                  <li key={contact.id}>
                     <ContactCard
                        contact={contact}
                        onClick={handleContactCardClick}
                     />
                  </li>
               )
            )}
         </div>
         <div className="sidebar-list-add-button-block">
            <Button
               customStyle='contacts-sidebar-btn'
               text='Добавить контакт'
               onClick={() => setShowAddContactModal(true)}
            />
         </div>
      </>
   )
}

export default SidebarContactsList
