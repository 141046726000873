import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash.debounce'

import './Plan.css'

import { 
   getIsPlanActive,
   getNormalizedPlanItems
} from '../utils'
import { plansMap } from '../../../utils/consts/plan'

import { plan_selectCart } from '../../../store/slices/plan/selectors'
import { 
   selectedComponentsChanged, 
   selectedPeriodChanged, 
   selectedPlanChanged 
} from '../../../store/slices/plan'
import { 
   calculatePlan, 
   changePlan, 
   createPayment, 
   setFreePlan 
} from '../../../store/slices/plan/thunk-creators'

import PlanItem from '../PlanItem'
import Cart from '../Cart'
import CartDetails from '../CartDetails'
import CartSupportBlock from '../CartSupportBlock'
import PlanComponent from '../PlanComponent'

const Plan = ({ 
   currentPlan, 
   availablePlanList,
   availablePlanComponents,
   managers 
}) => {
   const cart = useSelector(plan_selectCart)
   const { 
      selectedPlan,
      selectedPeriod,
      selectedComponents,
      shouldPay,
      calculationError
   } = cart

   const currentPlanData = currentPlan.plan
   const isPlanActive = getIsPlanActive(currentPlan.end_at)
   
   const [shouldCalculate, setShouldCalculate] = useState(
      currentPlanData &&
      currentPlanData?.id !== plansMap.TEST && 
      currentPlanData?.id !== plansMap.FREE && 
      !isPlanActive
   )
   const [disableChangePlanBtn, setDisableChangePlanBtn] = useState(isPlanActive)

   const dispatch = useDispatch()

   const handleSelectedPlanChange = (plan) => {
      dispatch(selectedPlanChanged(plan))
   }
   
   const handleChangeComponents = (component) => {      
      dispatch(selectedComponentsChanged(component))
   }
   
   const handlePeriodSelect = (period) => {
      dispatch(selectedPeriodChanged(period))
   }

   const handleCalculatePlan = async (body) => {
      try {
         const shouldPay = await dispatch(calculatePlan(body))
         setDisableChangePlanBtn(!shouldPay)
      } catch (err) {
         return Promise.reject(err)
      }
   }

   const debouncedCalculatePlan = useCallback(debounce(async (body) => {
      try {
         await handleCalculatePlan(body)
      } catch (err) {}
   }, 500), [])

   const handleCreatePayment = async () => {
      await dispatch(createPayment({
         planId: selectedPlan.id,
         period: selectedPeriod.value,
         planItems: getNormalizedPlanItems(selectedComponents)
      }))
   }

   const handleChangePlan = async () => {
      await dispatch(changePlan({
         planItems: getNormalizedPlanItems(selectedComponents)
      }))
   }

   const handleSetFreePlan = async () => {
      await dispatch(setFreePlan({
         planId: selectedPlan.id,
         period: selectedPeriod.value,
      }))
   }
   
   useEffect(() => {
      if (shouldCalculate && selectedPlan.type !== plansMap.FREE) {
         debouncedCalculatePlan({
            planId: selectedPlan.id,
            period: selectedPeriod.value,
            planItems: getNormalizedPlanItems(selectedComponents)
         })
      } else {
         setShouldCalculate(true)
      }
   }, [selectedPlan, selectedComponents, selectedPeriod])

   return (
      <div className='plan'>
         <div className='plan__main'>
            {availablePlanList.length > 0 && (
               <div className='plan__plan-list'>
                  {availablePlanList.map(plan => (
                     <PlanItem
                        key={plan.id}
                        planItem={plan}
                        selectedPlan={selectedPlan}
                        onSelect={handleSelectedPlanChange}
                     />
                  ))}
               </div>
            )}
            {availablePlanComponents.length > 0 && (
               <div className='plan__plan-components-list'>
                  {availablePlanComponents.map(component => (
                     <PlanComponent
                        key={component.id}
                        availablePlanList={availablePlanList}
                        planComponent={component}
                        cart={cart}
                        managers={managers}
                        onComponentsChange={handleChangeComponents}
                     />
                  ))}
               </div>
            )}
         </div>
         <div className='plan__sidebar'>
            <Cart
               cart={cart}
               currentPlan={currentPlan}
               isPlanActive={isPlanActive}
               shouldPay={shouldPay}
               changePlanBtnDisabled={disableChangePlanBtn}
               calculationError={calculationError}
               onPeriodChange={handlePeriodSelect}
               onCreatePayment={handleCreatePayment}
               onPlanChange={handleChangePlan}
               onSetFreePlan={handleSetFreePlan}
               onCalculate={handleCalculatePlan}
            />
            {currentPlan.id && (
               <CartDetails
                  currentPlan={currentPlan}
               />
            )}
            <CartSupportBlock />
         </div>
      </div>
   )
}

export default Plan