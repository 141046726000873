import React from 'react'

import './ContactCard.css'

const ContactCard = ({ isSelected, contact, onClick }) => {
   const { id, photo, domain, name } = contact

   const imgSrc = photo || '/assets/sidebar/default_avatar.png'

   const handleClick = () => onClick(id)

   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/sidebar/default_avatar.png'
   }

   return (
      <div 
         className={`contact-card ${isSelected ? 'selected' : ''}`}
         onClick={handleClick}   
      >
         <div className='contact-card__avatar'>
            <img 
               className='contact-card__avatar-photo' 
               src={imgSrc} 
               alt='avatar'
               onError={handleImageLoadingError}
            />
            <img
               className='contact-card__channel-icon'
               src={`/assets/sidebar/${domain}-icon.svg`}
               alt='Channel type'
            />
         </div>
         <p className='contact-card__username'>
            {name}
         </p>
      </div>
   )
}

export default ContactCard