import React, { useState } from 'react'

import './TagSelect.css'

const SelectedValueItem = ({ value, onDelete }) => {
   const [disableDeleteBtn, setDisableDeleteBtn] = useState(false)

   const handleDelete = async (e) => {      
      e.stopPropagation()
      try {
         setDisableDeleteBtn(true)
         await onDelete(value.value)
      } catch {
         setDisableDeleteBtn(false)
      }
   }

   return (
      <span
         key={value.value}
         className='tag-select__selected-value-item'

      >
         <span className='tag-select__selected-value-item-text'>
            {value.label}
         </span>
         <button
            className='tag-select__selected-value-item-delete-btn'
            onClick={handleDelete} 
            disabled={disableDeleteBtn}
         >
            <img 
               className='tag-select__selected-value-item-delete-btn-icon' 
               src='/assets/controls/close.svg' 
               alt='Close' 
            />
         </button>
      </span>
   )
}

export default SelectedValueItem