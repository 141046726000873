import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import uniqid from 'uniqid'
import './mainbarChat.css'
import VoiceRecorder from './VoiceRecorder'
import NotificationModal from '../modals/NotificationModal'
import { getLabelForCommentByPostId } from '../utils/helper'
import useAutoResizeTextarea from '../../hooks/useAutoResizeTextarea'
import MessagesService from '../../services/messagesService'
import { sendMessageFail } from '../../store/slices/messages'
import AttachmentsBox from '../AttachmentsBox'
import Tooltip from '../Tooltip'
import { tooltipsData, voiceMessageConstraints } from '../../utils/consts'
import ReplySection from './ReplySection'
import ForwardMessagesSection from './ForwardMessagesSection'
import TemplateList from '../TemplateList'
import { useClickOutside } from '../../hooks'
import { Button, btnSizes } from '../Button'
import DialogAnswerTimer from '../DialogAnswerTimer'

const MainbarChat = ({
   dialog,
   currentUser,
   templates,
   isManager,
   dialogs,
   selectedConversation,
   messages,
   selectedMessages,
   messageToForward,
   setMessages,
   showTemplate,
   setShowTemplate,
   setTemplateMessages,
   selectTemplate,
   commentReplySection,
   setCommentReplySection,
   setShowEmoji,
   chosenEmoji,
   uploadFileList,
   setUploadFileList,
   toggleSidebarView,
   setSelectTemplate,
   messageText,
   onMessageTextChange,
   handleReplySectionClose,
   selectedMessageToEdit,
   handleEditMessageCancel,
   onEditMessage,
   handleMessagesSelectionClear,
   handleDialogsScroll,
   handleForwardMessages,
   handleForwardMessageCancel,
   handleDeleteSelectedMessages,
   templateSubsections
}) => {
   const hiddenFileInput = React.useRef(null)
   const [currentDialog, setCurrentDialog] = useState(dialog)

   const unansweredMessageTimestamp = dialog.date_first_incoming_in_series
   const maxTimeWithoutAnswer = currentUser.time_without_answer   

   const showWithoutAnswerTimer = unansweredMessageTimestamp && maxTimeWithoutAnswer 

   useEffect(() => {
      setCurrentDialog(dialog)
   }, [dialog])

   const {
      value: inputMessage,
      setValue: setInputMessage,
      inputRef: messageInputRef
   } = useAutoResizeTextarea(messageText)
   const [placeholder, setPlaceholder] = useState('Напишите сообщение...')
   const [fileField, setFileField] = useState([])
   const [audioTransmit, setAudioTransmit] = useState(null)

   const [inputFileDisabled, setInputFileDisabled] = useState(false)
   const [micActivated, setMicActivated] = useState(false)
   const [timer, setTimer] = useState(0)

   const [isShowModalPermissions, setIsShowModalPermissions] = useState(false)

   const templateListItemRef = useClickOutside(() => setShowTemplate(false))

   const dispatch = useDispatch()

   const toggleIsShowModalPermissions = () => {
      setIsShowModalPermissions(!isShowModalPermissions)
   }

   const handleClick = (event) => {
      hiddenFileInput.current.click()
   }

   const handleMessageTextChange = (value) => {
      setInputMessage(value)
      onMessageTextChange(value)
   }

   const handleFileFieldChange = (files) => {
      if (fileField.length + files.length > 4) {
         alert('Превышен лимит прикрепленных файлов! (4)')
         return
      }

      const newFiles = []

      for (let i = 0; i < files.length; i++) {
         if (files[i].size > 99 * 1024 * 1024) {
            alert('Файл не может превышать 100MB в размере!')
            return
         }
         newFiles.push({
            id: uniqid(),
            file: files[i]
         })
      }

      setFileField(files => [...files, ...newFiles])
   }

   const handleFileDelete = (id) => setFileField(files => files.filter(file => file.id !== id))

   const handleTemplateSelect = (template) => {
      setSelectTemplate(template)
      setShowTemplate(false)
   }

   useEffect(() => {
      setFileField([])
      setAudioTransmit(null)
   }, [selectedConversation])

   useEffect(() => {
      const { text, file } = selectTemplate
      if (text) onMessageTextChange(text)
      if (file) setFileField(files => [...files, { id: uniqid(), file: { ...file }}])

      return () => {
         setFileField(files => files.filter(fileItem => !fileItem.link))
      }
   }, [selectTemplate])

   useEffect(() => {
      const handleContentPaste = (event) => {
         const items = (event.clipboardData || event.originalEvent.clipboardData).items
         const files = []
   
         for (let i = 0; i < items.length; i++) {
            const item = items[i]
            if (item.kind === 'file') {
               const file = item.getAsFile()
               files.push(file)
            }
         }
   
         handleFileFieldChange(files)
      }

      window.addEventListener('paste', handleContentPaste)

      return () => window.removeEventListener('paste', handleContentPaste)
   }, [handleFileFieldChange])

   const sendAudio = () => {
      const minMessageDuraction = voiceMessageConstraints.min
      const maxMessageDuration = voiceMessageConstraints.max[dialog.dialog_channel]
      const warningMessage = `Продолжительность голосового сообщения должна быть от ${minMessageDuraction} до ${maxMessageDuration} секунд`
      
      if (timer < minMessageDuraction || timer > maxMessageDuration) return alert(warningMessage)

      let currentDate = Math.round(+new Date() / 1000)
      const fileToUpload = new File([audioTransmit.blob], 'audioMessage.mp4', {
         type: 'audio/mp4',
         mimeType: 'audio/mpeg',
      })
      var bodyFormData = new FormData()
      bodyFormData.append('files[]', fileToUpload)
      bodyFormData.append('dialog_id', selectedConversation)
      bodyFormData.append('text', '')
      commentReplySection !== null && bodyFormData.append('message_id', commentReplySection.id)
      setTimer(0)
      MessagesService
         .sendAudio(bodyFormData)
         .then(data => {
            if (data.error) {
               const sendedMessage = {
                          id: data.data.id,
                          dialog_id: data.data.dialog_id,
                          text: data.data.error,
                          timestamp: currentDate,
                          isDocument: false,
                          is_incoming: false,
                          status: -1,
                          type: 'text',
                          manager: null,
                          author: {
                             type: currentDialog.dialog_channel,
                          },
                       }
               dispatch(sendMessageFail(sendedMessage))
            } else {
               setAudioTransmit(null)
            }
         })
         .catch(function (response) {
            //handle error
            console.log(response)
         })
   }

   const sendMessage = () => {
      setPlaceholder('Напишите сообщение...')
      let currentDate = Math.round(+new Date() / 1000)

      const bodyFormData = new FormData()
      bodyFormData.append('dialog_id', selectedConversation)
      bodyFormData.append('text', inputMessage)
      commentReplySection !== null && bodyFormData.append('message_id', commentReplySection.id)

      MessagesService
         .outgoing(bodyFormData)
         .then(data => {
            if (data.error) {
               const sendedMessage = {
                  id: data.data.id,
                  dialog_id: data.data.dialog_id,
                  text: data.data.error,
                  timestamp: currentDate,
                  isDocument: false,
                  is_incoming: false,
                  status: -1,
                  type: 'text',
                  manager: null,
                  author: {
                     type: currentDialog.dialog_channel,
                  },
               }
               dispatch(sendMessageFail(sendedMessage))
            }
         })
         .catch(function (err) {
            //handle error
            console.log(err)
         })
   }

   const sendFileText = () => {
      let currentDate = Math.round(+new Date() / 1000)
      const filesUploaded = fileField
      
      let bodyFormData = new FormData()
      bodyFormData.append('dialog_id', selectedConversation)

      filesUploaded.forEach((file, i) => {
         if (file.file.hasOwnProperty('link')) {
            bodyFormData.append('url', file.file.link)
         } else {
            bodyFormData.append('files[]', file.file)
         }
         i === 0 && bodyFormData.append('text', inputMessage)
      })
      commentReplySection !== null && bodyFormData.append('message_id', commentReplySection.id)

      MessagesService
         .outgoing(bodyFormData)
         .then(data => {
            if (data.error) {
               const sendedMessage = {
                          id: data.data.id,
                          dialog_id: data.data.dialog_id,
                          text: data.data.error,
                          timestamp: currentDate,
                          isDocument: false,
                          is_incoming: false,
                          status: -1,
                          type: 'text',
                          manager: null,
                          author: {
                             type: currentDialog.dialog_channel,
                          },
                       }
               dispatch(sendMessageFail(sendedMessage))
            }
         })
         .catch(function (response) {
            //handle error
            console.log(response)
         })
   }

   const handleSendClick = () => {
      if (currentDialog.read_only && isManager === true) {
         return toggleIsShowModalPermissions()
      }
      if (audioTransmit !== null) {
         sendAudio()
         setUploadFileList([])
         setAudioTransmit(null)
         setCommentReplySection(null)
         return
      }
      if (inputMessage.length === 0 && fileField.length === 0) {
         return setPlaceholder('Текст сообщения не может быть пустым.')
      }
      if (selectedMessageToEdit && 
         inputMessage.length !== 0 &&
         inputMessage !== selectedMessageToEdit.text
      ) {
         onEditMessage({
            dialogId: selectedMessageToEdit.dialog_id,
            messageId: selectedMessageToEdit.id,
            text: inputMessage
         })
      }
      if (
         !selectedMessageToEdit &&
         ((inputMessage.length !== 0 && fileField.includes(null)) ||
         fileField.length === 0)
      ) {
         sendMessage()
      } else if (!selectedMessageToEdit && fileField.length !== 0) {
         sendFileText()
      }
      setFileField([])
      setInputFileDisabled(false)
      setInputMessage('')
      setShowEmoji(false)
      setShowTemplate(false)
      setCommentReplySection(null)
      setSelectTemplate({})
   }

   const handleTemplateClick = () => {
      if (currentDialog.read_only && isManager === true) {
         return toggleIsShowModalPermissions()
      }
      setShowTemplate((prev) => !prev)
      setTemplateMessages(templates)
   }

   const handleTextareaKeyDown = (e) => {
      if (!e.ctrlKey && !e.shiftKey && !e.altKey && e.keyCode === 13) {
         e.preventDefault()
         handleSendClick()
         return true
      } else if ((e.ctrlKey || e.shiftKey) && e.keyCode === 13) {
         handleMessageTextChange(messageText + (e.ctrlKey ? "\r\n" : ''))
      }
   }

   useEffect(() => {
      selectTemplate.target && setInputMessage(selectTemplate.target.innerText)
   }, [selectTemplate])

   useEffect(() => {
      chosenEmoji !== null &&
         chosenEmoji.emoji !== null &&
         setInputMessage((prev) => prev + chosenEmoji.emoji)
   }, [chosenEmoji])

   useEffect(() => {
      async function getMedia(constraints) {
         let stream = null

         try {
            stream = await navigator.mediaDevices.getUserMedia(constraints)
            if (stream) setMicActivated(true)
            return
         } catch (err) {
            setMicActivated(false)
            console.log(err)
            return
         }
      }

      getMedia({ audio: true })
   }, [])

   const handleMicWarning = () => {
      alert('Небходимо разрешить доступ к микрофону в брузере')
   }

   return (
      <div
         id='send-message'
         className="mainbar-chat"
      >
         <NotificationModal
            isVisible={isShowModalPermissions}
            title={'Уведомление'}
            text={'Вам предоставлено только чтение диалогов.'}
            ok={toggleIsShowModalPermissions}
         />
         {showWithoutAnswerTimer && (
            <DialogAnswerTimer
               customStyle='dialog-chat-timer'
               unansweredMessageTimestamp={unansweredMessageTimestamp}
               maxTimeWithoutAnswer={maxTimeWithoutAnswer}
            />
         )}
         {audioTransmit !== null && (
            <div className='mainbar-chat-attachments'>
               <span style={{ position: 'relative', width: '100%' }}>
                  <audio
                     controls
                     src={audioTransmit.url}
                     style={{ display: 'inline-block' }}
                  ></audio>
                  <img
                     src="/assets/chat/xmark.svg"
                     alt=""
                     style={{
                        width: '17px',
                        height: '17px',
                        cursor: 'pointer',
                        filter: 'brightness(0%)',
                     }}
                     onClick={() => setAudioTransmit(null)}
                  />
               </span>
            </div> 
         )}
         <div className="mainbar-chat-wrapper">
            {commentReplySection && (
               <ReplySection
                  message={commentReplySection}
                  onReplySectionClose={handleReplySectionClose}
               />
            )}
            {selectedMessageToEdit && (
               <ReplySection
                  sectionType='edit'
                  message={selectedMessageToEdit}
                  onReplySectionClose={handleEditMessageCancel}
               />
            )}
            {(selectedMessages.length > 0 || messageToForward) && (
               <ForwardMessagesSection
                  messageToForward={messageToForward}
                  selectedDialog={dialog}
                  dialogs={dialogs}
                  selectedMessages={selectedMessages}
                  onScroll={handleDialogsScroll}
                  onForward={handleForwardMessages}
                  onForwardCancel={handleForwardMessageCancel}
                  onDelete={handleDeleteSelectedMessages}
                  onClear={handleMessagesSelectionClear}
               />
            )}
            <div className='mainbar-chat-wrapper__send-message-form'>
               <div className="mainbar-chat-area-wrap">
                  {!audioTransmit && (
                     <AttachmentsBox
                        files={fileField}
                        disabled={
                           currentDialog
                              ? currentDialog.dialog_channelStatus !== 'active'
                              : null
                        }
                        onChange={handleFileFieldChange}
                        onDelete={handleFileDelete}
                     />
                  )}
                  {uploadFileList !== null && uploadFileList.length !== 0 ? (
                     <div
                        style={{
                           display: 'flex',
                           flexWrap: 'wrap',
                           alignItems: 'center',
                           marginLeft: '45px',
                        }}
                     >
                        {uploadFileList.map((uploadFile, index) => (
                           <span
                              style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 alignItems: 'flex-start',
                                 marginRight: '5px',
                              }}
                              key={index}
                           >
                              <img
                                 src="/assets/chat/file.svg"
                                 alt=""
                                 style={{ height: '28px' }}
                              />
                              <span
                                 style={{ lineHeight: '18px', fontSize: '12px' }}
                              >
                                 {uploadFile && uploadFile.name}
                              </span>
                           </span>
                        ))}
                     </div>
                  ) : (
                     <div style={{ position: 'relative' }}>
                        <div className="mainbar-chat-area-input-wrap">
                           <textarea
                                 ref={messageInputRef}
                                 className="mainbar-chat-area"
                                 placeholder={placeholder}
                                 value={inputMessage}
                                 onChange={(e) => handleMessageTextChange(e.target.value)}
                                 onKeyDown={handleTextareaKeyDown}
                                 disabled={
                                    currentDialog
                                       ? currentDialog.dialog_channelStatus !== 'active'
                                       : null
                                 }
                           />
                        </div>
                        <button
                           className="mainbar-chat-emoji"
                           disabled={
                              currentDialog
                                 ? currentDialog.dialog_channelStatus !== 'active'
                                 : null
                           }
                           onClick={() => setShowEmoji((prev) => !prev)}
                        >
                           <svg
                              viewBox="0 0 26 26"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                           >
                              <path
                                 d="M14.5 25.375C20.5061 25.375 25.375 20.5061 25.375 14.5C25.375 8.4939 20.5061 3.625 14.5 3.625C8.4939 3.625 3.625 8.4939 3.625 14.5C3.625 20.5061 8.4939 25.375 14.5 25.375Z"
                                 stroke-width="2"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              />
                              <path
                                 d="M10.875 12.0835H10.8871"
                                 stroke-width="2"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              />
                              <path
                                 d="M18.125 12.0835H18.1371"
                                 stroke-width="2"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              />
                              <path
                                 d="M11.479 18.125C11.8728 18.5269 12.3428 18.8462 12.8615 19.0641C13.3802 19.2821 13.9372 19.3944 14.4998 19.3944C15.0625 19.3944 15.6195 19.2821 16.1382 19.0641C16.6569 18.8462 17.1269 18.5269 17.5207 18.125"
                                 stroke-width="2"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              />
                           </svg>
                        </button>
                        <div className='mainbar-chat-action-container mainbar-chat-action-container_templates'>
                           <div ref={templateListItemRef} className='mainbar-chat-action-container__btn'>
                              <button
                                 className="mainbar-chat-templates"
                                 onClick={handleTemplateClick}
                                 disabled={
                                    currentDialog
                                       ? currentDialog.dialog_channelStatus !== 'active'
                                       : null
                                 }
                              >
                                 <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2563_1316)">
                                       <path d="M2.5 1.25C2.16848 1.25 1.85054 1.3817 1.61612 1.61612C1.3817 1.85054 1.25 2.16848 1.25 2.5V12.5C1.25 12.8315 1.3817 13.1495 1.61612 13.3839C1.85054 13.6183 2.16848 13.75 2.5 13.75H14.4825C15.1455 13.7501 15.7813 14.0136 16.25 14.4825L18.75 16.9825V2.5C18.75 2.16848 18.6183 1.85054 18.3839 1.61612C18.1495 1.3817 17.8315 1.25 17.5 1.25H2.5ZM17.5 0C18.163 0 18.7989 0.263392 19.2678 0.732233C19.7366 1.20107 20 1.83696 20 2.5V18.4913C20 18.6149 19.9633 18.7358 19.8945 18.8386C19.8257 18.9414 19.728 19.0215 19.6138 19.0687C19.4995 19.116 19.3737 19.1282 19.2525 19.104C19.1312 19.0797 19.0198 19.0201 18.9325 18.9325L15.3663 15.3663C15.1319 15.1318 14.814 15.0001 14.4825 15H2.5C1.83696 15 1.20107 14.7366 0.732233 14.2678C0.263392 13.7989 0 13.163 0 12.5V2.5C0 1.83696 0.263392 1.20107 0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0L17.5 0Z" fill="#5D6070"/>
                                       <path d="M3.75 4.375C3.75 4.20924 3.81585 4.05027 3.93306 3.93306C4.05027 3.81585 4.20924 3.75 4.375 3.75H15.625C15.7908 3.75 15.9497 3.81585 16.0669 3.93306C16.1842 4.05027 16.25 4.20924 16.25 4.375C16.25 4.54076 16.1842 4.69973 16.0669 4.81694C15.9497 4.93415 15.7908 5 15.625 5H4.375C4.20924 5 4.05027 4.93415 3.93306 4.81694C3.81585 4.69973 3.75 4.54076 3.75 4.375ZM3.75 7.5C3.75 7.33424 3.81585 7.17527 3.93306 7.05806C4.05027 6.94085 4.20924 6.875 4.375 6.875H15.625C15.7908 6.875 15.9497 6.94085 16.0669 7.05806C16.1842 7.17527 16.25 7.33424 16.25 7.5C16.25 7.66576 16.1842 7.82473 16.0669 7.94194C15.9497 8.05915 15.7908 8.125 15.625 8.125H4.375C4.20924 8.125 4.05027 8.05915 3.93306 7.94194C3.81585 7.82473 3.75 7.66576 3.75 7.5ZM3.75 10.625C3.75 10.4592 3.81585 10.3003 3.93306 10.1831C4.05027 10.0658 4.20924 10 4.375 10H10.625C10.7908 10 10.9497 10.0658 11.0669 10.1831C11.1842 10.3003 11.25 10.4592 11.25 10.625C11.25 10.7908 11.1842 10.9497 11.0669 11.0669C10.9497 11.1842 10.7908 11.25 10.625 11.25H4.375C4.20924 11.25 4.05027 11.1842 3.93306 11.0669C3.81585 10.9497 3.75 10.7908 3.75 10.625Z" fill="#5D6070"/>
                                    </g>
                                    <defs>
                                       <clipPath id="clip0_2563_1316">
                                          <rect width="20" height="20" fill="white"/>
                                       </clipPath>
                                    </defs>
                                 </svg>
                              </button>
                              <Tooltip
                                 className='mainbar-chat-action-container__tooltip'
                                 text={tooltipsData.chat.templates}
                                 position='top'
                              />
                              {showTemplate && (
                                 <TemplateList
                                    templates={templates}
                                    templateSubsections={templateSubsections}
                                    handleCloseTemplateList={() => setShowTemplate(false)}
                                    handleTemplateSelect={handleTemplateSelect}
                                 />
                              )}
                           </div>
                        </div>
                     </div>
                  )}
               </div>
               <div className="mainbar-chat-control-buttons">
                  {micActivated ? (
                     <VoiceRecorder
                        currentDialog={currentDialog}
                        setAudioTransmit={setAudioTransmit}
                        micActivated={micActivated}
                        timer={timer}
                        setTimer={setTimer}
                     />
                  ) : (
                     <button
                        style={{ width: '25px', height: '25px' }}
                        onClick={handleMicWarning}
                     >
                        <svg
                           width="24"
                           height="24"
                           viewBox="0 0 24 24"
                           fill="none"
                           opacity=".3"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 2.75C10.2051 2.75 8.75 4.20507 8.75 6V12C8.75 13.7949 10.2051 15.25 12 15.25C13.7949 15.25 15.25 13.7949 15.25 12V6C15.25 4.20507 13.7949 2.75 12 2.75ZM7.25 6C7.25 3.37665 9.37665 1.25 12 1.25C14.6234 1.25 16.75 3.37665 16.75 6V12C16.75 14.6234 14.6234 16.75 12 16.75C9.37665 16.75 7.25 14.6234 7.25 12V6Z"
                              fill="#252525"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5 9.75C5.41421 9.75 5.75 10.0858 5.75 10.5V12C5.75 15.4518 8.54821 18.25 12 18.25C15.4518 18.25 18.25 15.4518 18.25 12V10.5C18.25 10.0858 18.5858 9.75 19 9.75C19.4142 9.75 19.75 10.0858 19.75 10.5V12C19.75 16.2802 16.2802 19.75 12 19.75C7.71979 19.75 4.25 16.2802 4.25 12V10.5C4.25 10.0858 4.58579 9.75 5 9.75Z"
                              fill="#252525"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 1.75C12.4142 1.75 12.75 2.08579 12.75 2.5V5.5C12.75 5.91421 12.4142 6.25 12 6.25C11.5858 6.25 11.25 5.91421 11.25 5.5V2.5C11.25 2.08579 11.5858 1.75 12 1.75Z"
                              fill="#252525"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.25 8C7.25 7.58579 7.58579 7.25 8 7.25H10.5C10.9142 7.25 11.25 7.58579 11.25 8C11.25 8.41421 10.9142 8.75 10.5 8.75H8C7.58579 8.75 7.25 8.41421 7.25 8Z"
                              fill="#252525"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.75 8C12.75 7.58579 13.0858 7.25 13.5 7.25H16C16.4142 7.25 16.75 7.58579 16.75 8C16.75 8.41421 16.4142 8.75 16 8.75H13.5C13.0858 8.75 12.75 8.41421 12.75 8Z"
                              fill="#252525"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.25 11C7.25 10.5858 7.58579 10.25 8 10.25H10.5C10.9142 10.25 11.25 10.5858 11.25 11C11.25 11.4142 10.9142 11.75 10.5 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11Z"
                              fill="#252525"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.75 11C12.75 10.5858 13.0858 10.25 13.5 10.25H16C16.4142 10.25 16.75 10.5858 16.75 11C16.75 11.4142 16.4142 11.75 16 11.75H13.5C13.0858 11.75 12.75 11.4142 12.75 11Z"
                              fill="#252525"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.25 22V19H12.75V22H11.25Z"
                              fill="#252525"
                           />
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.25 22C7.25 21.5858 7.58579 21.25 8 21.25H16C16.4142 21.25 16.75 21.5858 16.75 22C16.75 22.4142 16.4142 22.75 16 22.75H8C7.58579 22.75 7.25 22.4142 7.25 22Z"
                              fill="#252525"
                           />
                        </svg>
                     </button>
                  )}
                  <Button
                     customStyle='mainbar-chat-send-button'
                     onClick={handleSendClick}
                     disabled={
                        currentDialog
                           ? currentDialog.dialog_channelStatus !== 'active'
                           : null
                     }
                  >
                     <img
                        src={`/assets/chat/send.svg`}
                        alt=""
                        style={{ width: '16px', height: '16px' }}
                     />
                  </Button>
               </div>
            </div>
         </div>
      </div>
   )
}

export default MainbarChat
