import React, { useState } from 'react'

import './IncorrectNumbersModal.css'

import { btnThemes, Button } from '../../Button'

const IncorrectNumbersModal = ({
   show,
   numbersList,
   onChooseNewFile,
   onSubmit,
   onClose
}) => {
   const [disableSubmitBtn, setDisableSubmitBtn] = useState(false)

   const handleSubmit = async () => {
      setDisableSubmitBtn(true)
      await onSubmit()
      setDisableSubmitBtn(false)
   }

   return (
      <div className={`incorrect-numbers-modal ${show ? 'active' : ''}`}>
         <div className='incorrect-numbers-modal__body'>
            <div className='incorrect-numbers-modal__header'>
               <h4 className='incorrect-numbers-modal__title'>
                  Некорректные номера телефонов
               </h4>
               <button 
                  className='incorrect-numbers-modal__close-btn'
                  onClick={onClose}   
               >
                  <img src='/assets/controls/close.svg' alt='Close' />
               </button>
            </div>
            <div className='incorrect-numbers-modal__text'>
               Файл содержит некорректные номера телефонов. Вы можете загрузить новый файл или отправить рассылку по корректным номерам.
            </div>
            <div className='incorrect-numbers-modal__numbers-list-container'>
               <p className='incorrect-numbers-modal__numbers-list-title'>
                  Контакты непрошедшие проверку:
               </p>
               <ul className='incorrect-numbers-modal__numbers-list'>
                  {numbersList.map(number => (
                     <li key={number} className='incorrect-numbers-modal__numbers-list-item'>
                        <img src='/assets/controls/error-icon.svg' alt='Error' />
                        {number}
                     </li>
                  ))}
               </ul>
            </div>
            <div className='incorrect-numbers-modal__footer'>
               <Button
                  text='Загрузить новый файл'
                  theme={btnThemes.PRIMARY_SKINNY}
                  onClick={onChooseNewFile}
               />
               <Button
                  text='Отправить рассылку по корректным номерам'
                  onClick={handleSubmit}
                  disabled={disableSubmitBtn}
               />
            </div>
         </div>
      </div>
   )
}

export default IncorrectNumbersModal