import { CSSTransition } from 'react-transition-group'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import debounce from 'lodash.debounce'
import uniqid from 'uniqid'

import './addMailing.css'

import { contacts_selectContacts } from '../../../store/slices/contacts/selectors'
import { setContactsFilterChannelIds, setContactsFilterSearchValue } from '../../../store/slices/contactsFilter'
import { contactsFilter_selectFilter } from '../../../store/slices/contactsFilter/selectors'
import { mapContactsFilterToQuery } from '../../../utils/normalizeContactsParams'
import { fetchContacts } from '../../../store/slices/contacts/thunk-creators'

import { mailingTypesMap } from '../../../utils/consts'
import { messengerLimits } from '../../../data/limits'
import WarningBlock from '../WarningBlock'
import MailingFileNotification from '../MailingFileNotification'
import Checkbox from '../../Checkbox'
import ChannelSelect from './ChannelSelect'
import ContactsSelect from './ContactsSelect'
import { Button } from '../../Button'
import Select from '../../Select'
import DropFileInput from '../../DropFileInput'

const AddMailing = ({
   isVisible,
   mailingType,
   toggleShowAddMailingModal,
   tag,
   allowedTag,
   status,
   allowedStatus,
   channelList,
   allowedChannel,
   setHistoryMailing,
   shouldClearMailingModalCache,
   setShouldClearMailingModalCache,
   handleOpenIncorrectNumbersModal,
   currentUser,
   contactsList,
   handleContactsScroll
}) => {
   const contacts = useSelector(contacts_selectContacts)
   const contactsFilter = useSelector(contactsFilter_selectFilter)
   const { searchValue, channel, channelIds } = contactsFilter

   const [selectedChannels, setSelectedChannels] = useState([])
   const [allSelectedChannels, setAllSelectedChannels] = useState(false)
   const [areTheChannelsSelected, setTheChannelsSelected] = useState(false)

   const [selectedTags, setSelectedTags] = useState([])
   const [allSelectedTags, setAllSelectedTags] = useState(false)
   const [areTheTagsSelected, setTheTagsSelected] = useState(false)

   const [selectedStatuses, setSelectedStatuses] = useState([])
   const [allSelectedStatuses, setAllSelectedStatuses] = useState(false)
   const [areTheStatusesSelected, setTheStatusesSelected] = useState(false)

   const [tagsStatusesAreNotSelected, setTagsStatusesAreNotSelected] = useState(false)

   const [textField, setTextField] = useState('')
   const [isTextFieldFilled, setIsTextFieldFilled] = useState(false)
   const [fileField, setFileField] = useState([])

   const [fileListField, setFileListField] = useState([])
   const [isFileListLoaded, setIsFileListLoaded] = useState(false)
   const [isFileListExtValid, setIsFileListExtValid] = useState(false)

   const [isShowDropdown, setIsShowDropdown] = useState(false)
   const toggleIsShowDropdown = () => {
      setIsShowDropdown(!isShowDropdown)
   }
   const [selectedChannelId, setSelectedChannelId] = useState(null)
   const [areTheChannelIdSelected, setTheChannelIdSelected] = useState(false)

   const [currentLimit, setCurrentLimit] = useState(null)
   const [isLoading, setLoading] = useState(false)

   const [serverError, setServerError] = useState('')
   const [isShowServerError, setIsShowServerError] = useState(false)

   const [contactList, setContactList] = useState([])
   const [contactListCount, setContactListCount] = useState(1)
   const [contactInputValue, setContactInputValue] = useState(null)
   const [areContactListExist, setClientsListExist] = useState(false)

   const [selectedChannelForByNewContacts, setSelectedChannelForByNewContacts] = useState(null)
   const [selectedContacts, setSelectedContacts] = useState([])
   const [showSelectedContactsError, setShowSelectedContactsError] = useState(false)

   const dispatch = useDispatch()
   
   const handleSelectChannel = (item, isSelected) => {    
      setSelectedChannels(channels => (
         isSelected
            ? channels.filter(channel => channel.id !== item.id)
            : [...channels, item]
      ))
   }
   
   const handleDeleteSelectedChannel = (id) => {
      setSelectedChannels(channels => channels.filter(channel => channel.id !== id))
   }

   const handleSelectStatus = (item, isSelected) => {      
      setSelectedStatuses(statuses => (
         isSelected
            ? statuses.filter(status => status.id !== item.id)
            : [...statuses, item]
      ))
   }
   
   const handleDeleteSelectedStatus = (id) => {
      setSelectedStatuses(statuses => statuses.filter(status => status.id !== id))
   }  
   
   const handleSelectTag = (item, isSelected) => {      
      setSelectedTags(tags => (
         isSelected
            ? tags.filter(tag => tag.id !== item.id)
            : [...tags, item]
      ))
   }
   
   const handleDeleteSelectedTag = (id) => {
      setSelectedTags(tags => tags.filter(tag => tag.id !== id))
   } 

   const handleSelectChannelForByNewContacts = (channel) => {
      setSelectedChannelForByNewContacts(channel)
      dispatch(setContactsFilterChannelIds(channel === null ? channel : channel.id))
   }

   const handleContactListSearchChange = debounce((value) => {
      dispatch(setContactsFilterSearchValue(value))
   }, 300)

   const handleSelectContacts = (contact) => {
      setSelectedContacts(selectedContacts => (
         selectedContacts.find(selectedContact => selectedContact.id === contact.id)
            ? selectedContacts.filter(selectedContact => selectedContact.id !== contact.id)
            : [...selectedContacts, contact]
      ))
   }

   const handleSelectAllContacts = (items) => (
      setSelectedContacts(contacts => contacts.length === items.length ? [] : items)
   )

   const handleDeleteSelectedContact = (id) => {
      setSelectedContacts(contacts => contacts.filter(contact => contact.id !== id))
   }

   const handleFetchContacts = async (query) => {
      await dispatch(fetchContacts(query))
   }

   const handleContactsListScroll = async () => {
      const query = mapContactsFilterToQuery(contactsFilter) 
      await dispatch(fetchContacts(query, true))
   }

   const handleFilesChange = (files) => {
      setFileField(filesList => [...filesList, ...files.map(file => ({ id: uniqid(), file }))])
   }

   const handleDeleteFile = (id) => setFileField(files => files.filter(file => file.id !== id))

   const clearInputs = () => {
      setSelectedChannels([])
      setSelectedTags([])
      setSelectedStatuses([])
      setTextField('')
      setFileField([])
      setCurrentLimit(null)
      setIsTextFieldFilled(false)
      setTheChannelsSelected(false)
      setTheTagsSelected(false)
      setTheStatusesSelected(false)
      setTagsStatusesAreNotSelected(false)
      setFileListField([])
      setTheChannelIdSelected(false)
      setIsFileListExtValid(false)
      setContactList([])
      setClientsListExist(false)
      setSelectedChannelId(null)
      setContactInputValue(null)
      setSelectedContacts([])
   }

   const clearValidMessages = () => {
      setIsTextFieldFilled(false)
      setTheChannelsSelected(false)
      setTheTagsSelected(false)
      setTheStatusesSelected(false)
      setTagsStatusesAreNotSelected(false)
      setIsFileListLoaded(false)
      setTheChannelIdSelected(false)
      setIsFileListExtValid(false)
      setClientsListExist(false)
      setShowSelectedContactsError(false)
   }

   useEffect(() => {
      clearValidMessages();
   }, [mailingType, isVisible]);

   useEffect(() => {
      if (shouldClearMailingModalCache) {
         clearInputs()
         setShouldClearMailingModalCache(false)
      }
   }, [shouldClearMailingModalCache])

   useEffect(() => {
      const query = mapContactsFilterToQuery(contactsFilter)
      handleFetchContacts(query)
   }, [searchValue, channel, channelIds])

   const getAllowedConfig = () => {
      const output = {
         textLimit: Math.min(
            ...selectedChannels.map(
               (item) => messengerLimits[item.type].textLimit
            )
         ),
         documents: {
            formats: selectedChannels
               .map((item) => messengerLimits[item.type].documents.formats)
               .reduce((result, currentFormats) =>
                  result.filter((format) => currentFormats.includes(format))
               ),
            maxSize: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].documents.maxSize
               )
            ),
         },
         audio: {
            formats: selectedChannels
               .map((item) => messengerLimits[item.type].audio.formats)
               .reduce((result, currentFormats) =>
                  result.filter((format) => currentFormats.includes(format))
               ),
            maxSize: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].audio.maxSize
               )
            ),
            minDuration: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].audio.minDuration
               )
            ),
            maxDuration: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].audio.maxDuration
               )
            ),
         },
         video: {
            formats: selectedChannels
               .map((item) => messengerLimits[item.type].video.formats)
               .reduce((result, currentFormats) =>
                  result.filter((format) => currentFormats.includes(format))
               ),
            maxSize: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].video.maxSize
               )
            ),
            maxResolutions: {
               width: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].video.maxResolutions.width
                  )
               ),
               height: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].video.maxResolutions.height
                  )
               ),
            },
            maxDuration: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].video.maxDuration
               )
            ),
         },
         images: {
            formats: selectedChannels
               .map((item) => messengerLimits[item.type].images.formats)
               .reduce((result, currentFormats) =>
                  result.filter((format) => currentFormats.includes(format))
               ),
            maxSize: Math.min(
               ...selectedChannels.map(
                  (item) => messengerLimits[item.type].images.maxSize
               )
            ),
            minResolutions: {
               width: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].images.minResolutions.width
                  )
               ),
               height: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].images.minResolutions.height
                  )
               ),
            },
            maxResolutions: {
               width: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].images.maxResolutions.width
                  )
               ),
               height: Math.min(
                  ...selectedChannels.map(
                     (item) =>
                        messengerLimits[item.type].images.maxResolutions.height
                  )
               ),
            },
         },
      }

      setCurrentLimit(output)
   }

   useEffect(() => {
      if (selectedChannels.length === 0) {
         setCurrentLimit(null)
      } else {
         getAllowedConfig()
      }
   }, [selectedChannels])

   const mailingHandler = () => {
      if (textField.length === 0) {
         setIsTextFieldFilled(true)
      } else {
         setIsTextFieldFilled(false)
      }
      if (selectedChannels.length === 0 && mailingType === mailingTypesMap.BY_FILTER) {
         setTheChannelsSelected(true)
      } else {
         setTheChannelsSelected(false)
      }
      if (mailingType === mailingTypesMap.BY_FILTER) {         
         if (selectedTags.length === 0 && selectedStatuses.length === 0) {
            setTagsStatusesAreNotSelected(true)
         } else {
            setTagsStatusesAreNotSelected(false)
         }
      }
      if (fileListField.length === 0 && mailingType === mailingTypesMap.BY_CONTACTS_FILE) {
         setIsFileListLoaded(true)
      } else {
         setIsFileListLoaded(false)
      }
      if (selectedChannelId === null && (mailingType === mailingTypesMap.BY_CONTACTS_FILE || mailingType === mailingTypesMap.BY_NEW_CONTACTS)) {
         setTheChannelIdSelected(true)
      } else {
         setTheChannelIdSelected(false)
      }
      if (fileListField.length !== 0 && mailingType === mailingTypesMap.BY_CONTACTS_FILE) {
         if (!isFileExtensionValid()) {
            setIsFileListExtValid(true)
         } else {
            setIsFileListExtValid(false)
         }
      }
      if (filterClientsList().length === 0 && mailingType === mailingTypesMap.BY_NEW_CONTACTS) {
         setClientsListExist(true)
      } else {
         setClientsListExist(false)
      }
      if (!selectedContacts.length && mailingType === mailingTypesMap.BY_CONTACTS_LIST) {
         setShowSelectedContactsError(true)
      } else {
         setShowSelectedContactsError(false)
      }
      if (
          checkRequired()
      ) {
         setLoading(true)
         var bodyFormData = new FormData()
         bodyFormData.append('text', textField)

         if (fileField.length !== 0) {
            for (let file of fileField) {
               bodyFormData.append('files[]', file.file)
            }
         }

         if (mailingType === mailingTypesMap.BY_FILTER) {
            bodyFormData.append('allChannels', allSelectedChannels)
            if (allSelectedChannels !== true) {
               bodyFormData.append(
                   'channelIds',
                   selectedChannels.map((item) => item.id)
               )
            }

            bodyFormData.append('allDialogSteps', allSelectedStatuses)
            if (allSelectedStatuses !== true) {
               bodyFormData.append(
                   'dialogStepIds',
                   selectedStatuses.map((item) => item.id)
               )
            }

            bodyFormData.append('allTags', allSelectedTags)
            if (allSelectedTags !== true) {
               bodyFormData.append(
                   'tagIds',
                   selectedTags.map((item) => item.id)
               )
            }
         }

         if (mailingType === mailingTypesMap.BY_CONTACTS_FILE) {
            if (selectedChannelId) {
               bodyFormData.append('channel', selectedChannelId)
            }

            if (fileListField.length !== 0) {
               bodyFormData.append('fileContactList', fileListField[0].file)
            }
            bodyFormData.append('ignoreInvalid', 'false')
         }

         if (mailingType === mailingTypesMap.BY_NEW_CONTACTS) {
            if (selectedChannelId) {
               bodyFormData.append('channel', selectedChannelId)
            }

            if (contactList.length !== 0) {
               bodyFormData.append('clientsList', filterClientsList())
            }
         }

         if (mailingType === mailingTypesMap.BY_CONTACTS_LIST) {            
            bodyFormData.append('clientsIdList', selectedContacts.map(contact => contact.id))
         }

         axios({
            method: 'post',
            url: '/api/v1/mass-send/send' + generateType(),
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
         })
            .then((response) => {
               if (response.data.error) {
                  if (response.data.data.error.invalidNumbers) {
                     const contactsListMailingCache = {
                        type: generateType(),
                        formData: bodyFormData
                     }
                     const invalidNumbers = Object.values(response.data.data.error.invalidNumbers)
                     setLoading(false)
                     toggleShowAddMailingModal(false)
                     setFileListField([])
                     handleOpenIncorrectNumbersModal(invalidNumbers, contactsListMailingCache)
                  } else {
                     setServerError(response.data.data.error)
                     setLoading(false)
                     return setIsShowServerError(true)
                  }
               } else {
                  clearInputs()
                  toggleShowAddMailingModal(false)
                  setHistoryMailing((prevState) => {
                     return [response.data.data, ...prevState]
                  })
               }
               setLoading(false)
            })
            .catch((err) => {
               window.alert(err)
               window.location.reload(false)
            })
      }
   }

   const getSelectedTitle = () => {
      if (!selectedChannelId) {
         return 'Только выбранные'
      }
      const findSelected = channelList.find(item => item.id === selectedChannelId)
      return findSelected.type + ' - ' + findSelected.username
   }

   const handleAddReceiverClick = () => {
      setContactList(list => [...list, contactInputValue])
      setContactInputValue('')
   }

   const handleDelete = (client) => {
      setContactList(list => list.filter(item => item !== client))
   }

   const handleAddContact = (value) => {
      setContactInputValue(value)
   }

   const isFileExtensionValid = () => {
      const extensions = ['.txt', '.xlsx']
      const fileName = fileListField[0]?.file.name
      return extensions.some(ext => fileName?.endsWith(ext))
   }

   const filterClientsList = () => {
      return contactList.filter((client) => client !== null)
   }

   const checkRequired = () => {
      switch (mailingType) {
         case mailingTypesMap.BY_FILTER:
            return selectedChannels.length !== 0 &&
            (selectedTags.length !== 0 || selectedStatuses.length !== 0) &&
            textField.length !== 0
         case mailingTypesMap.BY_CONTACTS_FILE:
            return selectedChannelId !== null && fileListField.length !== 0 && textField.length !== 0
         case mailingTypesMap.BY_NEW_CONTACTS:
            return selectedChannelId !== null && filterClientsList().length !== 0 && textField.length !== 0
         case mailingTypesMap.BY_CONTACTS_LIST:
            return selectedContacts.length > 0
         default:
            return
      }
   }

   const generateType = () => {
      switch (mailingType) {         
         case mailingTypesMap.BY_FILTER:
            return '?type=filter'
         case mailingTypesMap.BY_CONTACTS_FILE:
            return '?type=file'
         case mailingTypesMap.BY_NEW_CONTACTS:
            return '?type=contact_list'
         case mailingTypesMap.BY_CONTACTS_LIST:
            return '?type=contact_id_list'
         default:
            return
      }
   }

   const filterAllowedChannels = () => {
      if (allowedChannel === null) {
         return channelList
      }
      let filteredChannelList = []
      allowedChannel.forEach((channelId) => {
         filteredChannelList.push(channelList.find(
           (channel) => channelId === channel.id
       ))
      })

      return filteredChannelList.filter(item => item !== undefined)
   }   

   useEffect(() => {
      if (textField.length > 0) {
         setIsTextFieldFilled(false)
      }
      if (selectedChannels.length > 0 && mailingType === mailingTypesMap.BY_FILTER) {
         setTheChannelsSelected(false)
      }
      if (mailingType === mailingTypesMap.BY_FILTER) {
         if (selectedTags.length > 0 || selectedStatuses.length > 0) {
            setTagsStatusesAreNotSelected(false)
         }
      }
      if (mailingType === mailingTypesMap.BY_CONTACTS_FILE) {
         setIsFileListLoaded(fileListField.length > 0 ? false : true)
         if (!fileListField.length) setIsFileListExtValid(false)
      }
      if (selectedChannelId !== null && (mailingType === mailingTypesMap.BY_CONTACTS_FILE || mailingType === mailingTypesMap.BY_NEW_CONTACTS)) {
         setTheChannelIdSelected(false)
      }
      if (fileListField.length !== 0 && mailingType === mailingTypesMap.BY_CONTACTS_FILE) {
         if (isFileExtensionValid()) {
            setIsFileListExtValid(false)
         } else {
            setIsFileListExtValid(true)
         }
      }

      if (filterClientsList().length > 0 && mailingType === mailingTypesMap.BY_NEW_CONTACTS) {
         setClientsListExist(false)
      }

      if (selectedContacts.length > 0 && mailingType === mailingTypesMap.BY_CONTACTS_LIST) {
         setShowSelectedContactsError(false)
      }
   }, [
      textField, 
      selectedChannels, 
      selectedTags, 
      selectedStatuses, 
      fileListField, 
      selectedChannelId, 
      contactList,
      selectedContacts
   ])

   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <div className="mailing-modal add-mailing-modal__wrapper basic_wrapper">
               <MailingFileNotification
                  title={'Уведомление'}
                  file={fileField.length === 0 ? '' : fileField[0].name}
                  text={serverError}
                  button={'Изменить рассылку'}
                  yes={() => setIsShowServerError(false)}
                  isVisible={isShowServerError}
               />
               <div className="add-mailing-modal__header basic_header">
                  <span className="add-mailing-modal__title basic_title">Новая рассылка</span>
                  <div className="basic-close">
                     <button
                        className="basic-close-button"
                        onClick={() => {
                           clearInputs()
                           toggleShowAddMailingModal()
                        }}
                     >
                        <img src="/assets/controls/close.svg" alt="close" />
                     </button>
                  </div>
               </div>

               {
                  mailingType === mailingTypesMap.BY_FILTER && (
                      <>
                         <div className="mailing-control-block">
                            <label
                                className={
                                   areTheChannelsSelected
                                       ? 'mailing-label-red'
                                       : 'mailing-label'
                                }
                                htmlFor=""
                            >
                               Выбрать канал
                            </label>
                            <Select
                               items={
                                  allowedChannel === null
                                      ? channelList.map(channel => ({ ...channel, name: channel.username }))
                                      : allowedChannel.map((channelId) => {
                                         const channel = channelList.find((channel) => channelId === channel.id)
                                         return !channel ? channel : { ...channel, name: channel.username }
                                      }).filter(item => item !== undefined)
                               }
                               selectedItems={selectedChannels}
                               placeholderText={'Выберите каналы для рассылки'}
                               onSelect={handleSelectChannel}
                               onDelete={handleDeleteSelectedChannel}
                            />
                         </div>

                         <div className="mailing-control-block">
                            <label
                                className={
                                   tagsStatusesAreNotSelected
                                       ? 'mailing-label-red'
                                       : 'mailing-label'
                                }
                                htmlFor=""
                            >
                               Выбрать статусы
                            </label>
                            <Select
                               items={
                                  allowedStatus === null
                                      ? status
                                      : allowedStatus.map((statusId) => {
                                         return status.find(
                                             (st) => statusId === st.id
                                         )
                                      })
                               }
                               selectedItems={selectedStatuses}
                               placeholderText={'Выберите статусы для рассылки'}
                               onSelect={handleSelectStatus}
                               onDelete={handleDeleteSelectedStatus}
                            />
                         </div>
                         <div className="mailing-control-block">
                            <label
                                className={
                                   tagsStatusesAreNotSelected
                                       ? 'mailing-label-red'
                                       : 'mailing-label'
                                }
                                htmlFor=""
                            >
                               Выбрать теги
                            </label>
                            <Select
                               items={
                                  allowedTag === null
                                      ? tag
                                      : allowedTag.map((tagId) => {
                                         return tag.find((tag) => tagId === tag.id)
                                      })
                               }
                               selectedItems={selectedTags}
                               placeholderText={'Выберите теги для рассылки'}
                               onSelect={handleSelectTag}
                               onDelete={handleDeleteSelectedTag}
                            />
                         </div>
                      </>
                  )
               }

               {
                  mailingType === mailingTypesMap.BY_CONTACTS_FILE && (
                      <>
                         <div className="mailing-control-block">
                            <div className="dropdown">
                               <div className="dropdown-wrapper" style={{ width: '100%' }}>
                                  <label
                                      className={
                                         areTheChannelIdSelected
                                             ? 'mailing-label-red'
                                             : 'mailing-label'
                                      }
                                      htmlFor=""
                                  >
                                     Выбрать канал
                                  </label>
                                  <div
                                      className="dropdown-current-item"
                                      onClick={toggleIsShowDropdown}
                                  >
                                     <span className="dropdown-current-item_name">
                                        {getSelectedTitle()}
                                     </span>
                                     <svg
                                         width="24"
                                         height="24"
                                         viewBox="0 0 24 24"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                        {isShowDropdown ? (
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                                                fill="#252525"
                                            />
                                        ) : (
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                                                fill="#252525"
                                            />
                                        )}
                                     </svg>
                                  </div>
                                  {isShowDropdown && (
                                      <div
                                          className="dropdown-list"
                                      >
                                         <div
                                             className="dropdown-list_wrapper"
                                             style={{ padding: '20px 24px' }}
                                         >
                                            {
                                               <ul className="dropdown-list_items">
                                                  {filterAllowedChannels()?.map((item) => {
                                                     const isChecked = selectedChannelId === item.id
                                                     return (
                                                         <li
                                                             className="dropdown-list_item"
                                                             style={{
                                                                margin: '0',
                                                                marginBottom: '16px',
                                                                cursor: 'pointer',
                                                             }}
                                                             key={item.id}
                                                             onClick={() => {
                                                                setSelectedChannelId(item.id)
                                                                setIsShowDropdown(false)
                                                             }}
                                                         >
                                                            <Checkbox
                                                               checked={isChecked}
                                                            />
                                                            <div className="dropdown-list_item-block">
                                                               {item.type + ' - ' + item.username}
                                                            </div>
                                                         </li>
                                                     )
                                                  })}
                                               </ul>
                                            }
                                         </div>
                                      </div>
                                  )}
                               </div>
                            </div>
                         </div>

                         <div className="mailing-control-block">
                            <label
                                className={
                                   isFileListLoaded || isFileListExtValid
                                       ? 'mailing-label-red question'
                                       : 'mailing-label question'
                                }
                                htmlFor=""
                            >
                               Добавить контакты с txt или xlsx файла
                               <div className="mailing-contact-file-info">
                                  <img
                                      src="/assets/chat/question-circular.svg"
                                      alt=""
                                      style={{ width: '15px', marginLeft: '3px'}}
                                  />
                                  <div className="mailing-contact-file-info-block cssAnimate">
                                     Максимальный размер файла 500КБ.
                                     <br/>
                                     <br/>
                                     В качестве контактов допускаются:
                                     <br/><b>WhatsApp</b> - номер телефона, пример: <i>89997776655</i>
                                     <br/><b>Instagram</b> - имя пользователя, пример: <i>user_12345</i>.
                                     <br/><b>Telegram</b> - имя пользователя, пример: <i>user_12345</i>
                                     <br/><b>ВКонтакте</b> - имя пользователя, пример: <i>user_12.34-5</i>
                                     <br/>
                                     <br/>
                                     Каждый контакт записывается с новой строчки без дополнительных знаков разделения.
                                  </div>
                               </div>
                            </label>
                            <div
                                className="mailing-control-block"
                                style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
                            >
                               <DropFileInput 
                                 files={fileListField} 
                                 onChange={(files) => {
                                    setFileListField(filesList => [
                                       ...filesList,
                                       ...files.map(file => ({ id: uniqid(), file }))
                                    ])
                                 }} 
                                 onDelete={(id) => setFileListField(files => files.filter(file => file.id !== id))}
                               />
                            </div>
                         </div>
                      </>
                  )
               }

               {
                  mailingType === mailingTypesMap.BY_NEW_CONTACTS && (
                      <>
                         <div className="mailing-control-block">
                            <div className="dropdown">
                               <div className="dropdown-wrapper" style={{ width: '100%' }}>
                                  <label
                                      className={
                                         areTheChannelIdSelected
                                             ? 'mailing-label-red'
                                             : 'mailing-label'
                                      }
                                      htmlFor=""
                                  >
                                     Выбрать канал
                                  </label>
                                  <div
                                      className="dropdown-current-item"
                                      onClick={toggleIsShowDropdown}
                                  >
                                     <span className="dropdown-current-item_name">
                                        {getSelectedTitle()}
                                     </span>
                                     <svg
                                         width="24"
                                         height="24"
                                         viewBox="0 0 24 24"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                     >
                                        {isShowDropdown ? (
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                                                fill="#252525"
                                            />
                                        ) : (
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                                                fill="#252525"
                                            />
                                        )}
                                     </svg>
                                  </div>
                                  {isShowDropdown && (
                                      <div
                                          className="dropdown-list"
                                      >
                                         <div
                                             className="dropdown-list_wrapper"
                                             style={{ padding: '20px 24px' }}
                                         >
                                            {
                                               <ul className="dropdown-list_items">
                                                  {filterAllowedChannels()?.map((item) => {
                                                     const isChecked = selectedChannelId === item.id
                                                     return (
                                                         <li
                                                             className="dropdown-list_item"
                                                             style={{
                                                                margin: '0',
                                                                marginBottom: '16px',
                                                                cursor: 'pointer',
                                                             }}
                                                             key={item.id}
                                                             onClick={() => {
                                                                setSelectedChannelId(item.id)
                                                                setIsShowDropdown(false)
                                                             }}
                                                         >
                                                            <Checkbox
                                                               checked={isChecked}
                                                            />
                                                            <div className="dropdown-list_item-block">
                                                               {item.type + ' - ' + item.username}
                                                            </div>
                                                         </li>
                                                     )
                                                  })}
                                               </ul>
                                            }
                                         </div>
                                      </div>
                                  )}
                               </div>
                            </div>
                         </div>

                         <div className="mailing-contact-list-block">
                            <label
                                className={
                                   areContactListExist
                                       ? 'mailing-label-red'
                                       : 'mailing-label'
                                }
                                htmlFor=""
                            >
                               Список контактов
                            </label>
                            <div className="mailing-list_input-wrap">
                              <div className="mailing-list_input-wrap-container">
                                 <input
                                    type="text"
                                    className="mailing-list_input"
                                    onChange={(e) => handleAddContact(e.target.value)}
                                    name={contactListCount}
                                    value={contactInputValue}
                                    placeholder="Добавить контакт"
                                 />
                                 <Button
                                    text='Добавить'
                                    onClick={handleAddReceiverClick}
                                 />
                              </div>
                               <div className="mass-send-instructions">
                                 <span>
                                 Введите контакт в поле, нажмите добавить,
                                 введите следующий контакт.
                                 </span>
                               </div>

                            </div>
                            {contactList.length > 0 && (
                              <div
                                className="mailing-contact-list-display"
                              >
                                 {contactList.map((client) => (
                                    <span
                                          key={client}
                                          className="mailing-single-contact-field"
                                          title={client}
                                    >
                                    {client}
                                       <img
                                             src="/assets/controls/delete.svg"
                                             alt=""
                                             className="mailing-list_delete-button"
                                             onClick={() => handleDelete(client)}
                                       />
                                 </span>
                                 ))}
                              </div>
                            )}
                        </div>
                      </>
                  )
               }

               {mailingType === mailingTypesMap.BY_CONTACTS_LIST && (
                  <>
                     <div className="mailing-control-block">
                        <label
                            className={
                               tagsStatusesAreNotSelected
                                   ? 'mailing-label-red'
                                   : 'mailing-label'
                            }
                            htmlFor=""
                        >
                           Выбрать канал
                        </label>
                        <ChannelSelect
                           items={
                              allowedChannel === null
                                 ? channelList.map(channel => ({ ...channel, name: channel.username }))
                                 : allowedChannel.map((channelId) => {
                                    const channel = channelList.find((channel) => channelId === channel.id)
                                    return !channel ? channel : { ...channel, name: channel.username }
                                 }).filter(item => item !== undefined)
                           }
                           selectedItem={selectedChannelForByNewContacts}
                           onSelect={handleSelectChannelForByNewContacts}
                        />
                     </div>
                     <div className="mailing-control-block">
                        <label
                           className={
                              showSelectedContactsError
                                 ? 'mailing-label-red'
                                 : 'mailing-label'
                           }
                           htmlFor=""
                        >
                           Контакты
                        </label>
                        <ContactsSelect
                           items={contacts}
                           selectedItems={selectedContacts}
                           search={searchValue}
                           onSelectAll={handleSelectAllContacts}
                           onSearchChange={handleContactListSearchChange}
                           onSelect={handleSelectContacts}
                           onDelete={handleDeleteSelectedContact}
                           onScroll={handleContactsListScroll}
                        />
                     </div>
                  </>
               )}

               <div className="mailing-control-block">
                  <label
                     className={
                        isTextFieldFilled
                           ? 'mailing-label-red'
                           : 'mailing-label'
                     }
                     htmlFor=""
                  >
                     Текст рассылки
                  </label>
                  <textarea
                     value={textField}
                     onChange={(e) => {
                        setTextField(e.target.value)
                     }}
                     placeholder="Текст рассылки"
                     minLength="1"
                     maxLength={
                        currentLimit === null ? '' : currentLimit.textLimit
                     }
                     className={
                        isTextFieldFilled
                           ? 'mailing-textarea-red'
                           : 'mailing-textarea'
                     }
                  ></textarea>
               </div>
               <div
                  className="mailing-control-block"
                  style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
               >
                  <DropFileInput 
                     files={fileField} 
                     onChange={handleFilesChange} 
                     onDelete={handleDeleteFile}
                  />
               </div>
               <div>
                  {areTheChannelsSelected && (
                     <WarningBlock title={'Не выбран ни один канал.'} />
                  )}
                  {areTheTagsSelected && (
                     <WarningBlock title={'Не выбран ни один тег.'} />
                  )}
                  {areTheStatusesSelected && (
                     <WarningBlock title={'Не выбран ни один статус.'} />
                  )}
                  {areTheChannelIdSelected && (
                     <WarningBlock title={'Не выбран канал.'} />
                  )}
                  {isFileListLoaded && (
                     <WarningBlock title={'Не выбран файл списка рассылки.'} />
                  )}
                  {isFileListExtValid && (
                     <WarningBlock title={'Разрешенное расширение файла с контактами - .txt, .xlsx.'} />
                  )}
                  {areContactListExist && (
                     <WarningBlock title={'Не заполнен список контактов.'} />
                  )}
                  {isTextFieldFilled && (
                     <WarningBlock title={'Введите текст рассылки.'} />
                  )}
                  {showSelectedContactsError && (
                     <WarningBlock title={'Не выбран ни один контакт.'} />
                  )}
               </div>
               <div className="add-mailing-form-submit">
                  {isLoading ? (
                     <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                     </div>
                  ) : (
                     <Button
                        text='Отправить рассылку'
                        onClick={mailingHandler}
                     />
                  )}
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default AddMailing
