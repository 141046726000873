import React, { useEffect, useState } from 'react'

import './ManagersConstructor.css'

import { MAX_MANAGERS_QTY, MIN_MANAGERS_QTY } from '../../../utils/consts'

import ManagersNumberInput from '../ManagersNumberInput'
import RangeInput from '../../../components/RangeInput'
import PlanTooltip from '../PlanTooltip'

const ManagersConstructor = ({
   planComponent,
   currentManagersQty = 10,
   availableManagersQty = 0,
   planNamesWithComponent,
   disabled,
   onChange = () => {}
}) => {
   const [managersQty, setManagersQty] = useState(currentManagersQty)

   const tooltipText = `
      Данная функциональность доступна пользователям с тарифом ${planNamesWithComponent}
   `
   
   const handleManagersQtyChange = (value) => {      
      const numericValue = value.replace(/^0+/, '');

      if (
         numericValue === '' ||
         isNaN(Number(numericValue)) ||
         Number(numericValue) < MIN_MANAGERS_QTY || 
         Number(numericValue) > MAX_MANAGERS_QTY
      ) {
         return
      }

      const numberValue = Number(numericValue)
      setManagersQty(numberValue)
      onChange({ ...planComponent, quantity: numberValue })
   }

   useEffect(() => {
      setManagersQty(currentManagersQty)
   }, [currentManagersQty])

   return (
      <div className={`managers-constructor ${disabled ? 'disabled' : ''}`}>
         <div className='managers-constructor__header'>
            <h4 className='managers-constructor__name'>
               Менеджеры
            </h4>
            {disabled && (
               <PlanTooltip 
                  customStyle='managers-constructor__tooltip'
                  text={tooltipText}   
               />
            )}
            <p className='managers-constructor__remaining-managers-status'>
               Свободно: <span>{availableManagersQty}</span> / {managersQty}
            </p>
         </div>
         <div className='managers-constructor__number-input'>
            <ManagersNumberInput
               value={managersQty}
               onChange={handleManagersQtyChange}
               disabled={disabled}
            />
         </div>
         <div className='managers-constructor__range-input'>
            <RangeInput
               value={managersQty}
               min={MIN_MANAGERS_QTY}
               max={MAX_MANAGERS_QTY}
               onChange={handleManagersQtyChange}
               disabled={disabled}
            />
         </div>
      </div>
   )
}

export default ManagersConstructor