import { messageTypes } from "../../../utils/consts"

export const getLastMessageText = (type, text) => {
   return messageTypes[type] || text || 'Сообщение'
}

export const getLastMessageTime = (timestamp) => {
   const date = new Date(timestamp * 1000)
   const today = new Date()

   const isToday =
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()

   if (isToday) {
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return `${hours}:${minutes}`
   } else {
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
   }
}