import React from 'react'

import './Input.css'

const TextInput = ({
   id = '', 
   name = '',
   type = 'text',
   value = '',
   label = '',
   error,
   placeholder = '',
   onChange,
   onBlur,
}) => {
   return (
      <div className={`input input_text ${error ? 'input_error' : ''}`}>
         <label 
            className='input__label'
            htmlFor={id}
         >
            {error || label}
         </label>
         <div className='input__body'>
            <input 
               className='input__input'
               id={id}
               name={name}
               type={type}
               value={value}
               placeholder={placeholder}
               onChange={onChange}
               onBlur={onBlur}
            />
         </div>
      </div>
   )
}

export default TextInput