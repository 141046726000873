import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { 
   plan_selectAvailablePlanListLoading, 
   plan_selectAvailablePlanList 
} from "../store/slices/plan/selectors"
import { fetchAvailablePlanList } from "../store/slices/plan/thunk-creators"

const useFetchAvailablePlanList = () => {
   const availablePlanListLoading = useSelector(plan_selectAvailablePlanListLoading)
   const availablePlanList = useSelector(plan_selectAvailablePlanList)
   
   const dispatch = useDispatch()
   
   const handleFetchAvailablePlanItems = async () => await dispatch(fetchAvailablePlanList())
   
   useEffect(() => {
      handleFetchAvailablePlanItems()
   }, [])
   
   return [availablePlanListLoading, availablePlanList]
}

export default useFetchAvailablePlanList